import React from 'react';
import { Grid as GridMui } from '@material-ui/core';

/**
 * Wrapper around Material UI `<Grid />` component
 * @see {@link https://material-ui.com/api/grid/}
 * @func Grid
 */
const Grid = props => <GridMui {...props} />;

export default Grid;
