import { ApolloProvider as ReactApolloProvider } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import ApolloClient, { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-boost';
import React from 'react';

import introspectionQueryResultData from '../__generated__/fragmentMatcher.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const cache = new InMemoryCache({ fragmentMatcher });

const GRAPHQL_ENDPOINT = process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT;

export const ApolloProvider: React.FC<{}> = ({ children }) => {
  const { isAuthenticated, getAccessTokenSilently, logout } = useAuth0();

  if (!isAuthenticated) {
    return null;
  }

  const client = new ApolloClient({
    uri: GRAPHQL_ENDPOINT,
    cache,
    onError: err => {
      if (/Unauthorized/.test(JSON.stringify(err))) {
        logout();
      }
    },
    request: async operation => {
      const token = await getAccessTokenSilently();
      operation.setContext({ headers: { authorization: `Bearer ${token}` } });
    }
  });

  return <ReactApolloProvider client={client}>{children}</ReactApolloProvider>;
};
