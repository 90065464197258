import React, { useMemo } from 'react';
import { graphql } from 'react-apollo';
import { v4 } from 'uuid';
// components
import Box from 'components/Box';
import Text from 'components/Text';
import { Uploader, useIntlScope } from '@lemonone/components';
// material-ui
import TextField from '@material-ui/core/TextField';
import { Label, Title } from './i18n';
import createUploadUrl from './createUploadUrl.graphql';
import styles from './CheckoutFormNotes.module.css';

const CheckoutFormNotes = ({ actions, values }) => {
  const uuid = useMemo(() => v4());
  const intl = useIntlScope('@lemonone/bookingui/');
  return (
    <Box margin="0 0 2">
      <div className={styles.Addtional}>
        <Text>
          <Title />
        </Text>
      </div>
      <div className={styles.Addtional}>
        <TextField
          fullWidth
          name="notes"
          label={<Label />}
          margin="none"
          multiline
          onBlur={actions.onBlur}
          onChange={actions.onChange}
          rows="5"
          value={values.notes || ''}
          variant="outlined"
        />
        <Text></Text>
      </div>

      <div className={styles.Addtional}>
        <Text>{intl('uploadAttachments')}</Text>
      </div>

      <Uploader
        id={process.env.REACT_APP_BOOKINGUI_ATTACHMENT_UPLOADER_TOKEN}
        folderName={uuid}
        onUpload={url => {
          actions.onChange({
            target: {
              name: 'attachment',
              value: url,
              type: 'text'
            }
          });
        }}
      />
    </Box>
  );
};

export default graphql(createUploadUrl)(CheckoutFormNotes);
