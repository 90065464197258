import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Box from 'components/Box';
import Text from 'components/Text';
import React from 'react';
import { useIntl } from 'react-intl';
import { useGetMyBillingAddressesQuery } from '../../__generated__';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: 0,
    width: '100%',
    height: 60,
    paddingTop: '0px'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  box: {
    marginTop: theme.spacing(2)
  }
}));

const CheckoutFormBillingAddress = ({ values, actions }) => {
  const classes = useStyles();
  const intl = useIntl();

  const { loading, error, data } = useGetMyBillingAddressesQuery({
    fetchPolicy: 'cache-and-network'
  });

  if (loading) return null;
  if (data && data.billingAddresses.length === 0) return null;

  return (
    <Box margin="0 0 2" className={classes.box}>
      <Text>
        {intl.formatMessage({
          id: 'components.CheckoutFormBillingAddress.title'
        })}
      </Text>

      <FormControl className={classes.formControl}>
        <InputLabel id="billingAddressFieldLabel">
          <Text>{intl.formatMessage({ id: 'Billing Address' })}</Text>
        </InputLabel>
        <Select
          labelId="billingAddressFieldLabel"
          id="billingAddressField"
          fullWidth
          name="billingAddressUuid"
          error={!loading && error}
          disabled={loading || error}
          onChange={actions.onChange}
          margin="none"
          value={values.billingAddressUuid || ''}
        >
          {!loading &&
            data &&
            data.billingAddresses.map(billingAddress => (
              <MenuItem key={billingAddress.uuid} value={billingAddress.uuid} elevation={0}>
                {intl.formatMessage(
                  {
                    id: 'components.CheckoutFormBillingAddress.billingAddress'
                  },
                  billingAddress
                )}
              </MenuItem>
            ))}
        </Select>
        <FormHelperText>
          {intl.formatMessage({
            id: 'components.CheckoutFormBillingAddress.notFound'
          })}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};

export default CheckoutFormBillingAddress;
