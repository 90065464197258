// components
// date-fns locales
// TODO: import locales dynamically
//      @see https://github.com/date-fns/date-fns/issues/1014
//      date-fns issue about breaking dynamic locale imports
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import pt from 'date-fns/locale/pt';
import { useIntl } from 'react-intl';

const defaultLang = 'en';
const defaultCurrency = 'EUR';
const supportedLangs = ['de', 'en', 'es', 'pt'];
const dateFnsLocales = { de, en, es, pt };

/**
 * Returns the user browser/os language
 *
 * [ISO 639-1]{@link https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes}
 *
 * @func getBrowserLang
 * @param {Array} [_supported=['en']]
 * @param {String} [defaultLg=en] ISO 639-1 language code
 * @return {String} ISO 639-1 language code
 */
export function getBrowserLang(_supported, defaultLg = 'en') {
  const supported = _supported || [defaultLg];
  const navigatorLg = (
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage || // for IE browsers lower than version 11
    ''
  ).substr(0, 2);
  return supported.indexOf(navigatorLg) !== -1 ? navigatorLg : defaultLg;
}

export const browserLang = getBrowserLang(supportedLangs, defaultLang);

/**
 * Locale from date-fns to be used with @material-ui/pickers
 * {@see ../components/SelectDateTime/Calendar.jsx}
 *
 * @func getDateFnsLocale
 * @return {Object}  default=defaultLang
 */
export function getDateFnsLocale() {
  /**
   * {@link https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes}
   * @type {String}
   */
  const locale = browserLang;
  return dateFnsLocales[locale] || dateFnsLocales[defaultLang];
}

/**
 * Binary floating-point numbers are inept at handling decimal fractions,
 * so 0.1 + 0.2 is not equal to 0.3. This is the most frequently reported bug
 * in JavaScript, and it is an intentional consequence of having adopted the
 * IEEE Standard for Binary Floating-Point Arithmetic (IEEE 754). This
 * standard is well-suited for many applications, but it violates most of the
 * things you learned about numbers in middle school. Fortunately, integer
 * arithmetic in floating point is exact, so decimal representation errors
 * can be avoided by scaling. For example, dollar values can be converted to
 * whole cents values by multiplying them by 100. The cents then can be
 * accurately added. The sum can be divided by 100 to convert back into
 * dollars. People have a reasonable expectation when they count money that
 * the results will be exact.
 *
 * -- "JavaScript: The Good Parts by Douglas Crockford. 978-0-596-51774-8."
 *
 * @func getMoneyProps
 * @param {Object} money
 * @param {Number} [money.amount=0] Integer represting the amount in cents
 * @param {String} [money.currency=defaultCurrency] currency code ISO 4217
 * @return {Object}
 */
export const getMoneyProps = ({ amount = 0, currency = defaultCurrency } = {}) => ({
  currency,
  style: 'currency',
  value: Number(amount) / 100
});

export const useMessage = () => {
  const intl = useIntl();
  return id => intl.formatMessage({ id });
};
