import { useCreateOrderMutation } from '__generated__';
import ErrorMessage from 'components/Error';
import Loading from 'components/Loading';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import logger from 'utils/logger';

const UPLOADER_URL = process.env.REACT_APP_BOOKINGUI_UPLOADER;

if (!UPLOADER_URL) {
  throw new Error('Missing REACT_APP_BOOKINGUI_UPLOADER');
}
const EditingServicePage: React.FC<{}> = () => {
  const params = useParams<{ uuid: string }>();
  const productUuid = params.uuid;
  const [createOrder, req] = useCreateOrderMutation();

  useEffect(() => {
    (async () => {
      try {
        const now = new Date();
        await createOrder({
          fetchPolicy: 'no-cache',
          variables: {
            order: {
              productId: productUuid,
              shoot: {
                start: now.toISOString(),
                end: now.toISOString(),
                location: {
                  name: 'LemonOne Editing Service',
                  address: 'Saarbrücker Str. 18, 10405 Berlin'
                }
              },
              host: {
                name: 'LemonOne',
                phone: '+4930120872870'
              }
            }
          }
        });
      } catch (err) {
        logger('error', err.message);
      }
    })();
  }, [productUuid]);

  const shootName = req.data?.createOrder.shoot?.googleCalendarSummary;

  useEffect(() => {
    if (shootName) {
      const url = new URL(UPLOADER_URL);
      url.pathname = '/uploader/';
      url.searchParams.set('id', shootName);
      window.location.href = url.toString();
    }
  }, [shootName]);

  if (req.loading) {
    return <Loading />;
  }

  if (req.error) {
    return <ErrorMessage message={req.error.message} />;
  }

  return null;
};

export default EditingServicePage;
