/* eslint-disable */
/**
 * @module utils/logger
 */

import * as Sentry from '@sentry/browser';

/**
 * Google Analytics tracking function
 *
 * Loaded through Google Tag Manager
 * @see /publit/index.html
 *
 * @type {Function}
 */
const { ga } = window;

const LOG_LEVELS = ['error', 'warn', 'log', 'info', 'debug', 'assert', 'dir', 'profile', 'profileEnd'];
const __DEV__ = process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test';
const SENTRY_DSN = process.env.REACT_APP_BOOKINGUI_SENTRY_DSN;

export const initLogger = () => {
  if (SENTRY_DSN && !__DEV__) {
    try {
      Sentry.init({
        dsn: SENTRY_DSN,
        environment: process.env.REACT_APP_STAGE,
        release: process.env.CIRCLE_SHA1
      });
    } catch (e) {
      console.error(e);
    }
  }
};

/**
 * @example
 *   logger('error', new Error('Somehting happened!', {
 *     logger: '',
 *     tags: {},
 *     extra: any
 *   }))
 *
 * @func logger
 * @param {string} level
 * @param {string|Error} message
 * @param {object} additionalData
 */
function logger(...args) {
  // first param can be optionaly the log level
  const level = LOG_LEVELS.every(elem => !(elem === args[0])) ? 'info' : args.shift();

  if (__DEV__) {
    // check if console[level] is a method of object console <- To be ignored by IE9 and lower
    if (typeof console === 'object' && console[level] && console[level].apply) {
      console[level].apply(console, args);
    }
  }

  // Send log to Sentry
  if (SENTRY_DSN && !__DEV__) {
    Sentry.withScope(scope => {
      scope.setLevel(level);
      scope.setExtras(args[1]);
      Sentry.captureException(args[0]);
    });
  }

  // Send error logs to Google Analytics
  if (level === 'error' && ga && !__DEV__) {
    ga('send', 'exception', {
      exDescription: args[0] && args[0].message
    });
  }
}

export default logger;
