import React from 'react';
// components
import Box from 'components/Box';
import Text from 'components/Text';
// material-ui
import TextField from '@material-ui/core/TextField';
import { FormLabel } from '@material-ui/core';
import {
  Description,
  HostEmailLabel,
  HostEmailError,
  HostNameLabel,
  HostNameError,
  HostPhoneLabel,
  HostPhoneError
} from './i18n';

const CheckoutFormHost = ({ values, actions, meta }) => (
  <Box margin="0 0 2">
    <Text>
      <Description />
    </Text>
    <TextField
      inputProps={{ 'data-e2e': 'checkoutFormHostName' }}
      fullWidth
      name="hostName"
      label={<HostNameLabel />}
      margin="none"
      required
      onBlur={actions.onBlur}
      onChange={actions.onChange}
      value={values.hostName || ''}
    />
    {meta.touched.hostName && meta.errors.hostName && (
      <FormLabel error>
        <HostNameError />
      </FormLabel>
    )}
    <TextField
      inputProps={{ 'data-e2e': 'checkoutFormHostEmail' }}
      fullWidth
      name="hostEmail"
      label={<HostEmailLabel />}
      margin="none"
      required
      onBlur={actions.onBlur}
      onChange={actions.onChange}
      type="email"
      value={values.hostEmail || ''}
    />
    {meta.touched.hostEmail && meta.errors.hostEmail && (
      <FormLabel error>
        <HostEmailError />
      </FormLabel>
    )}
    <TextField
      inputProps={{ 'data-e2e': 'checkoutFormHostPhone', maxLength: '40' }}
      fullWidth
      name="hostPhone"
      label={<HostPhoneLabel />}
      margin="none"
      required
      onBlur={actions.onBlur}
      onChange={actions.onChange}
      type="tel"
      value={values.hostPhone || ''}
    />
    {meta.touched.hostPhone && meta.errors.hostPhone && (
      <FormLabel error>
        <HostPhoneError />
      </FormLabel>
    )}
  </Box>
);

export default CheckoutFormHost;
