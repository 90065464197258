import React from 'react';
import Container from 'components/Container';
import NavBar from 'components/NavBar';

const Layout = ({ children }) => (
  <>
    <NavBar />
    <Container>{children}</Container>
  </>
);

export default Layout;
