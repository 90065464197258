import { Alert, Banner, Row, Typography, useIntlScope, useSettings } from '@lemonone/components';
import withWidth from '@material-ui/core/withWidth';
import { useGetMyProductsQuery } from '__generated__';
import ErrorMessage from 'components/Error';
import Grid from 'components/Grid';
import Loading from 'components/Loading';
import PhotoShootSpecCard from 'components/PhotoShootSpecCard';
import React from 'react';

export const ProductSelection = ({ onPhotoshootService, onEditingService, ...props }) => {
  const intl = useIntlScope('@lemonone/bookingui/');

  const { data, loading, error } = useGetMyProductsQuery({
    variables: {
      uuid: props.default
    }
  });

  const settings = useSettings();

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorMessage message={error.message} color="error" />;
  }

  return (
    <>
      {settings.bookingUI?.banner && (
        <Row>
          <Grid container justify="center" spacing={3}>
            <Grid item xs={12} sm={11} md={11}>
              <Banner variant="primary">{settings.bookingUI?.banner}</Banner>
            </Grid>
          </Grid>
        </Row>
      )}
      {data.getMyProducts.length === 0 && (
        <Row>
          <Grid container justify="center" spacing={3}>
            <Grid item xs={8} sm={8} md={8}>
              <Alert variant="primary">
                <Typography whiteSpace="pre-line">{intl('noProducts')}</Typography>
              </Alert>
            </Grid>
          </Grid>
        </Row>
      )}
      {data.getMyProducts.length !== 0 && (
        <Row>
          <Grid container justify="center" spacing={3}>
            {data.getMyProducts.map((product, i) => (
              <Grid key={i} item xs={12} sm={5} md={4} data-e2e="productItem">
                <PhotoShootSpecCard
                  onClick={product.shotByOwner ? onEditingService : onPhotoshootService}
                  product={product}
                  showPrice={false}
                />
              </Grid>
            ))}
          </Grid>
        </Row>
      )}
    </>
  );
};

export default withWidth()(ProductSelection);
