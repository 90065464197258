import { useIntlScope, Button, Icon, useIntl } from '@lemonone/components';
import React from 'react';
import track from 'utils/analytics';
import { mapStatus } from '../OrderTable/mapStatus';

function mapRow(order, intl) {
  const status = mapStatus(order);

  let deliveredAt = '';

  if (status === 'fulfilled' && order.shoot.deliveredAt) {
    deliveredAt = new Date(order.shoot.deliveredAt).toISOString();
  }

  if (status === 'cancelled' && order.shoot.cancelledAt) {
    deliveredAt = new Date(order.shoot.cancelledAt).toISOString();
  }

  return [
    order?.additionalData?.externalId ?? '',
    (order.shoot.location.name ? `${order.shoot.location.name}, ` : '') + order.shoot.location.address,
    order.uuid.slice(0, 8),
    order.user.email,
    new Date(order.createdAt).toISOString(),
    new Date(order.shoot.start).toISOString(),
    deliveredAt,
    order.product.name,
    order.shoot.price ? intl.formatNumber(order.shoot.price, { format: 'currency' }) : '',
    deliveredAt ? `${status} ${deliveredAt}` : `${status}`,
    order?.additionalData.notes ?? '',
    order.shoot.url ?? '',
    status === 'scheduled' ? `${window.location.origin}/reschedule?id=${order.uuid}` : ''
  ];
}

function sendFile({ name, data, type }) {
  const blob = new Blob([data], { type });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  link.download = name;
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
}

export const ExportOrdersCsv = ({ orders, disabled }) => {
  const intl = useIntl();
  const i18n = useIntlScope('components.OrderTable.');
  const common = useIntlScope('@lemonone/intl/text:');

  const headers = [
    i18n('referenceId'),
    i18n('address'),
    i18n('id'),
    i18n('creator'),
    i18n('createdAt'),
    i18n('date'),
    i18n('deliveredAt'),
    i18n('product'),
    common('price'),
    i18n('status'),
    common('notes'),
    i18n('downloadUrl'),
    i18n('rescheduleUrl')
  ];

  const onDownload = () => {
    track('exportBookingsAsCSV');
    const header = headers.map(JSON.stringify).join(',');
    const rows = orders.map(order =>
      mapRow(order, intl)
        .map(x => JSON.stringify(x))
        .join(',')
    );
    const csv = [header, ...rows].join('\n');
    sendFile({ name: 'orders.csv', type: 'text/csv', data: `${csv}\n` });
  };

  return (
    <Button
      size="xs"
      disabled={disabled}
      variant="text"
      type="button"
      color="primary"
      title={i18n('downloadCsv')}
      onClick={onDownload}
    >
      <Icon icon="download_csv" size="md" />
    </Button>
  );
};
