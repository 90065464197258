import React from 'react';
// components
import classes from './NavIcon.module.css';

const NavIcon = ({ stageNumber, label, status }) => {
  let circleClassName;
  let lableClassName;
  let stageNumberName;

  switch (status) {
    case 'CHECKED':
      circleClassName = `${classes.circle} ${classes.circleBlack} ${classes.circleChecked}`;
      lableClassName = `${classes.statusLabel} ${classes.labelBlack}`;
      stageNumberName = classes.stageNumberDivHidden;
      break;
    case 'CURRENT':
      circleClassName = `${classes.circle} ${classes.circleBlack}`;
      lableClassName = `${classes.statusLabel} ${classes.labelBlack}`;
      break;
    case 'UPCOMING':
    default:
      circleClassName = `${classes.circle} ${classes.circleGrey}`;
      lableClassName = `${classes.statusLabel} ${classes.labelGrey}`;
  }

  return (
    <div className={classes.container}>
      <div className={circleClassName}>
        <div className={stageNumberName}>{stageNumber + 1}</div>
      </div>
      <div className={lableClassName}>{label}</div>
    </div>
  );
};

export default NavIcon;
