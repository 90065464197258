import { Icon, Typography, useIntlScope } from '@lemonone/components';
import { TableCell, TableRow } from '@material-ui/core';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import track from 'utils/analytics';

import { mapStatus } from './mapStatus';
import { OrderDetailsRow } from './OrderDetailsRow';
import classes from './OrderTableRow.module.css';

const OrderTableRow = ({ order, onOrderPreview, striped, isNotesVisible }) => {
  const history = useHistory();
  const intl = useIntl();
  const text = useIntlScope('components.OrderTable.');
  const status = mapStatus(order);
  const [visibility, setVisibility] = useState(false);

  useEffect(() => {
    setVisibility(isNotesVisible);
  }, [isNotesVisible]);

  return (
    <>
      <TableRow className={cn(classes.row, striped && classes.stripedRow)}>
        <TableCell>
          <div style={{ width: '100px', wordBreak: 'break-all' }}>{order?.additionalData?.externalId ?? 'N/A'}</div>
        </TableCell>
        <TableCell>
          {order.shoot.location.name && <>{order.shoot.location.name}, &nbsp;</>}
          {order.shoot.location.address}
        </TableCell>
        <TableCell>{order.uuid.slice(0, 8)}</TableCell>
        <TableCell>
          <div style={{ width: '100px', wordBreak: 'break-all' }}>{order.user.email}</div>
        </TableCell>
        <TableCell>
          {intl.formatDate(order.createdAt, {
            format: 'dateTime',
            timeZone: order.shoot.location.timeZoneId
          })}
        </TableCell>
        <TableCell>
          {intl.formatDate(order.shoot.start, {
            format: 'dateTime',
            timeZone: order.shoot.location.timeZoneId
          })}
        </TableCell>
        <TableCell>
          {status === 'fulfilled' &&
            order.shoot.deliveredAt &&
            intl.formatDate(order.shoot.deliveredAt, {
              format: 'dateTime',
              timeZone: order.shoot.location.timeZoneId
            })}
        </TableCell>
        <TableCell>{order.product.name}</TableCell>
        <TableCell>
          {order.shoot.price &&
            intl.formatNumber(order.shoot.price, {
              format: 'currency'
            })}
        </TableCell>
        <TableCell>
          <Typography color={status === 'fulfilled' ? 'success' : 'primary'} size="inherit" weight="bold">
            {text(`status:${status}`)}
          </Typography>
          {status === 'fulfilled' && order.shoot.deliveredAt && (
            <div style={{ whiteSpace: 'nowrap' }}>
              {intl.formatDate(order.shoot.deliveredAt, {
                format: 'dateTime',
                timeZone: order.shoot.location.timeZoneId
              })}
            </div>
          )}
          {status === 'cancelled' && order.shoot.cancelledAt && (
            <div style={{ whiteSpace: 'nowrap' }}>
              {intl.formatDate(order.shoot.cancelledAt, {
                format: 'dateTime',
                timeZone: order.shoot.location.timeZoneId
              })}
            </div>
          )}
        </TableCell>

        <TableCell>
          {order.additionalData?.notes && (
            <>
              <div className={classes.notesColumn} onClick={() => setVisibility(!visibility)}>
                <p className={classes.notes}>{order.additionalData?.notes}</p>
                <button className={cn(classes.seeNotesButton)}>{visibility ? '▲' : '▼'}</button>
              </div>
            </>
          )}
        </TableCell>

        <TableCell>
          <div className={classes.actions}>
            <button className={classes.action} title={text('seeAllInfo')} onClick={() => onOrderPreview(order)}>
              <Icon icon="check" size="md" />
            </button>
            <button
              className={classes.action}
              title={text('rescheduleUrl')}
              disabled={status !== 'scheduled'}
              data-e2e="rescheduleBookingButton"
              onClick={() => {
                track('onClickReschedule', { label: order.uuid });
                history.push(`/reschedule?id=${order.uuid}`);
              }}
            >
              <Icon icon="edit_date" size="md" />
            </button>
            <button
              className={classes.action}
              title={text('downloadUrl')}
              disabled={status !== 'fulfilled'}
              onClick={() => window.open(order.shoot.url)}
            >
              <Icon icon="download" size="md" />
            </button>
          </div>
        </TableCell>
      </TableRow>
      {visibility && <OrderDetailsRow order={order} striped={striped} />}
    </>
  );
};

export default OrderTableRow;
