import React from 'react';
import { connect } from 'unistore/react';
import { FormattedMessage } from 'react-intl';
// components
import Box from 'components/Box';
import ConfirmationComponent from 'components/Confirmation';
import Grid from 'components/Grid';
import Loading from 'components/Loading';
import Text from 'components/Text';
import { useLocation } from 'react-router-dom';

export const mapStateToProps = ({ order }) => ({ order });

export const Confirmation = ({ order }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const orderUuid = params.get('order');

  return (
    <div>
      <Box margin="0 0 2">
        <Text variant="h5" align="center">
          {order && order.uuid === orderUuid ? (
            <FormattedMessage
              defaultMessage="Your booking has been confirmed."
              id="containers.Confirmation.confirmed"
            />
          ) : (
            <FormattedMessage
              defaultMessage="We are processing your booking..."
              id="containers.Confirmation.processing"
            />
          )}
        </Text>
      </Box>
      {order && order.uuid === orderUuid ? (
        <Grid container justify="center">
          <Grid item xs={12} sm={8}>
            <ConfirmationComponent order={order} />
          </Grid>
        </Grid>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default connect(mapStateToProps)(Confirmation);
