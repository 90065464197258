import { Button, Icon } from '@lemonone/components';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

const CreateBookingButton = () => {
  const history = useHistory();
  const intl = useIntl();
  return (
    <Button
      variant="text"
      color="primary"
      size="xs"
      title={intl.formatMessage({ id: 'components.CreateBookingButton.tooltip' })}
      onClick={() => history.push('/')}
    >
      <Icon icon="add_shoot" size="md" />
    </Button>
  );
};

export default CreateBookingButton;
