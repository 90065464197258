/* eslint-disable import/no-dynamic-require, global-require */

import CheckoutPage from 'containers/Checkout';
import ChooseAppointmentPage from 'containers/ChooseAppointment';
import ChooseProducts from 'containers/ChooseProducts';
import ConfirmationPage from 'containers/Confirmation';
import MyBookings from 'containers/MyBookings';
import OrganizationBookings from 'containers/OrganizationBookings';
import ContactsPage from 'containers/ContactsPage';
import EditingServicePage from 'containers/EditingServicePage';
import Rescheduling from 'containers/Rescheduling';
import NotFound from 'containers/NotFound';
import { LayoutDefault } from 'layouts/LayoutDefault';
import LayoutTableView from 'layouts/LayoutTableView';
import LayoutWithoutSteps from 'layouts/LayoutWithoutSteps';
import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import track from 'utils/analytics';
import { SettingsProvider } from '@lemonone/components';
import { NotificationProvider } from 'components/Notification';
import { useGetUserProfileQuery } from '__generated__';

import { ROUTES } from './constants';

const CONTAINERS = {
  [ROUTES.BOOKING_PRODUCT_SELECTION]: { component: ChooseProducts },
  [ROUTES.BOOKING_APPOINTMENT_SELECTION]: { component: ChooseAppointmentPage },
  [ROUTES.BOOKING_CHECKOUT]: { component: CheckoutPage },
  [ROUTES.BOOKING_CONFIRMATION]: { component: ConfirmationPage },
  [ROUTES.RESCHEDULE]: { component: Rescheduling, layout: LayoutWithoutSteps },
  [ROUTES.ORDERS_CANCEL]: { component: Rescheduling, layout: LayoutWithoutSteps },
  [ROUTES.BOOKINGS]: { component: MyBookings, layout: LayoutTableView },
  [ROUTES.BOOKINGS_ALL]: { component: OrganizationBookings, layout: LayoutTableView },
  [ROUTES.USERS]: { component: ContactsPage, layout: LayoutTableView },
  [ROUTES.REQUEST_EDITING]: { component: EditingServicePage, layout: LayoutTableView }
};

const App = () => {
  const location = useLocation();
  const getUserProfile = useGetUserProfileQuery();

  useEffect(() => {
    track('pageview', { label: location.pathname });
  }, [location]);

  if (getUserProfile.loading) return null;
  if (getUserProfile.error) return <>{getUserProfile.error.message}</>;

  const { settings: bookingUI, intl, claims } = getUserProfile.data.profile;

  return (
    <SettingsProvider settings={{ bookingUI, intl: intl ? JSON.parse(intl) : {}, claims }}>
      <NotificationProvider>
        <Switch>
          {Object.entries(CONTAINERS).map(([path, { layout: PageLayout = LayoutDefault, component: Page }], index) => (
            <Route key={index} path={path} exact>
              <PageLayout>
                <Page />
              </PageLayout>
            </Route>
          ))}
          <Route>
            <LayoutWithoutSteps>
              <NotFound />
            </LayoutWithoutSteps>
          </Route>
        </Switch>
      </NotificationProvider>
    </SettingsProvider>
  );
};

export default App;
