/* eslint-disable import/no-cycle */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withNotification } from 'components/Notification';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router';
// components
import Box from 'components/Box';
import Brand from 'components/Brand';
import Grid from 'components/Grid';
import Text from 'components/Text';
// material-ui
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Background01 from '../../assets/images/01.jpg';
import Background02 from '../../assets/images/02.jpg';
import Background03 from '../../assets/images/03.jpg';
import Background04 from '../../assets/images/04.jpg';
import Background05 from '../../assets/images/05.jpg';
import Background06 from '../../assets/images/06.jpg';
import Background07 from '../../assets/images/07.jpg';
import Background08 from '../../assets/images/08.jpg';
import Background09 from '../../assets/images/09.jpg';
import Background10 from '../../assets/images/010.jpg';
import Background11 from '../../assets/images/011.jpg';
import Background12 from '../../assets/images/012.jpg';
import Background13 from '../../assets/images/013.jpg';
import Background14 from '../../assets/images/014.jpg';
import Background15 from '../../assets/images/015.jpg';
import Background16 from '../../assets/images/016.jpg';
import Background17 from '../../assets/images/017.jpg';
import Background18 from '../../assets/images/018.jpg';
import Background19 from '../../assets/images/019.jpg';
import Background20 from '../../assets/images/020.jpg';
import Background21 from '../../assets/images/021.jpg';
import Background22 from '../../assets/images/022.jpg';
import Background23 from '../../assets/images/023.jpg';
import Background24 from '../../assets/images/024.jpg';
import Background25 from '../../assets/images/025.jpg';

const backgrounds = [
  Background01,
  Background02,
  Background03,
  Background04,
  Background05,
  Background06,
  Background07,
  Background08,
  Background09,
  Background10,
  Background11,
  Background12,
  Background13,
  Background14,
  Background15,
  Background16,
  Background17,
  Background18,
  Background19,
  Background20,
  Background21,
  Background22,
  Background23,
  Background24,
  Background25
];

const useStyles = makeStyles({
  appBar: {
    background: 'transparent',
    boxShadow: 'none',
    color: '#fff'
  },
  fullHeight: {
    backgroundColor: '#f2f2f2',
    backgroundImage: `url(${backgrounds[Math.round(Math.random() * (backgrounds.length - 1))]})`,
    backgroundSize: 'cover',
    minHeight: '100vh'
  },
  right: {
    marginLeft: 'auto'
  }
});

/**
 * User signin switch
 *
 * It will render a Login button for un-authenticated users
 * or username and avatar, name and Logout button for authenticated users.
 *
 * @func AuthPage
 */
export const AuthPage = () => {
  const classes = useStyles();
  const location = useLocation();
  const { error, loginWithRedirect } = useAuth0();

  return (
    <>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Brand href="https://www.lemonone.com" />
        </Toolbar>
      </AppBar>
      <Grid alignItems="center" className={classes.fullHeight} container justify="center">
        <Grid item xs={12} sm={8} md={4}>
          <Paper>
            <Box padding="1">
              <Text align="center" gutterBottom variant="h4">
                <FormattedMessage defaultMessage="Visuals your customers love" id="module.authentication.headline" />
              </Text>
              <Grid align="center">
                <Button
                  color="primary"
                  onClick={() => {
                    let url = '';
                    const isLogin = location.pathname === '/login';
                    if (!isLogin && location.pathname) url += location.pathname;
                    if (!isLogin && location.search) url += location.search;
                    loginWithRedirect({ appState: { returnTo: url } });
                  }}
                  variant="contained"
                >
                  <FormattedMessage
                    defaultMessage="Signup / Login to get amazing photos"
                    id="module.authentication.cta"
                  />
                </Button>
              </Grid>
              {error && (
                <Box padding="1 0 0">
                  <Text align="center" color="error" variant="body2">
                    <FormattedMessage
                      defaultMessage="Something went wrong with the authenticattion. Please try again or contact us if the issue persist."
                      id={`errors.${error.message}`}
                    />
                  </Text>
                </Box>
              )}
            </Box>
            <Box padding="1">
              <Text align="center" variant="caption">
                <FormattedMessage
                  defaultMessage="© {year} LemonOne GmbH"
                  id="module.authentication.copyright"
                  values={{ year: new Date().getFullYear() }}
                />
              </Text>
              &nbsp;
              <Text align="center" variant="caption">
                <Link href="https://www.lemonone.com/en/imprint" underline="always">
                  <FormattedMessage defaultMessage="Imprint" id="module.authentication.imprint" />
                </Link>
                &nbsp;|&nbsp;
                <Link href="https://www.lemonone.com/en/terms-conditions" underline="always">
                  <FormattedMessage defaultMessage="Privacy policy" id="module.authentication.privacy" />
                </Link>
              </Text>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default withNotification(AuthPage);
