import { Button, Icon, useIntl, useIntlScope } from '@lemonone/components';
import { capitalize } from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import track from 'utils/analytics';

import { mapStatus } from '../OrderTable/mapStatus';
import classes from './OrderInfoSidebarContent.module.css';

export type OrderInfoSidebarContentProps = {
  order: {
    uuid: string;
    createdAt: string;
    status: string;
    additionalData?: { externalId?: string; notes?: string };
    product: { name: string };
    user: { email: string };
    shoot: {
      start: string;
      location: { address: string; name: string; timeZoneId: string };
      url?: string;
      price?: number;
      deliveredAt?: string;
      feedback?: string;
    };
  };
};

type OrderInfoSidebarContentItem = {
  key?: React.ReactNode;
  value: React.ReactNode;
};

export const OrderInfoSidebarContent: React.FC<OrderInfoSidebarContentProps> = ({ order }) => {
  const shortOrderUuid = order.uuid.slice(0, 8);
  const i18n = useIntlScope('components.OrderTable.');
  const common = useIntlScope('@lemonone/intl/text:');
  const intl = useIntl();
  const status = mapStatus(order);
  const history = useHistory();

  const sidebarItems: OrderInfoSidebarContentItem[] = [
    {
      key: common('date'),
      value: intl.formatDate(order.shoot.start, {
        timeZone: order.shoot.location.timeZoneId,
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false
      })
    },
    {
      key: common('orderId'),
      value: shortOrderUuid
    }
  ];

  if (order.additionalData?.externalId) {
    sidebarItems.push({
      key: common('externalReference'),
      value: order.additionalData.externalId
    });
  }

  if (order.additionalData?.notes) {
    sidebarItems.push({
      key: common('notes'),
      value: <p className={classes.multilineText}>{order.additionalData.notes}</p>
    });
  }

  sidebarItems.push({
    key: i18n('creator'),
    value: order.user.email
  });

  sidebarItems.push({
    key: i18n('createdAt'),
    value: intl.formatDate(order.createdAt, {
      timeZone: order.shoot.location.timeZoneId,
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    })
  });

  sidebarItems.push({
    key: i18n('product'),
    value: order.product.name
  });

  sidebarItems.push({
    key: i18n('address'),
    value: (
      <>
        {order.shoot.location.name && <>{order.shoot.location.name}, &nbsp;</>}
        {order.shoot.location.address}
      </>
    )
  });

  if (order.shoot.deliveredAt) {
    sidebarItems.push({
      key: i18n('deliveredAt'),
      value: intl.formatDate(new Date(order.shoot.deliveredAt), {
        timeZone: order.shoot.location.timeZoneId,
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false
      })
    });
  }

  sidebarItems.push({
    key: i18n('status'),
    value: capitalize(i18n(`status:${status}`)?.toString())
  });

  if (order.shoot.price) {
    sidebarItems.push({
      key: common('price'),
      value: intl.formatNumber(order.shoot.price, { format: 'currency' })
    });
  }

  if (order.shoot.feedback) {
    sidebarItems.push({
      key: common('feedback'),
      value: <p className={classes.multilineText}>{order.shoot.feedback}</p>
    });
  }

  const actions: React.ReactNode[] = [];

  if (status === 'fulfilled') {
    actions.push(
      <Button
        size="xs"
        title={common('downloadUrl')?.toString()}
        variant="contained"
        color="primary"
        type="button"
        onClick={() => window.open(order.shoot.url)}
      >
        <Icon icon="download" size="md" variant="white" />
        {i18n('downloadUrl')}
      </Button>
    );
  }

  if (status === 'scheduled') {
    actions.push(
      <Button
        size="xs"
        variant="contained"
        color="primary"
        title={i18n('rescheduleUrl')?.toString()}
        type="button"
        onClick={() => {
          track('onClickReschedule', { label: order.uuid });
          history.push(`/reschedule?id=${order.uuid}`);
        }}
        data-e2e="rescheduleBookingButton"
      >
        <Icon icon="edit_date" size="md" variant="white" />
        <>{i18n('rescheduleUrl')}</>
      </Button>
    );
  }

  sidebarItems.push({
    value: (
      <div className={classes.actions}>
        {actions.map((action, index) => {
          return (
            <div className={classes.action} key={index}>
              {action}
            </div>
          );
        })}
      </div>
    )
  });

  return (
    <ul className={classes.list}>
      {sidebarItems.map((item, index) => {
        return (
          <li key={index} className={classes.listItem}>
            {item.key && <div className={classes.fieldName}>{item.key}</div>}
            <div className={classes.fieldValue}>{item.value}</div>
          </li>
        );
      })}
    </ul>
  );
};
