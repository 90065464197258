import { geocodeByAddress } from 'react-places-autocomplete';

import { getAddressComponents } from '../../utils/getAddressComponents';

const getCountryCode = addressComponents => {
  const country = addressComponents
    .filter(({ types }) => {
      const isCountry = types.indexOf('country') !== -1;
      const isPolitical = types.indexOf('political') !== -1;
      return isCountry && isPolitical;
    })
    .pop();

  return country && country.short_name;
};

const isQuarantinedCountry = addressComponents => {
  const quarantinedCountries = process.env.REACT_APP_BOOKINGUI_COVID19
    ? process.env.REACT_APP_BOOKINGUI_COVID19.split(',')
    : [];

  const countryCode = getCountryCode(addressComponents);

  return quarantinedCountries.indexOf(countryCode) !== -1;
};

/**
 * Validate address accuracy
 *
 * @method validFullAddress
 * @param  {String} address
 * @return {Boolean}
 */
export const validateAddress = async address => {
  const results = await geocodeByAddress(address);

  if (!results || !results.length) {
    return { valid: false, error: 'Not found' };
  }

  const geo = results[0];
  const addressComponents = getAddressComponents(geo);

  const isPreciseAddress =
    addressComponents.city &&
    addressComponents.country &&
    addressComponents.countryCode &&
    (geo.plus_code ?? addressComponents.postalCode) &&
    (geo.plus_code ?? addressComponents.street) &&
    (geo.plus_code ?? addressComponents.streetNumber);

  if (!isPreciseAddress && geo.geometry.location_type !== 'ROOFTOP') {
    return { valid: false, error: 'invalid' };
  }

  if (isQuarantinedCountry(geo.address_components)) {
    return { valid: false, error: 'COVID-19 Quarantine' };
  }

  return { valid: true };
};
