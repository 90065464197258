import { DetailsList, Row, Typography, useIntlScope } from '@lemonone/components';
import { useIntl } from 'react-intl';
import { getDurationParts } from '@lemonone/helpers';

import classes from './OrderDetails.module.css';

export const OrderDetails = ({ orderInfo }) => {
  const i18n = useIntl();
  const intl = useIntlScope('@lemonone/intl/text:');
  const tpl = useIntlScope('@lemonone/bookingui/components/OrderDetails:');
  return (
    <>
      <Row>
        <Typography as="h2" size="sm">
          {tpl('title')}
        </Typography>
      </Row>
      <Row className={classes.root}>
        <DetailsList
          items={[
            {
              icon: 'calendar',
              content: (
                <Typography>
                  {i18n.formatDate(orderInfo.shootDateTime, {
                    timeZone: orderInfo.shootTimeZone,
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                  })}
                </Typography>
              )
            },
            {
              icon: 'location',
              content: <Typography>{orderInfo.shootAddress}</Typography>
            },
            {
              icon: 'time',
              content: <Typography>{intl('duration_hours', getDurationParts(orderInfo.shootDurationMins))}</Typography>,
              sub: <Typography dimmed>{intl('estimation')}</Typography>
            },
            {
              icon: 'product',
              content: <Typography>{orderInfo.productName}</Typography>
            }
          ]}
        />
      </Row>
    </>
  );
};
