import { Alert, Icon, Row, Typography } from '@lemonone/components';
import React from 'react';
import { useIntl } from 'react-intl';

import OrderDetails from '../OrderDetails';

const RescheduleShootFormScheduled = ({ orderInfo }) => {
  const intl = useIntl();
  return (
    <>
      <Row>
        <Alert variant="success">
          <Icon icon="accepted" variant="success" />
          <Typography color="success" as="p">
            {intl.formatMessage({
              id: 'containers.Confirmation.confirmed'
            })}
          </Typography>
        </Alert>
      </Row>
      <Row>
        <OrderDetails orderInfo={orderInfo} />
      </Row>
    </>
  );
};

export default RescheduleShootFormScheduled;
