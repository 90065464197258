import React from 'react';
import THEME_SOURCE from 'assets/theme.json';
// material-ui
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

/**
 * Generate a theme base on the options received from `THEME_SOURCE`
 * For available customizations see Material-UI documentation:
 * @see {@link https://material-ui.com/customization/default-theme/}
 * @type {Object}
 */
export const THEME = createMuiTheme(THEME_SOURCE);

/**
 * Wrap Material-UI ThemeProvider
 *
 * @see {@link https://material-ui.com/customization/themes/#muithemeprovider}
 * @func ThemeProvider
 * @param {Object} props
 * @return {Function} React.Component
 */
export const ThemeProvider = ({ children }) => (
  <MuiThemeProvider theme={THEME}>
    <CssBaseline />
    {children}
  </MuiThemeProvider>
);

export default ThemeProvider;
