import React, { Component } from 'react';
import validate from './validator';

function validateEventTarget(target) {
  let validateResult = true;

  if (target.type === 'checkbox' && target.required) {
    validateResult = validate('checkbox', target.checked);
  } else if (target.name === 'externalId') {
    validateResult = validate('externalId', target.value.trim());
  } else if (target.required) {
    validateResult = validate(target.type, target.value.trim());
  }

  if (typeof validateResult === 'string') {
    return validateResult;
  }

  return !validateResult;
}

/**
 * Form wrapper that takes care of state, actions and validation.
 *
 * Relays on HTML form standard validation.
 *
 * Uses [renderProps pattern](https://reactjs.org/docs/render-props.html).
 *
 * ### Props
 *
 * - **defaultValues** `Object` <br>
 *   The default values, useful when not controlling the component.
 *   For required fields is mandatory to specify a _defaultValue_.
 *
 * - **onSubmit** `(values:Object) => void` <br>
 *   This function will be called when user submit a form.
 *   It gets and object with all fields values.
 *
 * - **children** `(values:Object, actions:Object, meta:Object) => ReactElement` <br>
 *   renderProps function to call on render with the following arguments:
 *
 *    - **values** Current field values.
 *
 *    - **actions** <br>
 *
 *        - `onBlur:Function`
 *        - `onChange:Function`
 *        - `onSubmit:Function`
 *
 *    - **meta** <br>
 *
 *        - `errors:Object` fields that are not valid
 *        - `loading:Boolean`
 *        - `touched:Object` fields that have been modified
 *
 * ### Validation
 *
 * Currently there is only 3 types of validators: text, email and checkbox. <br>
 * Look into [validators.js](https://github.com/lemononeBerlin/bookingui/blob/master/src/components/Form/validator.js) for more details.
 *
 * @class Form
 * @extends Component
 */
class Form extends Component {
  // initial state
  state = {
    errors: this.props.defaultValues
      ? Object.keys(this.props.defaultValues).reduce((prev, fieldName) => ({ ...prev, [fieldName]: true }), {})
      : {},
    loading: false,
    touched: {},
    values: this.props.defaultValues || {}
  };

  // event handlers

  handleChange = e => {
    const { checked, name, type, value } = e.target;

    this.setState(state => ({
      errors: {
        ...state.errors,
        [name]: validateEventTarget(e.target)
      },
      values: {
        ...state.values,
        [name]: type === 'checkbox' ? checked : value
      }
    }));
  };

  handleBlur = e => {
    const { name, required } = e.target;

    this.setState(state => ({
      errors: {
        ...state.errors,
        [name]: required && validateEventTarget(e)
      },
      touched: {
        ...state.touched,
        [name]: true
      }
    }));
  };

  handleSubmit = async e => {
    const { onSubmit } = this.props;
    e.preventDefault();
    this.setState({ loading: true });
    if (onSubmit) await onSubmit(this.state.values);
    this.setState({ loading: false });
  };

  render() {
    const { errors, loading, touched, values } = this.state;
    const actions = {
      onBlur: this.handleBlur,
      onChange: this.handleChange,
      onSubmit: this.handleSubmit
    };
    const meta = {
      errors,
      loading,
      touched
    };

    return <form onSubmit={this.handleSubmit}>{this.props.children(values, actions, meta)}</form>;
  }
}

export default Form;
