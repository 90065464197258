import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'unistore/react';
import { useHistory } from 'react-router-dom';

import { Row } from '@lemonone/components';

import {
  chooseAppointment as chooseAppointmentAction,
  shootRequestReceived as shootRequestReceivedAction,
  submitAddress as submitAddressAction
} from 'actions';

import InputGeoSearch from 'components/InputGeoSearch';
import { withNotification } from '../components/Notification';
import styles from './ChooseAppointment.module.css';
import SelectDateTime from '../components/SelectDateTime';
import ShootRequest from '../components/ShootRequest';

export const ChooseAppointment = ({
  chooseAppointment,
  address,
  product,
  shootRequest,
  shootRequestReceived,
  submitAddress,
  onNotification
}) => {
  const history = useHistory();

  if (!product) {
    history.push('/');
    return false;
  }

  return (
    <>
      <Row>
        <div className={styles.ShootAddress}>
          <div className={styles.ShootAddressText}>
            <FormattedMessage id="Tell us where you need the photo shoot" />
          </div>
        </div>
      </Row>
      <Row>
        <InputGeoSearch onSubmit={submitAddress} defaultValue={address} onError={onNotification} />
      </Row>
      {address && (
        <Row>
          <div style={{ maxWidth: 800, margin: '0 auto' }}>
            <SelectDateTime
              address={address}
              productUuid={product.uuid}
              duration={product.duration}
              onAppointmentSelect={appointment => {
                chooseAppointment(appointment);
                history.push('/checkout');
              }}
              renderZeroCapacitiesFallback={() => (
                <ShootRequest
                  address={address}
                  product={product}
                  shootRequest={shootRequest}
                  shootRequestReceived={shootRequestReceived}
                  onNotification={onNotification}
                />
              )}
            />
          </div>
        </Row>
      )}
    </>
  );
};

export default withNotification(
  connect(
    ({ address, shootRequest, product }) => ({
      address,
      shootRequest,
      product
    }),
    {
      chooseAppointment: chooseAppointmentAction,
      shootRequestReceived: shootRequestReceivedAction,
      submitAddress: submitAddressAction
    }
  )(ChooseAppointment)
);
