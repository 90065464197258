/**
 * valid text must have at least 1 characters
 *
 * @func validateText
 * @param {String} [text=''] text to validate
 * @return {Boolean}
 */
export const validateText = (text = '') => !!text && text.length > 0;

/**
 * valid checkbox must be checked
 *
 * @func validateCheckbox
 * @param {Boolean} [checked=false]
 * @return {Boolean}
 */
export const validateCheckbox = (checked = false) => checked;

/**
 * There are a couple of standards that define how an email addrese should look
 * like:
 * - [RFC 5321 - Simple Mail Transfer Protocol]{@link https://tools.ietf.org/html/rfc5321}
 * - [RFC 5322 - Internet Message Format]{@link https://tools.ietf.org/html/rfc5322}
 *    - replaces: [RFC 2822]{@link https://tools.ietf.org/html/rfc2822#section-3.4.1}
 *
 * Getting and implementation that adheres stricly to these standards is costly.
 * Therefore our implementation uses a simple regexp that validate most common
 * and frequently used email address patterns.
 *
 * The more accurate way validate an email address is by sending an email and
 * asking the user to verify that the email has been properly delivered.
 *
 * If you are bored here you have some literature on the topic:
 * - {@link https://stackoverflow.com/questions/201323/how-to-validate-an-email-address-using-a-regular-expression/201378#201378}
 * - {@link https://www.regular-expressions.info/email.html}
 * - {@link https://emailregex.com/}
 * - {@link https://jsfiddle.net/ghvj4gy9/embedded/result,js/}
 *
 * @func validateEmail
 * @param {String} [email='']
 * @return {Boolean}
 */
export const validateEmail = (email = '') => {
  const re = /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/gim;

  // email can't be longer than 254 characters
  if (email.length > 254) return false;

  return re.test(email);
};

export const validateExternalId = extenalId => {
  if (extenalId && extenalId.length > 72) {
    return 'components.CheckoutFormHost.Labels.externalIdTooLongError';
  }

  return true;
};

const validators = {
  checkbox: validateCheckbox,
  email: validateEmail,
  text: validateText,
  tel: validateText,
  externalId: validateExternalId
};

const validate = (validatorType, value) => {
  const validator = validators[validatorType];
  if (!validator) return false;
  return validator(value);
};

export default validate;
