/* eslint-disable */
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Currency: string;
  Date: string;
  DateTime: string;
  EmailAddress: string;
  PhoneNumber: string;
  PositiveFloat: number;
  PositiveInt: number;
  PostalCode: string;
  URL: string;
  UUID: string;
  UnsignedFloat: number;
  UnsignedInt: number;
};

export type Query = {
  __typename?: 'Query';
  getCapacityDays: Array<CapacityDay>;
  getCapacityTimeslots: Array<CapacityTimeslot>;
  getContacts: Array<Contact>;
  getMyBillingAddresses: Array<BillingAddress>;
  getMyOrders: Array<Order>;
  getMyProducts: Array<Product>;
  getOrder?: Maybe<OrderInfo>;
  getOrdersByOrganization: Array<Order>;
  getUserProfile: UserProfile;
  isPrepaid?: Maybe<Scalars['Boolean']>;
};

export type QueryGetCapacityDaysArgs = {
  address: Scalars['String'];
  productUuid: Scalars['String'];
};

export type QueryGetCapacityTimeslotsArgs = {
  address: Scalars['String'];
  productUuid: Scalars['String'];
  date: Scalars['String'];
};

export type QueryGetMyOrdersArgs = {
  uuid?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
};

export type QueryGetMyProductsArgs = {
  uuid?: InputMaybe<Scalars['String']>;
};

export type QueryGetOrderArgs = {
  uuid: Scalars['String'];
};

export type QueryGetOrdersByOrganizationArgs = {
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
};

export type CapacityDay = {
  __typename?: 'CapacityDay';
  date: Scalars['String'];
  timeZoneId: Scalars['String'];
  recommended: Scalars['Boolean'];
};

export type CapacityTimeslot = {
  __typename?: 'CapacityTimeslot';
  start: Scalars['String'];
  end: Scalars['String'];
  timeZoneId: Scalars['String'];
  recommended: Scalars['Boolean'];
};

export type Contact = {
  __typename?: 'Contact';
  email: Scalars['String'];
};

export type BillingAddress = {
  __typename?: 'BillingAddress';
  name: Scalars['String'];
  accountNumber?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  countryCode: Scalars['String'];
  createdAt?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  invoiceRecipient?: Maybe<Scalars['String']>;
  invoiceRecipientEmail?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  street: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  uuid: Scalars['ID'];
  vatNumber?: Maybe<Scalars['String']>;
  vatValidated?: Maybe<Scalars['Boolean']>;
};

export type Order = {
  __typename?: 'Order';
  additionalData?: Maybe<AdditionalData>;
  createdAt?: Maybe<Scalars['String']>;
  host?: Maybe<Host>;
  payment?: Maybe<Payment>;
  product?: Maybe<Product>;
  shoot?: Maybe<Shoot>;
  status: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  uuid: Scalars['ID'];
};

export type AdditionalData = {
  __typename?: 'AdditionalData';
  affiliateId?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type Host = {
  __typename?: 'Host';
  email?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type Payment = {
  __typename?: 'Payment';
  status: Scalars['String'];
  transactionId?: Maybe<Scalars['String']>;
};

export type Product = {
  __typename?: 'Product';
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  price?: Maybe<Money>;
  duration?: Maybe<Scalars['Float']>;
  uuid?: Maybe<Scalars['ID']>;
  shotByOwner?: Maybe<Scalars['Boolean']>;
};

export type Money = {
  __typename?: 'Money';
  amount: Scalars['Int'];
  currency: Scalars['String'];
};

export type Shoot = {
  __typename?: 'Shoot';
  end: Scalars['String'];
  location: Location;
  googleCalendarId?: Maybe<Scalars['String']>;
  googleEventId?: Maybe<Scalars['String']>;
  googleCalendarSummary?: Maybe<Scalars['String']>;
  orderUuid: Scalars['String'];
  photographerId?: Maybe<Scalars['String']>;
  start: Scalars['String'];
  status: Scalars['String'];
  uuid: Scalars['ID'];
  url?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  deliveredAt?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
};

export type Location = {
  __typename?: 'Location';
  address: Scalars['String'];
  geometry?: Maybe<Geometry>;
  name?: Maybe<Scalars['String']>;
  timeZoneId?: Maybe<Scalars['String']>;
};

export type Geometry = {
  __typename?: 'Geometry';
  coordinates?: Maybe<Array<Maybe<Scalars['Float']>>>;
  type?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  id?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  organizationUuid?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  sub?: Maybe<Scalars['ID']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type OrderInfo = {
  __typename?: 'OrderInfo';
  orderUuid: Scalars['String'];
  productName?: Maybe<Scalars['String']>;
  productUuid: Scalars['String'];
  orderStatus: Scalars['String'];
  shootDateTime: Scalars['String'];
  shootAddress: Scalars['String'];
  shootTimeZone: Scalars['String'];
  shootDurationMins: Scalars['Int'];
};

export type UserProfile = {
  __typename?: 'UserProfile';
  settings?: Maybe<Settings>;
  intl?: Maybe<Scalars['String']>;
  claims: Claims;
};

export type Settings = {
  __typename?: 'Settings';
  banner?: Maybe<Scalars['String']>;
  externalIdRequired?: Maybe<Scalars['Boolean']>;
};

export type Claims = {
  __typename?: 'Claims';
  roles: Array<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  cancelOrder: OrderInfo;
  createOrder: Order;
  createUploadUrl?: Maybe<Scalars['String']>;
  photoShootRequest: ShootRequest;
  rescheduleShootForOrder: OrderInfo;
};

export type MutationCancelOrderArgs = {
  orderId: Scalars['String'];
  cancellationNotes?: InputMaybe<Scalars['String']>;
};

export type MutationCreateOrderArgs = {
  order: OrderInput;
};

export type MutationCreateUploadUrlArgs = {
  name?: InputMaybe<Scalars['String']>;
  mimeType: Scalars['String'];
};

export type MutationPhotoShootRequestArgs = {
  shootRequest: ShootRequestInput;
};

export type MutationRescheduleShootForOrderArgs = {
  orderId: Scalars['String'];
  cancellationNotes?: InputMaybe<Scalars['String']>;
  start: Scalars['String'];
};

export type OrderInput = {
  additionalData?: InputMaybe<AdditionalDataInput>;
  shoot: ShootInput;
  host?: InputMaybe<HostInput>;
  paymentToken?: InputMaybe<Scalars['String']>;
  productId: Scalars['String'];
  billingAddressUuid?: InputMaybe<Scalars['String']>;
};

export type AdditionalDataInput = {
  affiliateId?: InputMaybe<Scalars['String']>;
  attachment?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
};

export type ShootInput = {
  end: Scalars['String'];
  location: LocationInput;
  calendarId?: InputMaybe<Scalars['String']>;
  start: Scalars['String'];
  timeZoneId?: InputMaybe<Scalars['String']>;
};

export type LocationInput = {
  address: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export type HostInput = {
  email?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type ShootRequestInput = {
  address: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  productId: Scalars['String'];
};

export type ShootRequest = {
  __typename?: 'ShootRequest';
  address?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type Availability = {
  __typename?: 'Availability';
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  timeZoneId?: Maybe<Scalars['String']>;
  calendarId?: Maybe<Scalars['String']>;
  guaranteed: Scalars['Boolean'];
};

export type OrderFragmentFragment = {
  __typename?: 'Order';
  uuid: string;
  createdAt?: Maybe<string>;
  status: string;
  additionalData?: Maybe<{ __typename?: 'AdditionalData'; notes?: Maybe<string>; externalId?: Maybe<string> }>;
  product?: Maybe<{ __typename?: 'Product'; name?: Maybe<string> }>;
  shoot?: Maybe<{
    __typename?: 'Shoot';
    end: string;
    start: string;
    price?: Maybe<number>;
    url?: Maybe<string>;
    deliveredAt?: Maybe<string>;
    cancelledAt?: Maybe<string>;
    status: string;
    feedback?: Maybe<string>;
    location: { __typename?: 'Location'; address: string; name?: Maybe<string>; timeZoneId?: Maybe<string> };
  }>;
  user?: Maybe<{ __typename?: 'User'; email: string }>;
};

export type CreateOrderMutationVariables = Exact<{
  order: OrderInput;
}>;

export type CreateOrderMutation = {
  __typename?: 'Mutation';
  createOrder: {
    __typename?: 'Order';
    uuid: string;
    product?: Maybe<{
      __typename?: 'Product';
      description?: Maybe<string>;
      duration?: Maybe<number>;
      image?: Maybe<string>;
      name?: Maybe<string>;
      uuid?: Maybe<string>;
      price?: Maybe<{ __typename?: 'Money'; amount: number; currency: string }>;
    }>;
    shoot?: Maybe<{
      __typename?: 'Shoot';
      end: string;
      googleCalendarSummary?: Maybe<string>;
      start: string;
      location: { __typename?: 'Location'; address: string; timeZoneId?: Maybe<string> };
    }>;
    user?: Maybe<{
      __typename?: 'User';
      name?: Maybe<string>;
      email: string;
      phone?: Maybe<string>;
      billingAddress?: Maybe<string>;
      city?: Maybe<string>;
      companyName?: Maybe<string>;
      zipcode?: Maybe<string>;
    }>;
  };
};

export type GetCapacityDaysQueryVariables = Exact<{
  address: Scalars['String'];
  productUuid: Scalars['String'];
}>;

export type GetCapacityDaysQuery = {
  __typename?: 'Query';
  capacityDays: Array<{ __typename?: 'CapacityDay'; date: string; timeZoneId: string; recommended: boolean }>;
};

export type GetCapacityTimeslotsQueryVariables = Exact<{
  address: Scalars['String'];
  productUuid: Scalars['String'];
  date: Scalars['String'];
}>;

export type GetCapacityTimeslotsQuery = {
  __typename?: 'Query';
  capacityTimeslots: Array<{
    __typename?: 'CapacityTimeslot';
    start: string;
    end: string;
    timeZoneId: string;
    recommended: boolean;
  }>;
};

export type GetContactsQueryVariables = Exact<{ [key: string]: never }>;

export type GetContactsQuery = { __typename?: 'Query'; contacts: Array<{ __typename?: 'Contact'; email: string }> };

export type GetMyBillingAddressesQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyBillingAddressesQuery = {
  __typename?: 'Query';
  billingAddresses: Array<{
    __typename?: 'BillingAddress';
    name: string;
    accountNumber?: Maybe<string>;
    city?: Maybe<string>;
    companyName?: Maybe<string>;
    country: string;
    countryCode: string;
    createdAt?: Maybe<string>;
    currency?: Maybe<string>;
    invoiceRecipient?: Maybe<string>;
    invoiceRecipientEmail?: Maybe<string>;
    postalCode: string;
    state?: Maybe<string>;
    stateCode?: Maybe<string>;
    street: string;
    updatedAt?: Maybe<string>;
    uuid: string;
    vatNumber?: Maybe<string>;
    vatValidated?: Maybe<boolean>;
  }>;
};

export type GetMyBookingsQueryVariables = Exact<{
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;

export type GetMyBookingsQuery = {
  __typename?: 'Query';
  bookings: Array<{
    __typename?: 'Order';
    uuid: string;
    createdAt?: Maybe<string>;
    status: string;
    additionalData?: Maybe<{ __typename?: 'AdditionalData'; notes?: Maybe<string>; externalId?: Maybe<string> }>;
    product?: Maybe<{ __typename?: 'Product'; name?: Maybe<string> }>;
    shoot?: Maybe<{
      __typename?: 'Shoot';
      end: string;
      start: string;
      price?: Maybe<number>;
      url?: Maybe<string>;
      deliveredAt?: Maybe<string>;
      cancelledAt?: Maybe<string>;
      status: string;
      feedback?: Maybe<string>;
      location: { __typename?: 'Location'; address: string; name?: Maybe<string>; timeZoneId?: Maybe<string> };
    }>;
    user?: Maybe<{ __typename?: 'User'; email: string }>;
  }>;
};

export type GetMyProductsQueryVariables = Exact<{
  uuid?: Maybe<Scalars['String']>;
}>;

export type GetMyProductsQuery = {
  __typename?: 'Query';
  getMyProducts: Array<{
    __typename?: 'Product';
    description?: Maybe<string>;
    image?: Maybe<string>;
    duration?: Maybe<number>;
    name?: Maybe<string>;
    shotByOwner?: Maybe<boolean>;
    uuid?: Maybe<string>;
    price?: Maybe<{ __typename?: 'Money'; amount: number; currency: string }>;
  }>;
};

export type GetOrganizationsBookingsQueryVariables = Exact<{
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;

export type GetOrganizationsBookingsQuery = {
  __typename?: 'Query';
  bookings: Array<{
    __typename?: 'Order';
    uuid: string;
    createdAt?: Maybe<string>;
    status: string;
    additionalData?: Maybe<{ __typename?: 'AdditionalData'; notes?: Maybe<string>; externalId?: Maybe<string> }>;
    product?: Maybe<{ __typename?: 'Product'; name?: Maybe<string> }>;
    shoot?: Maybe<{
      __typename?: 'Shoot';
      end: string;
      start: string;
      price?: Maybe<number>;
      url?: Maybe<string>;
      deliveredAt?: Maybe<string>;
      cancelledAt?: Maybe<string>;
      status: string;
      feedback?: Maybe<string>;
      location: { __typename?: 'Location'; address: string; name?: Maybe<string>; timeZoneId?: Maybe<string> };
    }>;
    user?: Maybe<{ __typename?: 'User'; email: string }>;
  }>;
};

export type GetUserProfileQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserProfileQuery = {
  __typename?: 'Query';
  profile: {
    __typename?: 'UserProfile';
    intl?: Maybe<string>;
    settings?: Maybe<{ __typename?: 'Settings'; banner?: Maybe<string>; externalIdRequired?: Maybe<boolean> }>;
    claims: { __typename?: 'Claims'; roles: Array<string> };
  };
};

export type IsPrepaidQueryVariables = Exact<{ [key: string]: never }>;

export type IsPrepaidQuery = { __typename?: 'Query'; isPrepaid?: Maybe<boolean> };

export type PhotoShootRequestMutationVariables = Exact<{
  shootRequest: ShootRequestInput;
}>;

export type PhotoShootRequestMutation = {
  __typename?: 'Mutation';
  photoShootRequest: { __typename?: 'ShootRequest'; status?: Maybe<string> };
};

export const OrderFragmentFragmentDoc = gql`
  fragment OrderFragment on Order {
    uuid
    additionalData {
      notes
      externalId
    }
    createdAt
    product {
      name
    }
    shoot {
      end
      start
      price
      location {
        address
        name
        timeZoneId
      }
      url
      deliveredAt
      cancelledAt
      status
      feedback
    }
    status
    user {
      email
    }
  }
`;
export const CreateOrderDocument = gql`
  mutation createOrder($order: OrderInput!) {
    createOrder(order: $order) {
      product {
        description
        duration
        image
        name
        price {
          amount
          currency
        }
        uuid
      }
      shoot {
        end
        googleCalendarSummary
        location {
          address
          timeZoneId
        }
        start
      }
      user {
        name
        email
        phone
        billingAddress
        city
        companyName
        zipcode
      }
      uuid
    }
  }
`;
export type CreateOrderMutationFn = ApolloReactCommon.MutationFunction<
  CreateOrderMutation,
  CreateOrderMutationVariables
>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCreateOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, options);
}
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = ApolloReactCommon.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateOrderMutation,
  CreateOrderMutationVariables
>;
export const GetCapacityDaysDocument = gql`
  query GetCapacityDays($address: String!, $productUuid: String!) {
    capacityDays: getCapacityDays(address: $address, productUuid: $productUuid) {
      date
      timeZoneId
      recommended
    }
  }
`;

/**
 * __useGetCapacityDaysQuery__
 *
 * To run a query within a React component, call `useGetCapacityDaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCapacityDaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCapacityDaysQuery({
 *   variables: {
 *      address: // value for 'address'
 *      productUuid: // value for 'productUuid'
 *   },
 * });
 */
export function useGetCapacityDaysQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetCapacityDaysQuery, GetCapacityDaysQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetCapacityDaysQuery, GetCapacityDaysQueryVariables>(
    GetCapacityDaysDocument,
    options
  );
}
export function useGetCapacityDaysLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCapacityDaysQuery, GetCapacityDaysQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetCapacityDaysQuery, GetCapacityDaysQueryVariables>(
    GetCapacityDaysDocument,
    options
  );
}
export type GetCapacityDaysQueryHookResult = ReturnType<typeof useGetCapacityDaysQuery>;
export type GetCapacityDaysLazyQueryHookResult = ReturnType<typeof useGetCapacityDaysLazyQuery>;
export type GetCapacityDaysQueryResult = ApolloReactCommon.QueryResult<
  GetCapacityDaysQuery,
  GetCapacityDaysQueryVariables
>;
export const GetCapacityTimeslotsDocument = gql`
  query GetCapacityTimeslots($address: String!, $productUuid: String!, $date: String!) {
    capacityTimeslots: getCapacityTimeslots(address: $address, productUuid: $productUuid, date: $date) {
      start
      end
      timeZoneId
      recommended
    }
  }
`;

/**
 * __useGetCapacityTimeslotsQuery__
 *
 * To run a query within a React component, call `useGetCapacityTimeslotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCapacityTimeslotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCapacityTimeslotsQuery({
 *   variables: {
 *      address: // value for 'address'
 *      productUuid: // value for 'productUuid'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetCapacityTimeslotsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetCapacityTimeslotsQuery, GetCapacityTimeslotsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetCapacityTimeslotsQuery, GetCapacityTimeslotsQueryVariables>(
    GetCapacityTimeslotsDocument,
    options
  );
}
export function useGetCapacityTimeslotsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCapacityTimeslotsQuery, GetCapacityTimeslotsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetCapacityTimeslotsQuery, GetCapacityTimeslotsQueryVariables>(
    GetCapacityTimeslotsDocument,
    options
  );
}
export type GetCapacityTimeslotsQueryHookResult = ReturnType<typeof useGetCapacityTimeslotsQuery>;
export type GetCapacityTimeslotsLazyQueryHookResult = ReturnType<typeof useGetCapacityTimeslotsLazyQuery>;
export type GetCapacityTimeslotsQueryResult = ApolloReactCommon.QueryResult<
  GetCapacityTimeslotsQuery,
  GetCapacityTimeslotsQueryVariables
>;
export const GetContactsDocument = gql`
  query GetContacts {
    contacts: getContacts {
      email
    }
  }
`;

/**
 * __useGetContactsQuery__
 *
 * To run a query within a React component, call `useGetContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContactsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetContactsQuery, GetContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetContactsQuery, GetContactsQueryVariables>(GetContactsDocument, options);
}
export function useGetContactsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContactsQuery, GetContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetContactsQuery, GetContactsQueryVariables>(GetContactsDocument, options);
}
export type GetContactsQueryHookResult = ReturnType<typeof useGetContactsQuery>;
export type GetContactsLazyQueryHookResult = ReturnType<typeof useGetContactsLazyQuery>;
export type GetContactsQueryResult = ApolloReactCommon.QueryResult<GetContactsQuery, GetContactsQueryVariables>;
export const GetMyBillingAddressesDocument = gql`
  query GetMyBillingAddresses {
    billingAddresses: getMyBillingAddresses {
      name
      accountNumber
      city
      companyName
      country
      countryCode
      createdAt
      currency
      invoiceRecipient
      invoiceRecipientEmail
      postalCode
      state
      stateCode
      street
      updatedAt
      uuid
      vatNumber
      vatValidated
    }
  }
`;

/**
 * __useGetMyBillingAddressesQuery__
 *
 * To run a query within a React component, call `useGetMyBillingAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyBillingAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyBillingAddressesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyBillingAddressesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyBillingAddressesQuery, GetMyBillingAddressesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetMyBillingAddressesQuery, GetMyBillingAddressesQueryVariables>(
    GetMyBillingAddressesDocument,
    options
  );
}
export function useGetMyBillingAddressesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMyBillingAddressesQuery, GetMyBillingAddressesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetMyBillingAddressesQuery, GetMyBillingAddressesQueryVariables>(
    GetMyBillingAddressesDocument,
    options
  );
}
export type GetMyBillingAddressesQueryHookResult = ReturnType<typeof useGetMyBillingAddressesQuery>;
export type GetMyBillingAddressesLazyQueryHookResult = ReturnType<typeof useGetMyBillingAddressesLazyQuery>;
export type GetMyBillingAddressesQueryResult = ApolloReactCommon.QueryResult<
  GetMyBillingAddressesQuery,
  GetMyBillingAddressesQueryVariables
>;
export const GetMyBookingsDocument = gql`
  query GetMyBookings($startDate: String, $endDate: String) {
    bookings: getMyOrders(startDate: $startDate, endDate: $endDate) {
      ...OrderFragment
    }
  }
  ${OrderFragmentFragmentDoc}
`;

/**
 * __useGetMyBookingsQuery__
 *
 * To run a query within a React component, call `useGetMyBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyBookingsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetMyBookingsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyBookingsQuery, GetMyBookingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetMyBookingsQuery, GetMyBookingsQueryVariables>(GetMyBookingsDocument, options);
}
export function useGetMyBookingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMyBookingsQuery, GetMyBookingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetMyBookingsQuery, GetMyBookingsQueryVariables>(GetMyBookingsDocument, options);
}
export type GetMyBookingsQueryHookResult = ReturnType<typeof useGetMyBookingsQuery>;
export type GetMyBookingsLazyQueryHookResult = ReturnType<typeof useGetMyBookingsLazyQuery>;
export type GetMyBookingsQueryResult = ApolloReactCommon.QueryResult<GetMyBookingsQuery, GetMyBookingsQueryVariables>;
export const GetMyProductsDocument = gql`
  query GetMyProducts($uuid: String) {
    getMyProducts(uuid: $uuid) {
      description
      image
      duration
      name
      shotByOwner
      price {
        amount
        currency
      }
      uuid
    }
  }
`;

/**
 * __useGetMyProductsQuery__
 *
 * To run a query within a React component, call `useGetMyProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyProductsQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetMyProductsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyProductsQuery, GetMyProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetMyProductsQuery, GetMyProductsQueryVariables>(GetMyProductsDocument, options);
}
export function useGetMyProductsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMyProductsQuery, GetMyProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetMyProductsQuery, GetMyProductsQueryVariables>(GetMyProductsDocument, options);
}
export type GetMyProductsQueryHookResult = ReturnType<typeof useGetMyProductsQuery>;
export type GetMyProductsLazyQueryHookResult = ReturnType<typeof useGetMyProductsLazyQuery>;
export type GetMyProductsQueryResult = ApolloReactCommon.QueryResult<GetMyProductsQuery, GetMyProductsQueryVariables>;
export const GetOrganizationsBookingsDocument = gql`
  query GetOrganizationsBookings($startDate: String, $endDate: String) {
    bookings: getOrdersByOrganization(startDate: $startDate, endDate: $endDate) {
      ...OrderFragment
    }
  }
  ${OrderFragmentFragmentDoc}
`;

/**
 * __useGetOrganizationsBookingsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationsBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationsBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationsBookingsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetOrganizationsBookingsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetOrganizationsBookingsQuery, GetOrganizationsBookingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetOrganizationsBookingsQuery, GetOrganizationsBookingsQueryVariables>(
    GetOrganizationsBookingsDocument,
    options
  );
}
export function useGetOrganizationsBookingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetOrganizationsBookingsQuery,
    GetOrganizationsBookingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetOrganizationsBookingsQuery, GetOrganizationsBookingsQueryVariables>(
    GetOrganizationsBookingsDocument,
    options
  );
}
export type GetOrganizationsBookingsQueryHookResult = ReturnType<typeof useGetOrganizationsBookingsQuery>;
export type GetOrganizationsBookingsLazyQueryHookResult = ReturnType<typeof useGetOrganizationsBookingsLazyQuery>;
export type GetOrganizationsBookingsQueryResult = ApolloReactCommon.QueryResult<
  GetOrganizationsBookingsQuery,
  GetOrganizationsBookingsQueryVariables
>;
export const GetUserProfileDocument = gql`
  query GetUserProfile {
    profile: getUserProfile {
      settings {
        banner
        externalIdRequired
      }
      intl
      claims {
        roles
      }
    }
  }
`;

/**
 * __useGetUserProfileQuery__
 *
 * To run a query within a React component, call `useGetUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserProfileQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, options);
}
export function useGetUserProfileLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(
    GetUserProfileDocument,
    options
  );
}
export type GetUserProfileQueryHookResult = ReturnType<typeof useGetUserProfileQuery>;
export type GetUserProfileLazyQueryHookResult = ReturnType<typeof useGetUserProfileLazyQuery>;
export type GetUserProfileQueryResult = ApolloReactCommon.QueryResult<
  GetUserProfileQuery,
  GetUserProfileQueryVariables
>;
export const IsPrepaidDocument = gql`
  query isPrepaid {
    isPrepaid
  }
`;

/**
 * __useIsPrepaidQuery__
 *
 * To run a query within a React component, call `useIsPrepaidQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsPrepaidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsPrepaidQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsPrepaidQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<IsPrepaidQuery, IsPrepaidQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<IsPrepaidQuery, IsPrepaidQueryVariables>(IsPrepaidDocument, options);
}
export function useIsPrepaidLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsPrepaidQuery, IsPrepaidQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<IsPrepaidQuery, IsPrepaidQueryVariables>(IsPrepaidDocument, options);
}
export type IsPrepaidQueryHookResult = ReturnType<typeof useIsPrepaidQuery>;
export type IsPrepaidLazyQueryHookResult = ReturnType<typeof useIsPrepaidLazyQuery>;
export type IsPrepaidQueryResult = ApolloReactCommon.QueryResult<IsPrepaidQuery, IsPrepaidQueryVariables>;
export const PhotoShootRequestDocument = gql`
  mutation photoShootRequest($shootRequest: ShootRequestInput!) {
    photoShootRequest(shootRequest: $shootRequest) {
      status
    }
  }
`;
export type PhotoShootRequestMutationFn = ApolloReactCommon.MutationFunction<
  PhotoShootRequestMutation,
  PhotoShootRequestMutationVariables
>;

/**
 * __usePhotoShootRequestMutation__
 *
 * To run a mutation, you first call `usePhotoShootRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePhotoShootRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [photoShootRequestMutation, { data, loading, error }] = usePhotoShootRequestMutation({
 *   variables: {
 *      shootRequest: // value for 'shootRequest'
 *   },
 * });
 */
export function usePhotoShootRequestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<PhotoShootRequestMutation, PhotoShootRequestMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<PhotoShootRequestMutation, PhotoShootRequestMutationVariables>(
    PhotoShootRequestDocument,
    options
  );
}
export type PhotoShootRequestMutationHookResult = ReturnType<typeof usePhotoShootRequestMutation>;
export type PhotoShootRequestMutationResult = ApolloReactCommon.MutationResult<PhotoShootRequestMutation>;
export type PhotoShootRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<
  PhotoShootRequestMutation,
  PhotoShootRequestMutationVariables
>;
