import React from 'react';
// components
import Text from 'components/Text';
// material-ui
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = {
  main: {
    textAlign: 'center',
    marginTop: '20px'
  }
};

/**
 * Wrapper around Material UI `<Progress />` component
 * @see {@link https://material-ui.com/demos/progress/#progress}
 * @func Loading
 */
const Loading = props => {
  const { classes, children, ...otherProps } = props;

  return (
    <div className={classes.main}>
      <CircularProgress {...otherProps} />
      <Text>{children}</Text>
    </div>
  );
};

export default withStyles(styles)(Loading);
