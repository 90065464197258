import React from 'react';

// material-ui
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Row } from '@lemonone/components';

// components
import Text from 'components/Text';
import styles from './CheckoutFormActions.module.css';

import { BookButton, PayAndBookButton, TCLabel } from './i18n';

/**
 * Button with Term and Conditions checkbox
 *
 * @func CheckoutFormActions
 * @param {Object} props
 * @param {React.Element} props.children
 * @param {Function} props.onClick
 * @param {Object} props.user
 * @return {React.Element}
 */
export const CheckoutFormActions = ({ loading, prepaid, price }) => (
  <Row className={styles.ButtonBlock}>
    <Row>
      <Text gutterBottom className={styles.Tcd}>
        <TCLabel />
      </Text>
    </Row>
    <Row>
      <Button
        className={styles.ButtonStyle}
        color="primary"
        data-e2e="checkoutFormActionsBtnBook"
        disabled={loading}
        type="submit"
        variant="contained"
        disableElevation
      >
        {loading && <CircularProgress size={24} />}
        {prepaid ? (
          <BookButton disableElevation className={styles.ButtonStyle} />
        ) : (
          <PayAndBookButton price={price} disableElevation className={styles.ButtonStyle} />
        )}
      </Button>
    </Row>
  </Row>
);

export default CheckoutFormActions;
