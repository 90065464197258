/* eslint-disable quotes, prefer-object-spread */

const intl = require('@lemonone/intl/lib/es');
const components = require('@lemonone/components/lib/i18n/es');

const bookingui = require('./en');

module.exports = Object.assign({}, bookingui, intl, components, {
  'components.MyBookings.Header.title': 'Sessões de fotos',
  'components.CreateBookingButton.tooltip': 'Reserve agora!',
  'components.NoBookings.EmptyButton': 'Reserve agora',
  'components.NoBookings.EmptyText': 'Ainda não reservou nenhuma sessão fotográfica.',

  'components.CheckoutFormHost.Labels.nameExsrror': 'Por favor preencha com o nome da pessoa de contato',
  'components.CheckoutFormHost.Labels.emailError': 'Por favor preencha com um endereço de e-mail válido',
  'components.CheckoutFormHost.Labels.phoneError': 'Por favor preencha com um número de telefone válido',
  'components.CheckoutFormHost.Labels.externalIdError': 'Por favor preencha com um ID de referência',
  'components.CheckoutFormHost.Labels.externalIdTooLongError': 'O ID de referência não pode ter mais de 72 caracteres',

  'components.Confirmation.buttonBook': 'Continue a reservar',
  'components.PhotoShootSpecDetails.duration': 'Minuten max.',

  'components.NavSteps.product': 'Produto',
  'components.NavSteps.booking': 'Data e Horário',
  'components.NavSteps.confirmation': 'Confirmação',
  'components.NavSteps.checkout': 'Agendamento',
  'Recommended time': 'Horário recomendado',

  'components.PhotoShootSpecDetails.nameLabel': 'Produto',
  'components.PhotoShootEventDetails.dateLabel': 'Data da sessão',
  'components.PhotoShootEventDetails.addressLabel': 'Morada',
  'components.OrderDetails.productPrice': 'Preço',
  'components.OrderDetails.title': 'Detalhes da sessão',
  'components.CheckoutFormNotes.title': 'Informações adicionais que deveríamos saber (acesso ao prédio, etc.)',
  'components.CheckoutFormBillingAddress.title': 'Detalhes de fatura',
  'components.CheckoutFormBillingAddress.billingAddress': '{name}, {street}, {postalCode} {city}, {countryCode}',
  'components.CheckoutFormBillingAddress.notFound':
    'Não vê seu endereço de fatura aqui? Fale com seu gerente de conta.',

  'containers.Confirmation.confirmed': 'A sua reserva foi confirmada',

  'errors.Invalid authentication nonce.': 'Autentificação falhou. Por favor contacte-nos se o erro precistir.',
  'errors.Network Error': 'Ocorreu um erro de sistema. Por favor contacte-nos para que o possamos resolver.',
  'errors.Unexpected Error': 'Houve um problema com o seu pedido. Por favor contacte-nos para que o possamos resolver.',
  'errors.Address Error: invalid': 'Por favor coloque a sua morada completa, incluindo o numero da porta',
  'errors.User did not authorize the request': 'Por favor autorize o pedido para compeletar o registo',
  'errors.Missing required fields': 'Por favor peencha os campos obrigatórios',
  'errors.GraphQL error: Selected date and time not available.':
    'A data selecionada já não se encontra disponível. Por favor seleciona nova data.',
  'errors.Error: Order not found': 'A sua reserva não foi encontrada',
  'errors.GraphQL error: Order not found': 'A sua reserva não foi encontrada',
  'errors.: Order already cancelled': 'A sua reserva já se encontra cancelada',
  'errors.: Shoot has already been cancelled': 'A sua reserva já se encontra cancelada',
  'errors.Error: Late cancellation attempt': 'O limite de tempo de cancelamento foi exedido',
  'errors.Error: ': 'Informações de sessão em falta',
  'errors.Error: User email not verified.': 'Por favor, verifique o seu endereço de e-mail.',
  'errors.GraphQL error: Host email is invalid: "value" must be a valid email':
    'Por favor preencha com um endereço de e-mail válido',
  'errors.GraphQL error: Expected prices to be set for products of non-prepaid organization':
    'Os preços dos produtos ainda não foram definidos. Por favor nos contate para prosseguir.',
  'errors.GraphQL error: Cancellation for this shoot is not possible anymore':
    'Infelizmente não é mais possível reagendar ou cancelar essa sessão de fotos. Entre em contato com o gerente da sua conta para mais detalhes.',

  'Company Name': 'Nome de Empresa',
  Name: 'Nome',
  'Contact us': 'Contacte-nos',
  Email: 'Email',
  'Billing Address': 'Endereço de fatura',
  City: 'Cidade',
  Zipcode: 'Codigo Postal',
  Phone: 'Telefone',
  'This field is required.': 'Campo obrigatório.',
  'You must accept the terms and conditions.': 'Obrigatório aceitar os termos e condições',
  'Your Booking': 'A sua reserva',
  'Book More': 'Continue a reservar',
  'Sorry, there are no appointments for the selected date.':
    'Pedeos desculpa mas não temos marcações para a data selecionada',
  'We are processing your order...': 'Estamos a processar o seu pedido...',
  Select: 'Selecione',
  'Tell us where you need the photo shoot': 'Diganos onde necessita ter um sessão de photos:',
  'Pick a date that works for you': 'Escolha uma data apropriada:',
  'Unfortunately, we do not have any available photographers in your area at the moment.':
    'Infelizmente não temos nenhum fotografo diponível na sua zona de momento',
  'Please fill in your contact details below, and we will inform you as soon as one of our photographers is available.':
    'Por favor, preencha seus dados de contato e proponha três datas e horários que funcionem para você, e nós te informaremos assim que encontrarmos um fotógrafo',
  'Thanks for your request!': 'Obrigdo pelo seu pedido',
  'Request photo shoot': 'Reserve um sessão fotográfica',
  'We have received your details and will be in touch as soon as we have a photographer for your photoshoot.':
    'Recebemos os seu dados e iremos entrar em contacto assim que  tenhamos um fotografo disponível',
  'Team LemonOne': 'Equipa LemonOne',
  'Enter your contact data so we can get in touch with you.':
    'Insira os seus daods de contacto pata que o possamos contactar.',
  'All fields with * are mandatory.': 'Todos os campos assinalados com * são mandatórios',
  'Please use your company email.': 'Por favor confirme o email da sua empresa',
  'We are very sorry that you have decided to cancel your booking. Please be aware that it is only possible to cancel bookings without charge up to 48 hours before the photo shoot. Also note that some time slots may not be available for re-booking any more':
    'Lamentamos que tenha decidido cancelar a sua reserva. Tenha em atenção que só é possível cancelar reservas sem cobrança até 48 horas antes da sessão fotográfica. Também pode ter em atenção que algums horários podem não estar disponíveis para nova reserva.',
  'Cancel Photo Shoot': 'Cancelar sessão',
  'Reasons for Cancellation': 'Razão do cancelamento',
  'Your photo shoot appointment has been cancelled': 'A sua sessão fotográfica foi cancelada',
  'We can find a solution for you!': 'Nós podemos arranjar-lhe uma solução!',
  'Pay & Book': 'Pagar e Reservar',
  'We can not complete your request. Please contact customer support':
    'Não conseguimos concluir o seu pedido. Por favor contacte-nos para que o possamos resolver.',
  'plus VAT': 'mais IVA',
  'components.SelectDayTime.start': 'Hora de início',

  'Your contact details': 'Detalhes de contacto',
  Continue: 'Continuar',
  'Billing details': 'Detalhes de facturação',
  'Look for available time slots in my location': 'Procurar por slots de tempo disponíveis na minha localização',
  'Clear the Searchfield': 'Limpe o campo de pesquisa',
  '@lemonone/bookingui/users': 'Comercial'
});
