import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { loadScript } from 'utils';
import logger from 'utils/logger';
import track from 'utils/analytics';
import PlacesAutocomplete from 'react-places-autocomplete';
// components
import Loading from 'components/Loading';
import SearchBar from 'components/SearchBar';
// material-ui
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { validateAddress } from './validate';

import styles from './InputGeoSearch.module.css';

const { REACT_APP_BOOKINGUI_GOOGLE_PLACES_API_KEY } = process.env;
const googleapisUri = `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_BOOKINGUI_GOOGLE_PLACES_API_KEY}&libraries=places`;

// TODO extract components:
//      - <InputAutocomplete />
//      - <InputSearch />
//      - ...

/**
 * React Component that implements a geolocation autocomplete field using Google
 * Maps APIs
 *
 * @class InputGeoSearch
 */
class InputGeoSearch extends Component {
  // Component state
  state = {
    loading: true,
    submitted: false,
    value: this.props.defaultValue || '',
    shouldShowHint: false
  };

  // Lifecycle methods

  async componentDidMount() {
    const { google } = window;
    if (google && google.maps) {
      // Google Maps JavaScript API already loaded
      this.setState({ loading: false });
      return;
    }
    try {
      await loadScript(googleapisUri);
    } catch (e) {
      logger('error', e);
    }
    this.setState({ loading: false });
  }

  componentWillReceiveProps({ value }) {
    if (this.props.value !== value) this.setState({ value });
  }

  // handlers

  /**
   * handle onChange event from the autocomplete input field
   * @method onChange
   * @param {String} value
   */
  onChange = value => {
    if (this.state.submitted) {
      if (this.props.onSubmit) this.props.onSubmit(null);
    }
    this.setState({ submitted: false, value });
  };

  /**
   * handle user click on one of the suggestions
   * from the autocomplete dropdown
   * @method onSelect
   * @param {String} value the autocomplete item value
   */
  onSelect = async value => {
    this.setState({ value });
    await this.handleSubmit(value);
  };

  /**
   * handle user click on the loupe search icon
   * @method onClick
   */
  onClick = async () => {
    await this.handleSubmit(this.state.value);
  };

  /**
   * validate address submited by user and call the
   * `onSubmit` prop for a valid address
   * or
   * `onError` prop for an invalid one
   * @method handleSubmit
   * @param {String} value
   */
  handleSubmit = async value => {
    const { intl } = this.props;
    const { valid, error } = await validateAddress(value);

    this.setState({ submitted: true });

    if (valid) {
      if (this.props.onSubmit) this.props.onSubmit(value);
    } else {
      if (this.props.onSubmit) this.props.onSubmit(null);
      track('invalidAddress', { label: value });
      if (this.props.onError) {
        this.props.onError(
          intl.formatMessage({
            id: `errors.Address Error: ${error}`,
            defaultMessage:
              'Oops! something unexpected happened. Please try again and contact us if the issue persists.'
          })
        );
      }
    }
  };

  handleReset = () => {
    this.setState({ submitted: true, value: '' });
    if (this.props.onSubmit) this.props.onSubmit(null);
  };

  // render

  render() {
    const { intl } = this.props;
    if (this.state.loading) return <Loading />;
    return (
      <PlacesAutocomplete onChange={this.onChange} onSelect={this.onSelect} value={this.state.value}>
        {({ getInputProps, suggestions, getSuggestionItemProps }) => {
          return (
            <>
              <div className={styles.SearchBar}>
                {this.state.shouldShowHint && (
                  <div className={styles.hint}>
                    <span className={styles.hintTitle}>
                      {intl.formatMessage({ id: '@lemonone/intl/text:addressSyntax' })}:
                    </span>
                    <span className={styles.hintGroup}>
                      {intl.formatMessage({ id: '@lemonone/intl/text:streetName' })}
                    </span>
                    <span className={styles.hintGroup}>
                      {intl.formatMessage({ id: '@lemonone/intl/text:houseNumber' })}
                    </span>
                    ,
                    <span className={styles.hintGroup}>
                      {intl.formatMessage({ id: '@lemonone/intl/text:zipCode' })}
                    </span>
                    <span className={styles.hintGroup}>{intl.formatMessage({ id: '@lemonone/intl/text:city' })}</span>,{' '}
                    <span className={styles.hintGroup}>
                      {intl.formatMessage({ id: '@lemonone/intl/text:country' })}
                    </span>
                  </div>
                )}
                <SearchBar
                  autoFocus
                  {...getInputProps()}
                  onReset={this.handleReset}
                  onClick={this.onClick}
                  onFocus={() => this.setState({ shouldShowHint: true })}
                  onBlur={() => this.setState({ shouldShowHint: false })}
                  placeholder={intl.formatMessage({ id: 'Tell us where you need the photo shoot' })}
                  arialabelsearch={intl.formatMessage({
                    id: 'Look for available time slots in my location'
                  })}
                  airalabelclose={intl.formatMessage({
                    id: 'Clear the Searchfield'
                  })}
                  data-e2e="photoShootAddressInput"
                />
              </div>
              {suggestions.length > 0 && (
                <Paper square className={styles.Suggestions}>
                  {suggestions.map(suggestion => (
                    <MenuItem
                      key={suggestion.index}
                      selected={suggestion.active}
                      {...getSuggestionItemProps(suggestion)}
                    >
                      {suggestion.description}
                    </MenuItem>
                  ))}
                </Paper>
              )}
            </>
          );
        }}
      </PlacesAutocomplete>
    );
  }
}

export default injectIntl(InputGeoSearch);
