import React from 'react';
// components
import Box from 'components/Box';
// material-ui
import { ArrowBack, ArrowForward, Keyboard } from '@material-ui/icons';
// 3rd party UI component
import { usePickerState, Calendar as MuiCalendar } from '@material-ui/pickers';
import { renderDay, toDateString } from './helpers';

const Calendar = ({ days = {}, onChange, value }) => {
  const { pickerProps } = usePickerState(
    {
      // flag `autoOk:true` solve issue: cannot change date on static Calendar component
      // @see https://github.com/mui-org/material-ui-pickers/issues/1172
      autoOk: true,
      value,
      onChange
    },
    {
      getDefaultFormat: () => 'MM/dd/yyyy',
      getValidationError: () => null
    }
  );

  const dates = new Set(Object.keys(days));

  return (
    <Box data-e2e="dateSelectCalendar" margin="0 auto" style={{ maxWidth: '280px', overflow: 'hidden' }}>
      <MuiCalendar
        {...pickerProps}
        disablePast
        shouldDisableDate={date => !dates.has(toDateString(date))}
        renderDay={renderDay(dates)}
        keyboardIcon={<Keyboard />}
        leftArrowButtonProps={{ 'data-e2e': 'dateSelectCalendarArrowLeft' }}
        leftArrowIcon={<ArrowBack />}
        rightArrowButtonProps={{ 'data-e2e': 'dateSelectCalendarArrowRight' }}
        rightArrowIcon={<ArrowForward />}
      />
    </Box>
  );
};

const CalendarIntl = props => <Calendar {...props} />;

export default CalendarIntl;
