import React from 'react';
import Notification from './Notification';

const NotificationContext = React.createContext({
  onNotification: () => {}
});
/**
 * Component to provide needed data for notifications into nested components
 * without prop drilling
 *
 * @module ./NotificationProvider
 */
export class NotificationProvider extends React.Component {
  // initial state
  state = {
    message: '',
    open: false
  };

  // add handler

  onNotification = message => {
    this.setState({ message, open: true });
  };

  onClose = () => {
    this.setState({
      message: null,
      open: false
    });
  };

  // render

  render() {
    const { children, ...props } = this.props;
    const { message, open } = this.state;
    const context = {
      onNotification: this.onNotification
    };

    return (
      <NotificationContext.Provider value={context}>
        <Notification onClose={this.onClose} message={message} open={open} />
        {React.cloneElement(children, props)}
      </NotificationContext.Provider>
    );
  }
}

export const withNotification = Component => props => (
  <NotificationContext.Consumer>{context => <Component {...props} {...context} />}</NotificationContext.Consumer>
);
