import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';

export const LoginForm: React.FC<{}> = ({ children }) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({ appState: { returnTo: '/' } });
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (!isLoading && isAuthenticated) {
    return <>{children}</>;
  }

  return null;
};
