export const getAddressComponents = ({ address_components: components }) => {
  let categories = [
    { type: 'street_number', key: 'streetNumber' },
    { type: 'street_address', key: 'street' },
    { type: 'route', key: 'street' },
    { type: 'locality', key: 'city' },
    { type: 'sublocality', key: 'city' },
    { type: 'postal_town', key: 'city' },
    { type: 'sublocality_level_1', key: 'city' },
    { type: 'sublocality_level_2', key: 'city' },
    { type: 'sublocality_level_3', key: 'city' },
    { type: 'sublocality_level_4', key: 'city' },
    { type: 'neighborhood', key: 'city' },
    { type: 'country', key: 'country' },
    { type: 'postal_code', key: 'postalCode' }
  ];

  const res = {};

  for (const category of categories) {
    for (const component of components) {
      if (component.types.includes(category.type)) {
        if (!res[category.key]) res[category.key] = component.long_name;
        if (category.key === 'country') res.countryCode = component.short_name;
        categories = categories.filter(item => item.key !== category.key);
      }
    }
  }

  return res;
};
