import ErrorMessage from 'components/Error';
import MyBookings from 'components/MyBookings';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSettings } from '@lemonone/components';
import { useGetOrganizationsBookingsLazyQuery } from '../__generated__';

import { ACCESS_RESTRICTIONS } from '../constants';

const OrganizationBookingsContainer = () => {
  const intl = useIntl();
  const settings = useSettings();
  const [load, { loading, error, data }] = useGetOrganizationsBookingsLazyQuery({ fetchPolicy: 'network-only' });

  if (!settings.claims?.roles?.includes(ACCESS_RESTRICTIONS.ROLES.CLIENT_ADMIN)) {
    return <ErrorMessage message="Not Authorized" />;
  }

  return (
    <MyBookings
      title={intl.formatMessage({ id: 'page.organization.bookings.title' })}
      load={({ startDate, endDate }) =>
        load({
          variables: {
            startDate,
            endDate
          }
        })
      }
      loading={loading}
      error={error}
      data={data?.bookings ?? []}
    />
  );
};

export default OrganizationBookingsContainer;
