/* eslint-disable quotes, prefer-object-spread */

const intl = require('@lemonone/intl/lib/es');
const components = require('@lemonone/components/lib/i18n/es');

const bookingui = require('./en');

module.exports = Object.assign({}, bookingui, intl, components, {
  'components.CheckoutFormActions.buttonBook': 'Reservar',
  'components.CheckoutFormActions.buttonPayBook': 'Pagar {price} y Reservar',
  'components.CheckoutFormActions.termsLink': 'https://www.lemonone.com/en/terms-conditions',
  'components.CheckoutFormActions.privacyLink': 'https://www.lemonone.com/en/privacy-policy',
  'components.CheckoutFormActions.labelTC':
    'Al completar tu pedido aceptas nuestras <terms-link>Condiciones</terms-link> de uso.\nConsulta nuestro Aviso de <privacy-link>privacidad</privacy-link>.',

  'components.CheckoutFormConsent.label':
    'Estoy autorizado a compartir los datos de contacto con LemonOne GmbH para satisfacer la contratación de servicios fotográficos.',

  'components.CheckoutFormHost.title': 'Detalles del anfitrion',
  'components.CheckoutFormHost.description':
    'Si otra persona va a estar en el lugar de la sesión de fotos, por favor indícanos sus datos para poder contactar con ella.',
  'components.CheckoutFormHost.Labels.name': 'Nombre',
  'components.CheckoutFormHost.Labels.nameError': 'Por favor ingrese el nombre de la persona de contacto',
  'components.CheckoutFormHost.Labels.email': 'Email',
  'components.CheckoutFormHost.Labels.emailError': 'Por favor ingrese una dirección de correo electrónico válida',
  'components.CheckoutFormHost.Labels.phone': 'Teléfono',
  'components.CheckoutFormHost.Labels.phoneError': 'Por favor ingrese un número de teléfono válido',
  'components.CheckoutFormHost.Labels.externalIdError': 'Por favor ingrese un ID de referencia',
  'components.CheckoutFormHost.Labels.externalIdTooLongError': 'El ID de referencia no puede superar los 72 caracteres',

  'components.CheckoutFormLocation.title': 'Más detalles sobre la localizción',
  'components.CheckoutFormLocation.Labels.locationName': 'Nombre del negocio',
  'components.CheckoutFormLocation.Labels.externalId': 'Tu identificator',

  'components.CheckoutFormNotes.attachmentButton': 'Haz click aquí para adjuntar un archivo',
  'components.CheckoutFormNotes.attachmentDescription':
    'También puedes enviarnos un archivo adjunto con la información necesaria.',
  'components.CheckoutFormNotes.title': 'Información adicional que necesitamos saber (acceso al edificio, etc.)',
  'components.CheckoutFormNotes.label': 'Mensaje',
  'components.CheckoutFormNotes.description':
    'Escribenos aquí si tienes alguna información relevante que no te hemos preguntado.',

  'components.Confirmation.buttonBook': 'Reservar otra sesión de fotos',

  'components.MyBookings.Header.title': 'Sesiones de fotos',
  'components.CreateBookingButton.tooltip': '¡Reserva una sesión de fotos!',
  'components.NoBookings.EmptyButton': 'Reservar ahora',
  'components.NoBookings.EmptyText': 'Aún no has reservado ninguna sesión de fotos.',

  'components.NavSteps.product': 'Producto',
  'components.NavSteps.booking': 'Fecha y franja horaria',
  'components.NavSteps.confirmation': 'Confirmación',
  'components.NavSteps.checkout': 'Detalles',

  'components.OrderDetails.title': 'Su sesión de fotos',

  'components.OrderTable.id': 'ID',
  'components.OrderTable.referenceId': 'Referencia',
  'components.OrderTable.creator': 'Programado por',
  'components.OrderTable.createdAt': 'Programado el',
  'components.OrderTable.date': 'Fecha',
  'components.OrderTable.address': 'Dirección',
  'components.OrderTable.status': 'Estatus',
  'components.OrderTable.product': 'Producto',
  'components.OrderTable.downloadUrl': 'Descargar fotos',
  'components.OrderTable.seeAllInfo': 'Ver toda la información',
  'components.OrderTable.rescheduleUrl': 'Reprogramar',
  'components.OrderTable.deliveredAt': 'Fecha y hora de entrega',
  'components.OrderTable.downloadCsv': 'Descarga CSV',

  'components.OrderTable.status:scheduled': 'programada',
  'components.OrderTable.status:cancelled': 'cancelada',
  'components.OrderTable.status:fulfilled': 'cumplida',
  'components.OrderTable.status:rescheduling': 'reprogramar',
  'components.OrderTable.status:processing': 'procesando',

  'components.PhotoShootEventDetails.addressLabel': 'Dirección',
  'components.PhotoShootEventDetails.dateLabel': 'Fecha de la sesión de fotos',

  'components.PhotoShootSpecCard.totalDuration': '{duration} minutos max.',
  'components.ShootDuration.totalDuration': '{duration} minutos max.',

  'components.PhotoShootSpecDetails.duration': 'minutos max.',
  'components.PhotoShootSpecDetails.durationLabel': 'Duración máx.',
  'components.PhotoShootSpecDetails.nameLabel': 'Producto',

  'components.ProductSelection.buttonSelect': 'Continuar',
  'components.ProductSelection.editingService': 'Solicitar edición',

  'components.ShootRequest.confirmation':
    'Hemos recibido sus datos y nos pondremos en contacto tan pronto como tengamos un fotógrafo para su sesión de fotos.',
  'components.ShootRequest.details':
    'Por favor, rellene sus datos de contacto a continuación, y le informaremos tan pronto como uno de nuestros fotógrafos esté disponible.',
  'components.ShootRequest.team': 'Equipo LemonOne',
  'components.ShootRequest.titleAfter': 'Gracias por su solicitud!',
  'components.ShootRequest.titleBefore':
    'Desafortunadamente, no tenemos ningún fotógrafo disponible en su área en este momento.',

  'components.ShootRequestForm.action': 'Solicitar sesión de fotos',
  'components.ShootRequestForm.message': 'Díganos lo que quiere, por favor!',

  'components.UserMenu.MenuItem.orders': 'Mis sesiones reservadas',
  'components.UserMenu.MenuItem.organizationBookings': 'Todas las sesiones de fotos',

  'containers.Confirmation.confirmed': 'Su reserva ha sido confirmada.',
  'containers.Confirmation.processing': 'Estamos procesando su pedido...',

  'components.CheckoutFormBillingAddress.title': 'Detalles de facturación',
  'components.CheckoutFormBillingAddress.billingAddress': '{name}, {street}, {postalCode} {city}, {countryCode}',
  'components.CheckoutFormBillingAddress.notFound':
    '¿No ves tu dirección de facturación aquí? Por favor, comunícate con tu gerente de cuenta.',
  'components.SelectDayTime.start': 'Hora de comienzo',

  'errors.Address Error: invalid': 'Por favor, introduzca la dirección completa, incluido el número de la casa.',
  'errors.Address Error: Not Found': 'La dirección indicada no existe.',
  'errors.Address Error: COVID-19 Quarantine':
    'Para proteger a todos los colaboradores frente a COVID-19, no es posible reservar sesiones de foto en la zona indicada. Si necesita más información contactenos: hello@lemonone.com / +4930120872870',
  'errors.Error: Order not found': 'No se pudo encontrar la orden.',
  'errors.GraphQL error: Order already cancelled': 'La sesión de fotos ya ha sido cancelada.',
  'errors.GraphQL error: Shoot has already been cancelled': 'La sesión de fotos ya ha sido cancelada.',
  'errors.GraphQL error: Order cancellation event missing': 'La sesión de fotos ya ha sido cancelada.',
  'errors.Error: Late cancellation attempt': 'Se ha sobrepasado el plazo de anulación.',
  'errors.Error: ': 'Información de tomas perdidas',
  'errors.Error: User email not verified.': 'Por favor, verifique su correo electrónico.',
  'errors.GraphQL error: Host email is invalid: "value" must be a valid email':
    'Por favor ingrese una dirección de correo electrónico válida',
  'errors.GraphQL error: Shoot lies in the past': 'No puedes cancelar una sesión de fotos que esta en el pasado.',
  'errors.GraphQL error: Order not found': 'No tenemos constancia de la session de fotos que esta intentando cancelar.',
  "errors.GraphQL error: Product doesn't exist!":
    'Necesitamos más información para poder procesar su solicitud. Póngase en contacto con nosotros para que podamos crear su producto individual.',
  "errors.GraphQL error: Variable 'orderId' has coerced Null value for NonNull type 'String!'":
    'Necesitamos el identificador de su pedido para poder cancelarlo.',
  'errors.Failed to authenticate':
    'No hemos podido procesar su registro correctamente. Por favor, inténtalo de nuevo o ponte en contacto con nosotros si el problema persiste.',
  'errors.GraphQL error: Selected date and time not available.':
    'Su cita elegida ya no está disponible. Por favor, elija otra fecha.',
  'errors.GraphQL error: User email not verified': 'Por favor verifica tu email para poder finalizar tu pedido.',
  'errors.Invalid authentication nonce.':
    'No hemos podido procesar su registro correctamente. Por favor, inténtalo de nuevo o ponte en contacto con nosotros si el problema persiste.',
  'errors.Missing required fields': 'Por favor, rellene todos los campos obligatorios.',
  'errors.Network Error':
    'Hubo un problema con la red. Por favor, inténtelo de nuevo o póngase en contacto con nosotros si el problema persiste.',
  'errors.OrderDetailsFacade.missing_required_props':
    'Nos faltan algunos datos para poder mostrarle una información precisa sobre su sesión de fotos. Inténtelo de nuevo más tarde y contáctenos si la incidencia persiste.',
  'errors.OrderDetailsFacade.not_found': 'No se pudo encontrar la orden.',
  'errors.PhotoShootEventDetails.missing_data':
    'Nos faltan algunos datos para poder mostrarle una información precisa sobre su sesión de fotos. Inténtelo de nuevo más tarde y contáctenos si la incidencia persiste.',
  'errors.PhotoShootSpecDetails.missing_data':
    'Nos faltan algunos datos para poder mostrarle una información precisa sobre su sesión de fotos. Inténtelo de nuevo más tarde y contáctenos si la incidencia persiste.',
  'errors.Unexpected Error':
    'Hubo un problema con su petición. Por favor, háganoslo saber para que podamos resolverlo.',
  'errors.User did not authorize the request': 'Por favor, autorice la solicitud para completar el inicio de sesión.',
  'errors.GraphQL error: Expected prices to be set for products of non-prepaid organization':
    'Los precios de los productos todavía no han sido asignados. Por favor contáctenos para proseguir.',
  'errors.GraphQL error: Cancellation for this shoot is not possible anymore':
    'Desafortunadamente, la cancelación o reprogramación de esta sesión de fotos ya no es posible. Comuníquese con su gerente de cuenta para obtener más detalles.',

  'errors.GraphQL error: TimeSlotExpired: booking is not possible on the selected date & time':
    'La franja horaria seleccionada ya no está disponible. Vuelve a seleccionarla',
  'errors.GraphQL error: TimeSlotExpired: booking date must be in the future':
    'La franja horaria seleccionada ya no está disponible. Vuelve a seleccionarla',
  'errors.GraphQL error: TimeSlotExpired: booking is not possible less than 36 hours in advance':
    'La franja horaria seleccionada ya no está disponible. Vuelve a seleccionarla',
  'errors.GraphQL error: TimeSlotExpired: booking is not possible less than 72 hours in advance':
    'La franja horaria seleccionada ya no está disponible. Vuelve a seleccionarla',
  'errors.GraphQL error: TimeSlotExpired: booking is only possible between 8-20 hours':
    'La franja horaria seleccionada ya no está disponible. Vuelve a seleccionarla',
  'errors.GraphQL error: TimeSlotExpired: booking is not possible on Sunday':
    'La franja horaria seleccionada ya no está disponible. Vuelve a seleccionarla',
  'errors.GraphQL error: TimeSlotExpired: booking is only possible at HH:00, HH:30':
    'La franja horaria seleccionada ya no está disponible. Vuelve a seleccionarla',

  'module.authentication.copyright': '© {year} LemonOne GmbH',
  'module.authentication.cta': 'Entrar',
  'module.authentication.imprint': 'Aviso Legal',
  'module.authentication.headline': 'Espectaculares fotos que impresionaran a tus clientes',
  'module.authentication.navLoginButton': 'Entrar',
  'module.authentication.privacy': 'Política de privacidad',
  'module.authentication.DialogAlert.title': 'Necesita verificar su correo electrónico',
  'module.authentication.DialogAlert.description':
    'Por favor, verifique su dirección de correo electrónico siguiendo el enlace que le hemos enviado al email que nos ha indicado en el proceso de registro.',
  'module.authentication.DialogAlert.buttonAccept': 'Ya he verificado mi correo electrónico',
  'module.authentication.DialogAlert.buttonCancel': 'Salir',

  'Recommended time': 'Hora recomendada',

  'Company Name': 'Nombre de la empresa',
  Name: 'Nombre',
  'Contact us': 'Contáctenos',
  Email: 'Correo electrónico',
  'Billing Address': 'Dirección de facturación',
  City: 'Ciudad',
  Zipcode: 'Código postal',
  Phone: 'Teléfono',
  'This field is required.': 'Este campo es obligatorio.',
  'You must accept the terms and conditions.': 'Debe aceptar los términos y condiciones.',
  'Your Booking': 'Su reserva',
  'Book More': 'Reservar más',
  'Sorry, there are no appointments for the selected date.': 'Lo sentimos, no hay citas para la fecha seleccionada.',
  'We are processing your order...': 'Estamos procesando su pedido...',
  Select: 'Seleccione',
  'Tell us where you need the photo shoot': 'Díganos donde necesita la sesión de fotos:',
  'Pick a date that works for you': 'Escoja la fecha idónea:',
  'We have received your details and will be in touch as soon as we have a photographer for your photoshoot.':
    'Hemos recibido sus datos y nos pondremos en contacto tan pronto como tengamos un fotógrafo para su sesión de fotos.',
  'Enter your contact data so we can get in touch with you.':
    'Introduzca su información personal para que podamos estar en contacto con usted',
  'All fields with * are mandatory.': 'Todos los campos marcados con * son obligatorios.',
  'Please use your company email.': 'Por favor, utilice el correo electrónico de su empresa.',
  'We are very sorry that you have decided to cancel your booking. Please be aware that it is only possible to cancel bookings without charge up to 48 hours before the photo shoot. Also note that some time slots may not be available for re-booking any more.':
    'Lamentamos mucho que haya decidido cancelar su reserva. Tenga en cuenta que sólo es posible cancelar reservas sin cargo hasta 48 horas antes de la sesión de fotos. Tenga en cuenta también que algunas franjas horarias pueden no estar disponibles para volver a reservar.',
  'Cancel Photo Shoot': 'Cancelar la sesión de fotos',
  'Please fill in your contact details and propose three dates and times that work for you, and we will inform you as soon as we found a photographer.':
    'Por favor, rellene sus datos de contacto y proponga tres fechas y horas que le sirvan, y le informaremos tan pronto como encontremos un fotógrafo',
  'Reasons for Cancellation': 'Motivos de la cancelación',
  'Your photo shoot appointment has been cancelled': 'Su cita para la sesión de fotos ha sido cancelada',
  'We can find a solution for you!': 'Nosotros podemos encontrar una solución para ti!',
  'Pay & Book': 'Pagar y Reservar',
  'We can not complete your request. Please contact customer support':
    'No podemos completar su solicitud. Póngase en contacto con el servicio de atención al cliente',
  'plus VAT': 'más IVA',
  'Your contact details': 'Sus datos de contacto',
  Continue: 'Programar',
  'Billing details': 'Detalles de facturación',
  'Look for available time slots in my location': 'Buscar las franjas horarias disponibles en mi ubicación',
  'Clear the Searchfield': 'Borrar el campo de búsqueda',

  'page.organization.bookings.apply': 'Aplicar',
  'page.organization.bookings.from': 'Sesiones de fotos desde',
  'page.organization.bookings.title': 'Todas las sesiones de fotos',
  'page.organization.bookings.until': 'Sesiones de fotos hasta',
  'page.organization.bookings.search': 'Buscar',
  '@lemonone/bookingui/noProducts':
    'No hay productos disponibles para reservar en este momento.\n\nComuníquese con su <bold>administrador</bold> de cuentas para obtener ayuda.',
  '@lemonone/bookingui/uploadAttachments': 'Agregar archivos adjuntos',
  '@lemonone/bookingui/users': 'Usuarios'
});
