import { useIntlScope } from '@lemonone/components';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import cn from 'classnames';
import React from 'react';

import { Order } from '../../__generated__';
import classes from './OrderDetailsRow.module.css';

export type OrderDetailsRowProps = {
  order: Order;
  striped: boolean;
};

export const OrderDetailsRow: React.FC<OrderDetailsRowProps> = ({ order, striped }) => {
  const common = useIntlScope('@lemonone/intl/text:');
  return (
    <TableRow className={cn(classes.wrapper, striped && classes.contained)}>
      <TableCell colSpan={12}>
        <table className={classes.table}>
          <tr className={classes.row}>
            <td className={classes.cell}>
              <strong>{common('notes')}:</strong>
            </td>
            <td className={classes.cell}>
              <p>{order.additionalData?.notes}</p>
            </td>
          </tr>
        </table>
      </TableCell>
    </TableRow>
  );
};
