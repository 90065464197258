import React from 'react';
import { connect } from 'unistore/react';
import { chooseProduct } from 'actions';
import Box from 'components/Box';
import ProductSelection from 'components/ProductSelection';
import { useHistory, useLocation } from 'react-router-dom';

// FIXME codestyle
// eslint-disable-next-line @typescript-eslint/no-shadow
export const ChooseProduct = ({ defaultShootSpec, chooseProduct }) => {
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const shootSpec = params.get('shootSpec') ?? defaultShootSpec;
  const onPhotoshootService = product => {
    chooseProduct(product);
    history.push('/choose-appointment');
  };
  const onEditingService = product => {
    history.push(`/request-editing/${product.uuid}`);
  };
  return (
    <Box margin="2 0 0">
      <ProductSelection
        default={shootSpec}
        onPhotoshootService={onPhotoshootService}
        onEditingService={onEditingService}
      />
    </Box>
  );
};

export default connect(undefined, { chooseProduct })(ChooseProduct);
