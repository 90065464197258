import { TextArea, Row, useIntlScope } from '@lemonone/components';
import React from 'react';
import { useController } from 'react-hook-form';
import { useIntl } from 'react-intl';

const CheckoutFormNotes = ({ control }) => {
  const intl = useIntl();
  const tpl = useIntlScope('@lemonone/bookingui/components/RescheduleShoot:');
  const { field: textarea, fieldState: field } = useController({
    control,
    name: 'cancellationNotes',
    rules: { required: true }
  });
  return (
    <Row>
      {tpl('reason')}
      <TextArea
        {...textarea}
        name="cancellationNotes"
        required
        data-e2e="cancellationNotesTextArea"
        error={field.error}
        hint={
          field.error
            ? intl.formatMessage({
                id: `@lemonone/intl/text:${field.error.type}`,
                defaultMessage: field.error.message ?? field.error.type
              })
            : ''
        }
      />
    </Row>
  );
};

export default CheckoutFormNotes;
