export const ACCESS_RESTRICTIONS = {
  // @NOTE These need to be defined on the Auth0 Authorization Extension, not in the Auth0 Dashboard
  ROLES: {
    CLIENT_ADMIN: 'client:admin'
  }
};
export const ORDER_STATUS = {
  CANCELLED: 'cancelled',
  CONFIRMED: 'confirmed'
};
export const ROUTES = {
  // Booking Flow
  BOOKING_PRODUCT_SELECTION: '/',
  BOOKING_APPOINTMENT_SELECTION: '/choose-appointment',
  BOOKING_CHECKOUT: '/checkout',
  BOOKING_CONFIRMATION: '/confirmation',

  ORDERS_CANCEL: '/cancel-order',
  BOOKINGS: '/bookings',
  BOOKINGS_ALL: '/bookings/all',
  RESCHEDULE: '/reschedule',
  USERS: '/users',
  REQUEST_EDITING: '/request-editing/:uuid'
};
