import DateFnsUtils from '@date-io/date-fns';
import { Row, Typography, useIntlScope } from '@lemonone/components';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import { useController } from 'react-hook-form';
import { getDateFnsLocale } from 'utils/i18n';

import SelectDateTime from '../SelectDateTime';

const RescheduleShootForm = ({ control, orderInfo }) => {
  const intl = useIntlScope('@lemonone/intl/text:');
  const tpl = useIntlScope('@lemonone/bookingui/components/RescheduleShoot:');
  const { field: timeslot, fieldState: field } = useController({ name: 'start', control });

  return (
    <>
      <Row>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getDateFnsLocale()}>
          <SelectDateTime
            address={orderInfo.shootAddress}
            productUuid={orderInfo.productUuid}
            duration={orderInfo.shootDurationMins}
            onAppointmentSelect={appointment => {
              timeslot.onChange(appointment.start);
            }}
            title={tpl('calendar')}
          />
        </MuiPickersUtilsProvider>
      </Row>
      {field.error && (
        <Row>
          <Typography color="error">{intl('required_datetime')}</Typography>
        </Row>
      )}
    </>
  );
};

export default RescheduleShootForm;
