import { useIntlScope } from '@lemonone/components';
import { makeStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { useIntl } from 'react-intl';

import { Contact } from '../../__generated__';

export type ContactsTableProps = {
  contacts: Contact[];
};

const useStyles = makeStyles(theme => ({
  id: {
    width: 30
  },
  table: {
    '& .MuiTableCell-head': {
      padding: 6,
      fontWeight: 'bold',
      whiteSpace: 'nowrap'
    },
    '& .MuiTableCell-body': {
      padding: 6,
      borderBottom: 'none'
    },
    '& .MuiTableBody-root > .MuiTableRow-root:nth-child(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}));

export const ContactsTable: React.FC<ContactsTableProps> = ({ contacts }) => {
  const classes = useStyles();
  const intl = useIntl();
  const common = useIntlScope('@lemonone/intl/text:');
  return (
    <Table className={classes.table} size="small" padding="none">
      <TableHead>
        <TableRow>
          <TableCell className={classes.id}>#</TableCell>
          <TableCell>{common('email')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {orderBy(contacts, 'email', 'asc').map((contact, index) => {
          return (
            <TableRow key={index}>
              <TableCell>{intl.formatNumber(index + 1)}.</TableCell>
              <TableCell>{contact.email.toLowerCase()}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
