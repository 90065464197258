import track from 'utils/analytics';

export const chooseAppointment = (state, appointment) => {
  // side-effect analytics
  track('chooseAppointment');
  // mutate state
  return { ...state, appointment };
};

export const chooseProduct = (state, product) => {
  // side-effect analytics
  track('chooseProduct', { label: product.name });
  // mutate state
  return { ...state, product };
};

export const shootRequestReceived = state => {
  // side-effect analytics
  track('shootRequestReceived');
  // mutate state
  return { ...state, shootRequest: true };
};

export const submitAddress = (state, address) => {
  // side-effect analytics
  track('submitAddress', { label: address });
  // mutate state
  return { ...state, address };
};

export const createBooking = () => {
  // side-effect: analytics
  track('onClickNewBooking');
};
