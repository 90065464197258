import { Footer } from '@lemonone/components';
import { Grid, makeStyles } from '@material-ui/core';
import NavBar from 'components/NavBar';
import React from 'react';

const useStyles = makeStyles(theme => ({
  main: {
    margin: '0 auto',
    maxWidth: `${theme.breakpoints.values.lg}px`,
    display: 'flex',
    minHeight: 'calc(100vh - 150px);',
    flexDirection: 'column',
    justifyContent: 'space-between'
  }
}));

const LayoutTableView = ({ children }) => {
  const classes = useStyles();
  return (
    <>
      <NavBar />
      <div className={classes.main}>
        <Grid container justify="center" className={classes.main}>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
        <Footer />
      </div>
    </>
  );
};

export default LayoutTableView;
