import TableCell from '@material-ui/core/TableCell';
import cn from 'classnames';
import React from 'react';

import classes from './SortableCell.module.css';

export const SortableCell = ({ children, sortingKey, onSorting, sorting, checkbox, ...props }) => {
  return (
    <TableCell {...props} className={classes.cell}>
      <div className={classes.wrapper}>
        <div
          className={classes.content}
          onClick={() => {
            onSorting?.({
              key: sortingKey,
              dir: sorting?.dir === 'asc' ? 'desc' : 'asc'
            });
          }}
        >
          {children}
          <div className={cn(classes.direction, sorting?.key === sortingKey && classes.sorted)}>
            {sorting.dir === 'asc' ? '▲' : '▼'}
          </div>
        </div>
        {checkbox}
      </div>
    </TableCell>
  );
};
