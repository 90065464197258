import React from 'react';

/**
 * @func addUnits
 * @param {String} values
 * @param {String} [unit='rem']
 * @return {String}
 */
const addUnits = (values, unit = 'rem') =>
  values
    .split(' ')
    .map(x => (Number.isFinite(Number(x)) && x !== '0' ? `${x}${unit}` : x))
    .join(' ');

/**
 * @func styles
 * @param {String} props
 * @param {String} props.margin
 * @param {String} props.padding
 * @param {String} props.style
 * @param {String} props.unit
 * @return {Object}
 */
const styles = ({ margin, padding, unit, style }) => ({
  ...style,
  margin: margin ? addUnits(margin, unit) : undefined,
  padding: padding ? addUnits(padding, unit) : undefined
});

/**
 * React component to wrap any other component that needs spacing properties
 *
 * @func Box
 * @param {React.Element} children
 * @param {Object} props
 * @param {String} props.margin
 * @param {String} props.padding
 * @param {Object} props.style
 * @param {String} [props.unit=rem]
 */
const Box = ({ children, ...props }) => <div style={styles(props)}>{children}</div>;

export default Box;
