import React from 'react';
import { injectIntl } from 'react-intl';
import styles from './ShootDuration.module.css';

const ShootDuration = ({ duration, intl }) => {
  return (
    <div>
      <hr className={styles.Hr} />

      <div className={styles.Table}>
        <img
          src="https://public-assets-one.s3.amazonaws.com/icons/clock_grey.svg"
          alt="Duration"
          className={styles.IconTime}
        />
      </div>
      <div className={styles.Table}>
        {intl.formatMessage(
          {
            id: 'components.PhotoShootSpecCard.totalDuration'
          },
          { duration }
        )}
      </div>
    </div>
  );
};

export default injectIntl(ShootDuration);
