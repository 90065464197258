import { useIntlScope } from '@lemonone/components';
import Box from '@material-ui/core/Box';
import ErrorMessage from 'components/Error';
import Loading from 'components/Loading';
import PageHeading from 'components/PageHeading';
import React from 'react';

import { useGetContactsQuery } from '../__generated__';
import { ContactsTable } from '../components/ContactsTable';

const ContactsPage: React.FC<{}> = () => {
  const { loading, error, data } = useGetContactsQuery();
  const text = useIntlScope('@lemonone/bookingui/');
  return (
    <Box margin="24px 0 24px 0">
      <PageHeading title={text('users')} />
      {loading && <Loading />}
      {!loading && error && <ErrorMessage message={error.message} />}
      {!loading && data && <ContactsTable contacts={data.contacts} />}
    </Box>
  );
};

export default ContactsPage;
