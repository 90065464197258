// TODO: use `babel-plugin-import-graphql`
// @see https://www.apollographql.com/docs/react/recipes/babel.html#Using-babel-plugin-import-graphql
//
// As far as we are using create-react-app we can't easyly change babel config
// without ejecting.
// https://github.com/detrohutt/babel-plugin-import-graphql#for-users-of-create-react-app
import gql from 'graphql-tag';

const createUploadUrl = `
  mutation initUpload($mimeType: String!) {
    createUploadUrl(mimeType: $mimeType)
  }
`;

export default gql(createUploadUrl);
