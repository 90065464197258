import React from 'react';
import { withStyles } from '@material-ui/core';
import Grid from 'components/Grid';

const styles = theme => ({
  main: {
    margin: '0 auto',
    maxWidth: `${theme.breakpoints.values.md}px`
  }
});

const Container = ({ children, classes }) => (
  <Grid container justify="center" className={classes.main}>
    <Grid item xs={12}>
      {children}
    </Grid>
  </Grid>
);

export default withStyles(styles)(Container);
