import React from 'react';
// components
import Box from 'components/Box';
import Text from 'components/Text';
// material-ui
import TextField from '@material-ui/core/TextField';
import { FormLabel } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { LocationExternalIdLabel, LocationNameLabel, Title } from './i18n';

const CheckoutFormLocation = ({ values, actions, required = {}, meta }) => {
  const intl = useIntl();
  return (
    <Box margin="0 0 2">
      <Text>
        <Title />
      </Text>

      <TextField
        fullWidth
        name="locationName"
        label={<LocationNameLabel />}
        margin="none"
        onBlur={actions.onBlur}
        onChange={actions.onChange}
        value={values.locationName || ''}
      />
      <TextField
        fullWidth
        required={required.externalId}
        name="externalId"
        label={<LocationExternalIdLabel />}
        margin="none"
        onBlur={actions.onBlur}
        onChange={actions.onChange}
        value={values.externalId || ''}
      />
      {meta.touched.externalId && meta.errors.externalId && (
        <FormLabel error>
          {intl.formatMessage({
            id:
              typeof meta.errors.externalId === 'string'
                ? meta.errors.externalId
                : 'components.CheckoutFormHost.Labels.externalIdError'
          })}
        </FormLabel>
      )}
    </Box>
  );
};

export default CheckoutFormLocation;
