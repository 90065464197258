import { Button, useSearchParam } from '@lemonone/components';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';
import Box from 'components/Box';
import CreateBookingButton from 'components/CreateBookingButton';
import ErrorMessage from 'components/Error';
import Loading from 'components/Loading';
import OrderTable from 'components/OrderTable';
import PageHeading from 'components/PageHeading';
import { addMonths, endOfMonth, formatISO, isValid, startOfMonth } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import track from 'utils/analytics';

import { OrderInfoSidebar } from '../OrderInfoSidebar';
import { ExportOrdersCsv } from '../OrganizationBookings/ExportOrdersCsv';

const MyBookings = ({ title, load, loading, error, data }) => {
  const intl = useIntl();
  const [order, setOrder] = useState(null);

  const defaultSearchParams = {
    start: formatISO(startOfMonth(new Date()), { representation: 'date' }),
    end: formatISO(endOfMonth(addMonths(new Date(), 1)), { representation: 'date' })
  };

  const [searchParams, setSearchParams] = useSearchParam(defaultSearchParams);

  const startDate = searchParams.get('start');
  const endDate = searchParams.get('end');
  const searchQuery = searchParams.get('q');

  useEffect(() => {
    if (isValid(new Date(startDate)) && isValid(new Date(endDate))) {
      load?.({ startDate, endDate });
      return;
    }
    setSearchParams(defaultSearchParams);
    load?.(defaultSearchParams);
  }, []);

  let orders = data ?? [];

  if (searchQuery) {
    const words = searchQuery.split(' ').map(word => new RegExp(`${word}`, 'i'));
    orders = orders.filter(item => words.every(regex => regex.test(JSON.stringify(item))));
  }

  return (
    <>
      <Box margin="24 0 24 0" unit="px">
        <form
          onSubmit={e => {
            e.preventDefault();
            load?.({ startDate, endDate });
          }}
        >
          <PageHeading title={title}>
            <FormControl variant="outlined" margin="dense">
              <InputLabel htmlFor="search-field" shrink>
                {intl.formatMessage({ id: 'page.organization.bookings.search' })}
              </InputLabel>
              <OutlinedInput
                id="search-field"
                value={searchQuery ?? ''}
                labelWidth={60}
                notched
                onChange={e => setSearchParams({ q: e.target.value })}
                endAdornment={
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                }
              />
            </FormControl>
            <TextField
              type="date"
              variant="outlined"
              margin="dense"
              InputLabelProps={{ shrink: true }}
              label={intl.formatMessage({ id: 'page.organization.bookings.from' })}
              defaultValue={startDate}
              onChange={e => {
                const val = e.target.value;
                if (isValid(new Date(val))) {
                  setSearchParams({ start: val, page: 0 });
                }
              }}
            />
            <TextField
              type="date"
              variant="outlined"
              margin="dense"
              InputLabelProps={{ shrink: true }}
              label={intl.formatMessage({ id: 'page.organization.bookings.until' })}
              defaultValue={endDate}
              onChange={e => {
                const val = e.target.value;
                if (isValid(new Date(val))) {
                  setSearchParams({ end: val, page: 0 });
                }
              }}
            />
            <Button disabled={loading} color="primary" variant="contained" type="submit" size="xs">
              {intl.formatMessage({ id: 'page.organization.bookings.apply' })}
            </Button>
            <ExportOrdersCsv orders={orders} disabled={loading} />
            <CreateBookingButton />
          </PageHeading>
        </form>
      </Box>
      {loading && <Loading />}
      {!loading && error && <ErrorMessage message={error} />}
      {!loading && (
        <OrderTable
          orders={orders}
          onOrderPreview={item => {
            track('previewOrderInSidebar');
            setOrder(item);
          }}
        />
      )}
      <OrderInfoSidebar open={Boolean(order)} order={order} onClose={() => setOrder(null)} />
    </>
  );
};

export default MyBookings;
