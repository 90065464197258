import { ConsentCheckbox, Typography, useIntlScope } from '@lemonone/components';
import React from 'react';
import { useController } from 'react-hook-form';

const RescheduleShootFormConsent = ({ control }) => {
  const intl = useIntlScope('@lemonone/bookingui/components/RescheduleShoot:');
  const { field: checkbox } = useController({
    control,
    name: 'consent',
    defaultValue: true,
    rules: { required: true }
  });
  return (
    <ConsentCheckbox {...checkbox} variant="outlined" required defaultChecked={false} data-e2e="consentCheckbox">
      <Typography as="p">{intl('consent')}</Typography>
    </ConsentCheckbox>
  );
};

export default RescheduleShootFormConsent;
