import { Row, Typography, useIntlScope } from '@lemonone/components';
import { Grid } from '@material-ui/core';
import React from 'react';

import RescheduleShootForm from './RescheduleShootForm';

const RescheduleShoot = ({ orderInfo, rescheduleShoot, cancelShoot }) => {
  const tpl = useIntlScope('@lemonone/bookingui/components/RescheduleShoot:');
  return (
    <Grid container justify="center" style={{ marginTop: 24, marginBottom: 24 }}>
      <Grid item xs={12} sm={8}>
        <Row>
          <Typography as="h1" size="lg" color="primary">
            {tpl('title')}
          </Typography>
        </Row>
        <Row>
          <RescheduleShootForm orderInfo={orderInfo} rescheduleShoot={rescheduleShoot} cancelShoot={cancelShoot} />
        </Row>
      </Grid>
    </Grid>
  );
};

export default RescheduleShoot;
