import React from 'react';
import compose from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { useAuth0 } from '@auth0/auth0-react';
// actions
// components
import { useCreateOrderMutation, useIsPrepaidQuery } from '__generated__';
import Form from 'components/Form';
import CheckoutFormActions from 'components/CheckoutFormActions';
import CheckoutFormBillingAddress from 'components/CheckoutFormBillingAddress';
import CheckoutFormHost from 'components/CheckoutFormHost';
import { useHistory } from 'react-router-dom';
import CheckoutFormLocation from 'components/CheckoutFormLocation';
import CheckoutFormNotes from 'components/CheckoutFormNotes';
import CheckoutFormConsent from 'components/CheckoutFormConsent';
import { withNotification } from 'components/Notification';
import { useSettings } from '@lemonone/components';
import handleSubmitCheckoutForm from './handleSubmitCheckoutForm';

const CheckoutForm = props => {
  const { user } = useAuth0();
  const history = useHistory();
  const [createOrder] = useCreateOrderMutation();
  const { data = {}, loading } = useIsPrepaidQuery();
  const settings = useSettings();
  const externalIdRequired = settings?.bookingUI?.externalIdRequired;
  const handleSubmit = handleSubmitCheckoutForm({ ...props, user, data, createOrder, history });
  return (
    <Form defaultValues={props.defaultValues} onSubmit={handleSubmit}>
      {(values, actions, meta) => (
        <>
          <CheckoutFormHost values={values} actions={actions} meta={meta} />
          <CheckoutFormLocation
            values={values}
            actions={actions}
            meta={meta}
            required={{ externalId: externalIdRequired }}
          />
          <CheckoutFormBillingAddress values={values} actions={actions} meta={meta} />
          <CheckoutFormNotes values={values} actions={actions} meta={meta} />
          <CheckoutFormConsent values={values} actions={actions} meta={meta} />
          <CheckoutFormActions
            loading={loading || meta.loading}
            prepaid={!loading && data?.isPrepaid}
            price={props.price}
          />
        </>
      )}
    </Form>
  );
};

export default compose(injectIntl, withNotification)(CheckoutForm);
