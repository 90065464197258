import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
  <div>
    <h1>404 Not Found</h1>
    <h3>Did you get lost?</h3>
    <p>Let me help you get back to book your photo shooting with LemonOne.</p>
    <Link to="/">Click here to start booking your photo shooting</Link>
  </div>
);

export default NotFound;
