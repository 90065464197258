import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import withWidth from '@material-ui/core/withWidth';

const styles = theme => ({
  leftIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  userContainer: {
    alignItems: 'center',
    display: 'inline-flex',
    verticalAlign: 'middle'
  }
});

const UserButton = ({ classes, user, width }) => (
  <span className={classes.userContainer}>
    {width !== 'xs' && user.name}
    <Avatar
      alt={user.name}
      src={user.picture}
      component="span"
      className={width !== 'xs' ? classes.leftIcon : undefined}
    />
  </span>
);

export default withWidth()(withStyles(styles)(UserButton));
