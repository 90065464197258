/* eslint-disable quotes, prefer-object-spread */

const intl = require('@lemonone/intl/lib/en');
const components = require('@lemonone/components/lib/i18n/en');

module.exports = Object.assign({}, intl, components, {
  'components.CheckoutFormActions.buttonBook': 'Book',
  'components.CheckoutFormActions.termsLink': 'https://www.lemonone.com/en/terms-conditions',
  'components.CheckoutFormActions.privacyLink': 'https://www.lemonone.com/en/privacy-policy',
  'components.CheckoutFormActions.buttonPayBook': 'Pay {price} & Book',
  'components.CheckoutFormActions.labelTC':
    'By placing your order you agree to LemonOne GmbH <terms-link>terms of services</terms-link>.\nPlease see our <privacy-link>privacy policy</privacy-link>.',

  'components.CheckoutFormConsent.label':
    'I do confirm, that I am authorized to pass the provided contact details to LemonOne GmbH for a reason of fulfilling contract obligations',

  'components.CheckoutFormHost.title': 'Host details',
  'components.CheckoutFormHost.description': 'Location Contact Details',
  'components.CheckoutFormHost.Labels.name': 'Name',
  'components.CheckoutFormHost.Labels.nameError': 'Please enter the name of the contact person',
  'components.CheckoutFormHost.Labels.email': 'Email',
  'components.CheckoutFormHost.Labels.emailError': 'Please enter a valid email address',
  'components.CheckoutFormHost.Labels.phone': 'Phone',
  'components.CheckoutFormHost.Labels.phoneError': 'Please enter a valid phone number',
  'components.CheckoutFormHost.Labels.externalIdError': 'Please enter a reference ID',
  'components.CheckoutFormHost.Labels.externalIdTooLongError': 'Reference ID cannot exceed 72 characters',

  'components.CheckoutFormLocation.title': 'Location details',
  'components.CheckoutFormLocation.Labels.locationName': 'Location Name (for example hotel or restaurant name)',
  'components.CheckoutFormLocation.Labels.externalId': 'Internal Reference ID',

  'components.CheckoutFormNotes.attachmentButton':
    'Or click here to upload any releavant documents (for example dish list)',
  'components.CheckoutFormNotes.attachmentDescription':
    'Or upload a document with any relevant information regarding your photoshoot',
  'components.CheckoutFormNotes.title': 'Additional information we need to know (access to the building etc.)',
  'components.CheckoutFormNotes.label': 'Message',
  'components.CheckoutFormNotes.description':
    "Write here any relevant information you have for us, but we didn't ask above (access to the building, shoot list etc.)",

  'components.Confirmation.buttonBook': 'Book more',

  'components.MyBookings.Header.title': 'Bookings',
  'components.CreateBookingButton.tooltip': 'Book Now!',
  'components.NoBookings.EmptyButton': 'Book Now',
  'components.NoBookings.EmptyText': 'You have not booked any photoshoots yet',

  'components.NavSteps.product': 'Product ',
  'components.NavSteps.booking': 'Date & time',
  'components.NavSteps.confirmation': 'Confirmation',
  'components.NavSteps.checkout': 'Appointment',

  'components.OrderTable.id': 'ID',
  'components.OrderTable.referenceId': 'Reference ID',
  'components.OrderTable.creator': 'Booked By',
  'components.OrderTable.createdAt': 'Booked On',
  'components.OrderTable.date': 'Date',
  'components.OrderTable.address': 'Address',
  'components.OrderTable.status': 'Status',
  'components.OrderTable.product': 'Product',
  'components.OrderTable.downloadUrl': 'Download photos',
  'components.OrderTable.seeAllInfo': 'See all information',
  'components.OrderTable.rescheduleUrl': 'Reschedule',
  'components.OrderTable.deliveredAt': 'Delivery date & time',

  'components.OrderTable.downloadCsv': 'Download CSV',

  'components.OrderTable.status:scheduled': 'scheduled',
  'components.OrderTable.status:cancelled': 'cancelled',
  'components.OrderTable.status:fulfilled': 'fulfilled',
  'components.OrderTable.status:rescheduling': 'rescheduled',
  'components.OrderTable.status:processing': 'processing',

  'components.OrderDetails.title': 'Your photoshoot booking details',

  'components.PhotoShootEventDetails.addressLabel': 'Address',
  'components.PhotoShootEventDetails.dateLabel': 'Photoshoot date',

  'components.PhotoShootSpecCard.totalDuration': '{duration} minutes max.',
  'components.ShootDuration.totalDuration': '{duration} minutes max.',

  'components.PhotoShootSpecDetails.duration': 'minutes max.',
  'components.PhotoShootSpecDetails.durationLabel': 'Max. duration',
  'components.PhotoShootSpecDetails.nameLabel': 'Product',

  'components.ProductSelection.buttonSelect': 'Schedule photoshoot',
  'components.ProductSelection.editingService': 'Request editing',

  'components.ShootRequest.confirmation':
    'We have received your details and will be in touch as soon as we have a photographer for your photoshoot',
  'components.ShootRequest.details':
    'Please fill in your contact details and propose three dates and times that work for you, and we will inform you as soon as we found a photographer.',
  'components.ShootRequest.team': 'Team LemonOne',
  'components.ShootRequest.titleAfter': 'Thanks for your request!',
  'components.ShootRequest.titleBefore':
    'Unfortunately, we do not have any available photographers in your area at the moment',

  'components.ShootRequestForm.action': 'Request photoshoot',
  'components.ShootRequestForm.message': 'Please tell us what you need!',

  'components.UserMenu.MenuItem.orders': 'Bookings',
  'components.UserMenu.MenuItem.organizationBookings': 'All Bookings',

  'components.CheckoutFormBillingAddress.title': 'Billing details',
  'components.CheckoutFormBillingAddress.billingAddress': '{name}, {street}, {postalCode} {city}, {countryCode}',
  'components.CheckoutFormBillingAddress.notFound':
    'Don’t see your billing address here? Please reach out to your account manager',
  'components.SelectDayTime.start': 'Starting time',

  'containers.Confirmation.confirmed': 'Your booking has been confirmed',
  'containers.Confirmation.processing': 'We are processing your booking...',

  'errors.Address Error: invalid': 'Please enter the full address, including the house number',
  'errors.Address Error: Not Found': 'Address not found',
  'errors.Address Error: COVID-19 Quarantine':
    'To protect all partners from COVID-19, it is not possible to book photoshoots in this area. For questions: hello@lemonone.com / +4930120872870',
  'errors.Error: ': 'Missing photoshoot information',
  'errors.Error: Order not found': 'The order could not be found',
  'errors.GraphQL error: Order already cancelled': 'The order is already cancelled',
  'errors.GraphQL error: Shoot has already been cancelled': 'The order is already cancelled',
  'errors.GraphQL error: Order cancellation event missing': 'The order is already cancelled',
  'errors.GraphQL error: Order not found': "The order you are trying to cancel doesn't exists",
  "errors.GraphQL error: Product doesn't exist!":
    'We need more information to process your request. Please contact us so that we can create your individual product',
  'errors.GraphQL error: Shoot lies in the past': 'The photoshoot you are trying to cancel already took place',
  "errors.GraphQL error: Variable 'orderId' has coerced Null value for NonNull type 'String!'":
    'You need to provide an identifier for the order you want to cancel',
  'errors.Error: Late cancellation attempt': 'The cancellation time limit has exceeded',
  'errors.Error: User email not verified.': 'Please verify your email address',
  'errors.GraphQL error: Host email is invalid: "value" must be a valid email': 'Please enter a valid email address',
  'errors.Failed to authenticate':
    'Something went wrong with the authenticattion. Please try again or contact us if the issue persist',
  'errors.GraphQL error: Selected date and time not available.':
    'Your choosen appointment is not available anymore. Please choose another date',
  'errors.GraphQL error: User email not verified': 'You need to verify your email to finish your booking',
  'errors.Invalid authentication nonce.': 'Authentication failed. Please try again or contact us if the issue persist',
  'errors.Missing required fields': 'Please fill all required fields',
  'errors.Network Error': 'There was a network issue. Please, try again or contact us if the issue persists',
  'errors.OrderDetailsFacade.missing_required_props':
    'We are missing some data to show you accurate informations regarding your photoshoot. Please try again and contact us if the issue persists',
  'errors.OrderDetailsFacade.not_found': 'Order not found',
  'errors.PhotoShootEventDetails.missing_data':
    'We are missing some data to show you accurate informations regarding your photoshoot. Please try again and contact us if the issue persist',
  'errors.PhotoShootSpecDetails.missing_data':
    'We are missing some data to show you accurate informations regarding your photoshoot. Please try again and contact us if the issue persist',
  'errors.Unexpected Error': 'There was an issue with your your request. Please let us know so we can solve it',
  'errors.User did not authorize the request': 'Please authorize the request to complete the login',
  'errors.GraphQL error: Expected prices to be set for products of non-prepaid organization':
    "Products' prices have not been set yet. Kindly contact us to proceed",
  'errors.GraphQL error: Cancellation for this shoot is not possible anymore':
    'Unfortunately the cancellation or rescheduling of this photoshoot is not possible anymore. Please reach out to your account manager for details',

  'errors.GraphQL error: TimeSlotExpired: booking is not possible on the selected date & time':
    'The selected time slot is no longer available, please select again',
  'errors.GraphQL error: TimeSlotExpired: booking date must be in the future':
    'The selected time slot is no longer available, please select again',
  'errors.GraphQL error: TimeSlotExpired: booking is not possible less than 36 hours in advance':
    'The selected time slot is no longer available, please select again',
  'errors.GraphQL error: TimeSlotExpired: booking is not possible less than 72 hours in advance':
    'The selected time slot is no longer available, please select again',
  'errors.GraphQL error: TimeSlotExpired: booking is only possible between 8-20 hours':
    'The selected time slot is no longer available, please select again',
  'errors.GraphQL error: TimeSlotExpired: booking is not possible on Sunday':
    'The selected time slot is no longer available, please select again',
  'errors.GraphQL error: TimeSlotExpired: booking is only possible at HH:00, HH:30':
    'The selected time slot is no longer available, please select again',

  'module.authentication.copyright': '© {year} LemonOne GmbH',
  'module.authentication.cta': 'Signup / Login',
  'module.authentication.headline': 'Boost your business with stunning pictures',
  'module.authentication.imprint': 'Imprint',
  'module.authentication.navLoginButton': 'SignUp / Login',
  'module.authentication.privacy': 'Privacy policy',
  'module.authentication.DialogAlert.title': 'You need to verifiy your email',
  'module.authentication.DialogAlert.description':
    'Please verifiy your email address by clicking on the verification link on the email',
  'module.authentication.DialogAlert.buttonAccept': 'I verified my email',
  'module.authentication.DialogAlert.buttonCancel': 'Logout',

  'Company Name': 'Company Name',
  Name: 'Name',
  'Contact us': 'Contact us',
  Email: 'Email',
  'Billing Address': 'Billing Address',
  City: 'City',
  Zipcode: 'Zipcode',
  Phone: 'Phone',
  'This field is required.': 'This field is required',
  'You must accept the terms and conditions.': 'You must accept the terms and conditions',
  'Your Booking': 'Your Booking',
  'Book More': 'Book More',
  'Sorry, there are no appointments for the selected date.': 'Sorry, there are no appointments for the selected date',
  'Recommended time': 'Recommended time',
  'We are processing your order...': 'We are processing your order...',
  Select: 'Select',
  'Tell us where you need the photo shoot': 'Tell us where you need the photoshoot:',
  'Pick a date that works for you': 'And when:',
  'Unfortunately, we do not have any available photographers in your area at the moment.':
    'Unfortunately, we do not have any available photographers in your area at the moment',
  'Please fill in your contact details and propose three dates and times that work for you, and we will inform you as soon as we found a photographer.':
    'Please fill in your contact details and propose three dates and times that work for you, and we will inform you as soon as we found a photographer',
  'Thanks for your request!': 'Thanks for your request!',
  'We have received your details and will be in touch as soon as we have a photographer for your photoshoot.':
    'We have received your details and will be in touch as soon as we have a photographer for your photoshoot',
  'Team LemonOne': 'Team LemonOne',
  'Enter your contact data so we can get in touch with you.': 'Enter your contact data so we can get in touch with you',
  'All fields with * are mandatory.': 'All fields with * are mandatory',
  'Please use your company email.': 'Please use your company email',
  'We are very sorry that you have decided to cancel your booking. Please be aware that it is only possible to cancel bookings without charge up to 48 hours before the photo shoot. Also note that some time slots may not be available for re-booking any more.':
    'We are very sorry that you have decided to cancel your booking. Please be aware that it is only possible to cancel bookings without charge up to 48 hours before the photo shoot. Also note that some time slots may not be available for re-booking any more.',
  'Cancel Photo Shoot': 'Cancel Photoshoot',
  'Reasons for Cancellation': 'Reasons for Cancellation',
  'Your photo shoot appointment has been cancelled': 'Your photoshoot appointment has been cancelled',
  'We can find a solution for you!': 'We can find a solution for you!',
  'Pay & Book': 'Pay & Book',
  'We can not complete your request. Please contact customer support':
    'We can not complete your request. Please contact customer support',
  'plus VAT': 'plus VAT',
  'Your contact details': 'Your contact details',
  Continue: 'Schedule',
  'Billing details': 'Billing details',
  'Look for available time slots in my location': 'Look for available time slots in my location',
  'Clear the Searchfield': 'Clear the Searchfield',

  'page.organization.bookings.apply': 'Apply',
  'page.organization.bookings.from': 'Photoshoots from',
  'page.organization.bookings.title': 'All Bookings',
  'page.organization.bookings.until': 'Photoshoots until',
  'page.organization.bookings.search': 'Search',

  '@lemonone/bookingui/components/OrderDetails:title': 'Your photoshoot details:',
  '@lemonone/bookingui/components/RescheduleShoot:title': 'Rescheduling or Cancellation',
  '@lemonone/bookingui/components/RescheduleShoot:calendar':
    'In order to reschedule the photoshoot, please select new date and time:',
  '@lemonone/bookingui/components/RescheduleShoot:reason':
    'Please state the reason for the rescheduling / cancellation:',
  '@lemonone/bookingui/components/RescheduleShoot:consent':
    '<bold>I am aware, that cancellation / rescheduling fees can apply.</bold>\nFor more information on the fees contact your account manager.',
  '@lemonone/bookingui/components/RescheduleShoot:reschedule': 'Reschedule photoshoot',
  '@lemonone/bookingui/components/RescheduleShoot:cancel': 'Cancel photoshoot',
  '@lemonone/bookingui/components/RescheduleShoot:not_available':
    'Sorry, there are no appointments for the selected date.',
  '@lemonone/bookingui/noProducts':
    'There are no products available to book at the moment.\n\nPlease reach out to your <bold>account</bold> manager for assistance.',
  '@lemonone/bookingui/uploadAttachments': 'Add attachments',
  '@lemonone/bookingui/users': 'Users'
});
