import 'react-app-polyfill/stable';

/* eslint-disable import/no-dynamic-require, global-require */
import { ThemeProvider, IntlProvider } from '@lemonone/components';

import './assets/base.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { CircularProgress } from '@material-ui/core';
import App from 'App';
import store from 'store';
import { getDateFnsLocale } from 'utils/i18n';
import { BrowserRouter, useHistory, Switch, Route } from 'react-router-dom';
import { Provider as StateProvider } from 'unistore/react';
import { ThemeProvider as MuiThemeProvider } from 'helpers/styles';
import { initLogger } from 'utils/logger';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import track from 'utils/analytics';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { LoginForm } from './components/LoginForm';
import { ApolloProvider } from './providers/ApolloProvider';
import { AuthPage } from './helpers/auth/AuthPage';

// initialise logger
// connect to 3rd party providers like Sentry
initLogger();
// initialise analytics
// As far as we have a SPA and URL changes don't imply a page refresh we need
// to track page views manually:
// 1. intial 'pageview' is tracked here
// 2. and then we listen for changes in the URL
track('pageview', { label: window.location.pathname });

const Authentication = ({ children }) => {
  const history = useHistory();
  return (
    <Auth0Provider
      useRefreshTokens
      cacheLocation="localstorage"
      domain={process.env.REACT_APP_BOOKINGUI_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_BOOKINGUI_AUTH0_CLIENTID}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_BOOKINGUI_AUTH0_AUDIENCE}
      scope="openid profile email offline_access"
      onRedirectCallback={state => state.returnTo && history.push(state.returnTo)}
    >
      {children}
    </Auth0Provider>
  );
};

const Root = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!isAuthenticated) {
    return (
      <Switch>
        <Route path="/login" component={LoginForm} />
        <Route component={AuthPage} />
      </Switch>
    );
  }

  return (
    <ApolloProvider>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getDateFnsLocale()}>
        <ThemeProvider theme="blackish">
          <StateProvider store={store}>
            <App />
          </StateProvider>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </ApolloProvider>
  );
};

ReactDOM.render(
  <BrowserRouter>
    <MuiThemeProvider>
      <IntlProvider load={language => require(`./assets/locales/${language}`)}>
        <Authentication>
          <Root />
        </Authentication>
      </IntlProvider>
    </MuiThemeProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
