import React from 'react';
// components
import Container from 'components/Container';
import NavBar from 'components/NavBar';
import NavSteps from 'components/NavSteps';
import { Footer } from '@lemonone/components';
import styles from './LayoutDefault.module.css';

export const LayoutDefault = ({ children }) => (
  <>
    <div className={styles.CommonContainer}>
      <NavBar />
      <div className={styles.wrapper}>
        <Container>
          <NavSteps />
          {children}
        </Container>
        <div className={styles.footerDiv}>
          <Footer />
        </div>
      </div>
    </div>
  </>
);

export default LayoutDefault;
