import React from 'react';
import { connect } from 'unistore/react';
import { Row } from '@lemonone/components';
import { onCreateOrder, onStartPayment } from 'actions/checkout';
// components
import CheckoutForm from 'components/CheckoutForm';
import Grid from 'components/Grid';
import { useHistory } from 'react-router-dom';
import OrderDetails from '../components/OrderDetails';

export const mapStateToProps = state => state;

// FIXME codestyle
// eslint-disable-next-line @typescript-eslint/no-shadow
export const Checkout = ({ appointment, address, onCreateOrder, checkoutFormData, onStartPayment, product }) => {
  const history = useHistory();
  // if we don't have the data needed for this container to work properly
  // we redirect to the initial page
  if (!appointment || !product) {
    history.push('/');
    return false;
  }

  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12} sm={8}>
          <Row>
            <OrderDetails
              orderInfo={{
                productUuid: product.uuid,
                productName: product.name,
                shootDateTime: appointment.start,
                shootAddress: address,
                shootTimeZone: appointment.timeZoneId,
                shootDurationMins: product.duration
              }}
            />
          </Row>
          <Row>
            <CheckoutForm
              onSubmit={onCreateOrder}
              onPayment={onStartPayment}
              price={product.price}
              defaultValues={{
                hostName: '',
                hostEmail: '',
                consent: false,
                ...checkoutFormData
              }}
            />
          </Row>
        </Grid>
      </Grid>
    </>
  );
};

export default connect(mapStateToProps, { onCreateOrder, onStartPayment })(Checkout);
