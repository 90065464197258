import track from 'utils/analytics';
import logger from 'utils/logger';

const mapStateToOrder = (
  { additionalData: { affiliateUserId }, address, appointment: { end, start }, product: { uuid } },
  { attachment, externalId, locationName, hostName, hostEmail, hostPhone, notes, billingAddressUuid }
) => ({
  billingAddressUuid,
  additionalData: {
    attachment,
    externalId: externalId || affiliateUserId,
    notes
  },
  host: hostName || hostEmail || hostPhone ? { name: hostName, email: hostEmail, phone: hostPhone } : undefined,
  productId: uuid,
  shoot: {
    end,
    start,
    location: { address, name: locationName }
  }
});

export const onCreateOrder = async (
  state,
  formData,
  createOrderMutation,
  onNotification,
  intl,
  paymentToken,
  history
) => {
  let stateMutation = null;
  // side-effect: analytics
  try {
    const response = await createOrderMutation({
      variables: {
        order: {
          paymentToken: paymentToken && paymentToken.id,
          ...mapStateToOrder(state, formData)
        }
      }
    });
    stateMutation = {
      ...state,
      order: response.data.createOrder,
      address: undefined,
      checkoutFormData: undefined
    };
    // side-effect analytics
    // TODO indentify user for tracking session
    track('onCreateOrderSuccess');
    // side-effect: redirection
    history.push(`/confirmation?order=${response.data.createOrder.uuid}`);
  } catch (e) {
    onNotification(
      intl.formatMessage({
        id: `errors.${e.message}`,
        defaultMessage: 'Oops! something unexpected happened. Please try again and contact us if the issue persists.'
      })
    );
    if (e.message.includes('TimeSlotExpired')) {
      history.push('/choose-appointment');
      return { ...state, checkoutFormData: formData };
    }
    logger('error', e);
    track('onCreateOrderFailed');
  }
  // mutate state
  return stateMutation;
};

export const onStartPayment = (state, open, user) => {
  // side-effect: analytics
  track('startPayment');
  // side-effect: open Stripe payment modal
  open({
    name: state.product.name,
    email: user.email,
    currency: 'eur',
    amount: state.product.price.amount
  });
  // mutate state
  return { ...state };
};
