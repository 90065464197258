import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Title = () => (
  <FormattedMessage
    defaultMessage="Additional Information"
    description="Title for the location details form"
    id="components.CheckoutFormNotes.title"
  />
);

export const Label = () => (
  <FormattedMessage
    defaultMessage="Message"
    description="A message with any extra information/requirement that the user need to communicate"
    id="components.CheckoutFormNotes.label"
  />
);

export const Description = () => (
  <FormattedMessage
    defaultMessage="Write here any relevant information you have for us, but we didn't ask above."
    description="Brief explanation of what's expected as a message."
    id="components.CheckoutFormNotes.description"
  />
);

export const AttachmentButton = () => (
  <FormattedMessage
    defaultMessage="Click here to select the file to upload."
    description="Text in button to upload file"
    id="components.CheckoutFormNotes.attachmentButton"
  />
);

export const AttachmentDescription = () => (
  <FormattedMessage
    defaultMessage="Or upload a document with any relevant information regarding your photo shoot session."
    description="Explain what is expected to be uploaded: a document with relevant information for the photo shoot session."
    id="components.CheckoutFormNotes.attachmentDescription"
  />
);
