import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Title = () => (
  <FormattedMessage
    defaultMessage="Host details"
    description="Title for the host details form"
    id="components.CheckoutFormHost.title"
  />
);
export const Description = () => (
  <FormattedMessage
    defaultMessage="If another person will be in the location the day of the shoot, please fill here the details so we can contact if necessary."
    description="Description for the host details form"
    id="components.CheckoutFormHost.description"
  />
);
export const HostNameLabel = () => (
  <FormattedMessage
    defaultMessage="Name"
    description="Label for the name or the person that'll be on the photo shoot location"
    id="components.CheckoutFormHost.Labels.name"
  />
);
export const HostNameError = () => (
  <FormattedMessage
    defaultMessage="Please enter the name of the contact person"
    description="Error message displayed in case of missing host name"
    id="components.CheckoutFormHost.Labels.nameError"
  />
);
export const HostEmailLabel = () => (
  <FormattedMessage
    defaultMessage="Email"
    description="Label for the email or the person that'll be on the photo shoot location"
    id="components.CheckoutFormHost.Labels.email"
  />
);
export const HostEmailError = () => (
  <FormattedMessage
    defaultMessage="Please enter a valid email address"
    description="Error message displayed for missing/invalid email"
    id="components.CheckoutFormHost.Labels.emailError"
  />
);
export const HostPhoneLabel = () => (
  <FormattedMessage
    defaultMessage="Phone"
    description="Label for the phone or the person that'll be on the photo shoot location"
    id="components.CheckoutFormHost.Labels.phone"
  />
);
export const HostPhoneError = () => (
  <FormattedMessage
    defaultMessage="Please enter a valid phone number"
    description="Error message displayed for missing phone number"
    id="components.CheckoutFormHost.Labels.phoneError"
  />
);
