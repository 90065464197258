export const mapStatus = order => {
  const now = new Date();
  const start = new Date(order.shoot.start);
  if (order.status === 'fulfilled') {
    return 'fulfilled';
  }
  if (order.status === 'confirmed' && start <= now) {
    return 'processing';
  }
  if (order.status === 'confirmed' && now <= start) {
    return 'scheduled';
  }
  if (order.status === 'cancelled') {
    return 'cancelled';
  }
  if (order.status === 'rescheduling') {
    return 'rescheduling';
  }
  return 'scheduled';
};
