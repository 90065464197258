import { Alert, Footer, Icon, Row, Typography } from '@lemonone/components';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import OrderDetails from '../OrderDetails';
import RescheduleShootFormActions from './RescheduleShootFormActions';
import RescheduleShootFormCalendar from './RescheduleShootFormCalendar';
import RescheduleShootFormCancelled from './RescheduleShootFormCancelled';
import RescheduleShootFormConsent from './RescheduleShootFormConsent';
import RescheduleShootFormNotes from './RescheduleShootFormNotes';
import RescheduleShootFormScheduled from './RescheduleShootFormScheduled';

const defaultValues = {
  consent: false,
  cancellationNotes: '',
  start: '',
  server: ''
};

const RescheduleShootForm = ({ orderInfo, cancelShoot, rescheduleShoot }) => {
  const intl = useIntl();
  const [action, setAction] = useState(null);
  const { handleSubmit, control, formState: form, getValues, setError } = useForm({ defaultValues });

  if (form.isSubmitSuccessful) {
    const { start } = getValues();
    if (action === 'cancel') {
      return <RescheduleShootFormCancelled orderInfo={orderInfo} />;
    }
    if (action === 'reschedule') {
      return <RescheduleShootFormScheduled orderInfo={{ ...orderInfo, shootDateTime: start }} />;
    }
  }

  const onSubmit = async ({ start, cancellationNotes }) => {
    try {
      if (action === 'cancel') {
        await cancelShoot({
          variables: {
            orderId: orderInfo.orderUuid,
            cancellationNotes
          }
        });
      }
      if (action === 'reschedule') {
        if (!start) {
          setError('start', {
            type: 'required'
          });
          return;
        }
        await rescheduleShoot({
          variables: {
            orderId: orderInfo.orderUuid,
            start,
            cancellationNotes
          }
        });
      }
    } catch (err) {
      setError('server', {
        type: 'error',
        message: err.message
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <OrderDetails orderInfo={orderInfo} />
      </Row>
      <Row>
        <RescheduleShootFormCalendar orderInfo={orderInfo} control={control} action={action} />
      </Row>
      <Row>
        <RescheduleShootFormNotes control={control} />
      </Row>
      <Row>
        <RescheduleShootFormConsent control={control} />
      </Row>
      {form.errors.server && (
        <Row>
          <Alert variant="error">
            <Icon icon="error" variant="error" />
            <Typography color="error">
              {intl.formatMessage({
                id: `errors.${form.errors.server.message}`,
                defaultMessage: form.errors.server.message
              })}
            </Typography>
          </Alert>
        </Row>
      )}
      <Row>
        <RescheduleShootFormActions action={action} setAction={setAction} disabled={form.isSubmitting} />
      </Row>
      <Row>
        <Footer />
      </Row>
    </form>
  );
};

export default RescheduleShootForm;
