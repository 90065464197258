import React from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';
// material-ui
import { ListItem, ListItemText } from '@material-ui/core';
import styles from './TimeSlot.module.css';

const DATE_FORMAT = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false
};

/**
 * React Component
 * Render diferent time slots options
 *
 * @func TimeSlot
 * @param {Object} props
 * @return {Function} React component for each available time slot
 */
const TimeSlot = ({ onClick, selected, timeslot }) => {
  const intl = useIntl();

  const formattedStart = intl.formatDate(timeslot.start, {
    timeZone: timeslot.timeZoneId,
    ...DATE_FORMAT
  });

  return (
    <ListItem
      button
      data-e2e="dateSelectTimeItem"
      onClick={() => onClick(timeslot)}
      style={{ display: 'inline-block', width: '15%', textAlign: 'center' }}
      disableGutters
    >
      <ListItemText
        primary={
          <span
            className={cn({
              [styles.Guaranteed]: timeslot.recommended,
              [styles.Selected]: selected
            })}
          >
            {formattedStart}
          </span>
        }
      />
    </ListItem>
  );
};

export default TimeSlot;
