import createStore from 'unistore';
import devtools from 'unistore/devtools';
import { cacheState, cachedState } from 'helpers/store';

const initialState = {
  additionalData: {
    affiliateUserId: undefined
  },
  address: '',
  appointment: null,
  customerId: null,
  defaultShootSpec: null,
  order: null,
  product: null
};

/**
 * Application state build from:
 *
 * - initialState
 * - short-lived cache: sessionStorage
 * - long-lived cache: localStorage
 *
 * @type {Object}
 */
const currentState = { ...initialState, ...cachedState() };

// initialise store
const store = process.env.NODE_ENV === 'production' ? createStore(currentState) : devtools(createStore(currentState));

store.subscribe(cacheState);

export default store;
