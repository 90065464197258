import logger from './logger';

/**
 * @module utils/analytics
 */

/**
 * Google Analytics tracking function
 *
 * Loaded through Google Tag Manager
 * @see /publit/index.html
 *
 * @type {Function}
 */
const { ga } = window;

/**
 * Object from Sentry SDK
 * @see {@link https://docs.sentry.io/clients/javascript/}
 *
 * At this moment we are loading Sentry SDK directly from their CDN
 * @see /public/index.html
 * Reason for this setup is performance: our js bundle is already 2MB.
 * Until we implemente code-splitting we better don't add more to the bundle.
 *
 * @type {Object}
 */
const { Sentry } = window;

const __DEV__ = process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test';

/**
 * Send an "event" to whatever analytics tools we have setup
 *
 * @example
 *    track('event',
 *      {
 *        category: '',
 *        action: '',
 *        label: '',
 *        value: 0
 *      }
 *    )
 *
 * @func track
 * @param options
 */
function track(event, options = {}) {
  if (__DEV__) {
    logger('info', `[analytics] ${event}`, options);
  }

  // Send to Google Analytics
  if (!__DEV__ && ga) {
    if (event === 'pageview') {
      ga('send', {
        hitType: 'pageview',
        page: options.label
      });
    } else {
      const eventCategory = options.category || 'user';
      const eventAction = event;
      const eventLabel = options.label;
      const eventValue = options.value;
      ga('send', {
        hitType: 'event',
        eventCategory,
        eventAction,
        eventLabel,
        eventValue
      });
    }
  }
}

/**
 * Send user related data to different tracking services
 *
 * @func identify
 * @param  {Object} options
 */
export function identify(options) {
  const { affiliateUserId, customerId, defaultShootSpec, email, name, sub } = options;

  if (__DEV__) {
    logger('info', '[analytics] set user data:', options);
  }

  // Send to Google Analytics
  if (!__DEV__ && ga) {
    if (customerId) ga('set', 'dimension1', customerId);
    if (affiliateUserId) ga('set', 'dimension2', affiliateUserId);
    if (defaultShootSpec) ga('set', 'dimension3', defaultShootSpec);
  }

  // Send to Sentry
  if (!__DEV__ && Sentry) {
    Sentry.configureScope(scope => {
      scope.setUser({
        affiliateId: customerId,
        affiliateUserId,
        defaultShootSpec,
        email,
        name,
        sub
      });
    });
  }
}

export default track;
