import { Link, Typography } from '@lemonone/components';
import React from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { getMoneyProps } from 'utils/i18n';

export const BookButton = () => (
  <FormattedMessage
    defaultMessage="Book"
    description="CTA to finalise the photo shoot booking"
    id="components.CheckoutFormActions.buttonBook"
  />
);
export const PayAndBookButton = ({ price }) => (
  <FormattedMessage
    defaultMessage="Pay & Book"
    description="CTA to pay and finalise the photo shoot booking"
    id="components.CheckoutFormActions.buttonPayBook"
    values={{ price: <FormattedNumber {...getMoneyProps(price)} /> }}
  />
);
export const ErrorMessageTC = () => (
  <FormattedMessage
    defaultMessage="You must accept the terms and conditions."
    description="Error message to show when T&C are not checked."
    id="components.CheckoutFormActions.errorMessageTC"
  />
);
export const TCLabel = () => {
  const intl = useIntl();
  return (
    <Typography whiteSpace="pre-line">
      {intl.formatMessage(
        {
          id: 'components.CheckoutFormActions.labelTC'
        },
        {
          'terms-link': children => (
            <Link target="_blank" href={intl.formatMessage({ id: 'components.CheckoutFormActions.termsLink' })}>
              {children}
            </Link>
          ),
          'privacy-link': children => (
            <Link target="_blank" href={intl.formatMessage({ id: 'components.CheckoutFormActions.privacyLink' })}>
              {children}
            </Link>
          )
        }
      )}
    </Typography>
  );
};
