import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MenuItems from './MenuItems';
import UserButton from './UserButton';

const useStyles = makeStyles({
  iconButton: {
    color: '#fff'
  }
});

/**
 * Dropdown menu with actions for an authenticated user
 *
 * @func UserMenu
 */
const UserMenu = ({ onLogout, user }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handleOpen = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!user) {
    return <></>;
  }

  return (
    <>
      <UserButton user={user} />
      <IconButton className={classes.iconButton} edge="end" aria-label="menu" onClick={handleOpen}>
        <MenuIcon />
      </IconButton>
      <MenuItems anchorEl={anchorEl} onLogout={onLogout} onClose={handleClose} />
    </>
  );
};

export default UserMenu;
