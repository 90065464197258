import React from 'react';

/**
 * Why do we need this function?
 *
 * `date` object we get from the Material UI datepicker looks like this:
 * `Mon Oct 29 2018 00:00:00 GMT+0100 (Central European Standard Time)`
 *
 * When we apply `.toISOString()` to the date an hour is subtracted and we get:
 * `2018-10-28T23:00:00.000Z`
 *
 * Causing the days highlighted in the calendar to be drifted by 1.
 *
 * @link {https://material-ui-pickers.firebaseapp.com/demo/datepicker}
 *
 * @func dateToISOStringTimeZoneAgnostic
 * @param {Date} date
 * @return {String} date string with the format 'YYYY-MM-DD'
 */
export function toDateString(date) {
  const opts = { minimumIntegerDigits: 2 };
  const YYYY = date.getFullYear();
  const MM = (date.getMonth() + 1).toLocaleString('en', opts);
  const DD = date.getDate().toLocaleString('en', opts);
  return `${YYYY}-${MM}-${DD}`;
}

/**
 * Custom renderer (render prop) for day provided by Material-UI pickers
 *
 * Highlight the days where there are available time slots for photo shootings
 *
 * @see {@link https://material-ui-pickers.firebaseapp.com/demo/datepicker}
 * @see {@link https://github.com/dmtrKovalenko/material-ui-pickers/blob/master/lib/src/DatePicker/Calendar.jsx#L208}
 *
 *
 * @func renderDay
 * @param {Set} [days]
 * @return {Function}
 */
export const renderDay = days => (date, selectedDate, dayInCurrentMonth, dayComponent) => {
  const dateString = toDateString(date);
  const disabled = !days.has(dateString);
  return React.cloneElement(dayComponent, {
    'data-e2e': disabled ? undefined : 'dateSelectDayBtn',
    disabled
  });
};

export const today = () => new Date();
