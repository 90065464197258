import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';

// material-ui

import NavIcon from 'components/NavIcon';
import styles from './NavSteps.module.css';

/**
 * Define steps properties
 *
 * @type {Array}
 */

const steps = [
  {
    label: <FormattedMessage id="components.NavSteps.product" />,
    icon: <NavIcon />,
    path: '/'
  },
  {
    label: <FormattedMessage id="components.NavSteps.booking" />,
    icon: <NavIcon />,
    path: '/choose-appointment'
  },
  {
    label: <FormattedMessage id="components.NavSteps.checkout" />,
    icon: <NavIcon />,
    path: '/checkout'
  },
  {
    label: <FormattedMessage id="components.NavSteps.confirmation" />,
    icon: <NavIcon />,
    path: '/confirmation'
  }
];

/**
 * Given a pathname, returns the index of the step in the `steps` Array
 *
 * @method deriveActiveStepFromPath
 * @param {String} pathname
 * @return {Number}
 */
const deriveActiveStepFromPath = pathname => {
  return steps.map(({ path }) => path).indexOf(pathname);
};

/**
 * Steps that represent the booking funnel and is based on URL pathname.
 * Therefore it needs to be aware of URL changes, and it looks very similar
 * to the `<Router />` component
 *
 * >
 * TODO find out if it makes sense to re-arrange `<Router />` in components
 *      hierarchy and get it to pass down through `props` all the relevant
 *      data needed for this component to work properly.
 *
 * @extends React.Component
 */
class NavSteps extends Component {
  // initial state
  state = {
    pathname: window.location.pathname,
    activeStep: deriveActiveStepFromPath(window.location.pathname)
  };

  // Lifecycle methods

  componentDidMount() {
    this.props.history?.listen(({ pathname }) => {
      this.setState({
        activeStep: deriveActiveStepFromPath(pathname),
        pathname
      });
    });
  }

  render() {
    const { activeStep } = this.state;
    return (
      <div className={styles.navContainter}>
        {steps.map(({ label }, indexStep) => {
          let status;

          if (indexStep < activeStep) {
            status = 'CHECKED';
          } else if (indexStep === activeStep) {
            status = 'CURRENT';
          } else {
            status = 'UPCOMING';
          }

          return (
            <div className={styles.navElement} key={indexStep}>
              <NavIcon stageNumber={indexStep} status={status} label={label} />
            </div>
          );
        })}
      </div>
    );
  }
}

export default props => {
  const history = useHistory();
  return <NavSteps {...props} history={history} />;
};
