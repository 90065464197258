import React from 'react';
import { compiler } from 'markdown-to-jsx';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { getMoneyProps } from 'utils/i18n';
import { useIntlScope } from '@lemonone/components';
// components
import Box from 'components/Box';
import Grid from 'components/Grid';
import Text from 'components/Text';
// material-ui
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { withStyles } from '@material-ui/core/styles';
import styles from './PhotoShootSpecCard.css';

/**
 * Display product selection CTA
 * @func SelectProductButton
 * @param {Function} onClick
 * @return {React.Element}
 */
const SelectProductButton = props => {
  return (
    <Grid container justify={'center'}>
      <Grid item>
        <Box padding="0 .2 0">
          <Button
            {...props}
            color="primary"
            data-e2e="productSelectBtn"
            fullWidth
            variant="contained"
            disableElevation
            className="action"
          />
        </Box>
      </Grid>
    </Grid>
  );
};

/**
 * Display photo session details
 *
 * @func PhotoShootSpecCard
 *
 * @param {String} props.name photo shoot specification
 * @param {String} [props.description='']
 * @param {String} props.image URL for the image
 * @param {Object} props.price Money object
 * @param {Number} props.price.amount amount in cents
 * @param {String} props.price.currency currency code
 *
 * @return {React.Element}
 */
const PhotoShootSpecCard = ({ onClick, showPrice, product }) => {
  const intl = useIntlScope('components.ProductSelection.');
  return (
    <span className="wrapper">
      <Card className="card" variant="outlined">
        <Text align="center" data-e2e="productName" variant="h5">
          <div className="productName"> {product.name} </div>
        </Text>
        {product.image && <CardMedia className="media" data-e2e="productPicture" image={product.image} />}
        <CardContent className="cardContentRoot">
          {showPrice && (
            <Box margin="0 0 1" data-e2e="productPrice">
              <Text align="center" variant="h5">
                <FormattedNumber {...getMoneyProps(product.price)} />
              </Text>
            </Box>
          )}
          {product.description && (
            <Box data-e2e="productDescription">
              <div className="markDown">
                <Text align="left" component="div">
                  <span className="markdown">{compiler(product.description.replace(/\\n/g, '\n'))}</span>
                </Text>
              </div>
            </Box>
          )}

          <Text align="center" component="p"></Text>
        </CardContent>
        <Text align="center" component="p">
          <div className="cardTime">
            <div className="cellOne">
              <img
                src="https://public-assets-one.s3.amazonaws.com/icons/clock_grey.svg"
                className="iconTime"
                alt="time"
              />
            </div>
            <div className="cellTwo">
              <FormattedMessage
                id="components.PhotoShootSpecCard.totalDuration"
                defaultMessage="max. {duration} minutes"
                values={{ duration: product.duration }}
              />
            </div>
          </div>
        </Text>

        <div>
          <SelectProductButton onClick={() => onClick(product)}>
            {intl(product.shotByOwner ? 'editingService' : 'buttonSelect')}
          </SelectProductButton>
          <CardActions></CardActions>
        </div>
      </Card>
    </span>
  );
};

export default withStyles(styles)(PhotoShootSpecCard);
