/**
 * For components that depends on 3rd parties, like Google Geolocation
 * Autocomplente or Stripe Checkout, we need to load the library from them.
 *
 * This function take the URI string where the script lives and append a script
 * tag to the body to load the library
 *
 * @func loadScript
 * @param src
 * @return Promise
 */
export function loadScript(src) {
  return new Promise((resolve, reject) => {
    if (!src) reject(new Error('Missing URI for script to load'));
    const scriptTag = document.createElement('script');
    scriptTag.src = src;
    scriptTag.async = true;
    scriptTag.addEventListener('load', e => resolve(e));
    scriptTag.addEventListener('error', error => reject(error));
    document.body.appendChild(scriptTag);
  });
}

/**
 * parse query string from URL into an object
 *
 * @example
 *  queryString('?hello=world&one=1') : { hello: 'world', one: '1' }
 *
 * @method queryString
 * @param {String} query
 * @return {Object}
 */
export const queryString = query => {
  const queryObj = {};
  const pairs = query.substr(1).split('&');
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    queryObj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return queryObj;
};
