import React from 'react';
// material-ui
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  margin: {
    margin: `72px ${theme.spacing(1)}px ${theme.spacing(1)}px`
  },
  // TODO create more variants: success, warning, info, etc.
  //      and set variant `prop` in the `Notification` component.
  //      Then class in `ContentProps={{ className: `${classes.error} ...` }}`
  //      should ve dependant on this `variant` prop.
  error: {
    backgroundColor: theme.palette.error.light
  }
});

const Action = ({ icon, ...buttonProps }) => (
  <IconButton color="inherit" {...buttonProps}>
    {icon}
  </IconButton>
);

/**
 * Wrapper for Material UI `<Snackbar />`
 * @see {@link https://material-ui.com/api/snackbar/}
 *
 * @func Notification
 * @extends Component
 */
const Notification = ({ classes, ...props }) => (
  <Snackbar
    action={[<Action key="Close" icon={<CloseIcon />} onClick={props.onClose} />]}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    ContentProps={{ className: `${classes.error} ${classes.margin}` }}
    TransitionProps={{ direction: 'left' }}
    {...props}
  />
);

export default withStyles(styles)(Notification);
