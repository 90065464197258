import React from 'react';
import { FormattedMessage } from 'react-intl';

export const MenuItemOrders = () => (
  <FormattedMessage defaultMessage="My Bookings" id="components.UserMenu.MenuItem.orders" />
);

export const MenuItemOrganizationBookings = () => (
  <FormattedMessage defaultMessage="Organization Bookings" id="components.UserMenu.MenuItem.organizationBookings" />
);
