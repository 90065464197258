import { Button, Spinner, useIntlScope } from '@lemonone/components';
import React from 'react';

import classes from './RescheduleShootForm.module.css';

const RescheduleShootForm = ({ action, disabled, setAction }) => {
  const intl = useIntlScope('@lemonone/bookingui/components/RescheduleShoot:');
  return (
    <div className={classes.wrapper}>
      <Button
        disabled={disabled}
        processing={disabled && action === 'cancel'}
        type="submit"
        color="secondary"
        variant="text"
        data-e2e="cancelButton"
        onClick={() => setAction('cancel')}
      >
        {action === 'cancel' && disabled && <Spinner variant="secondary" />}
        {intl('cancel')}
      </Button>
      <Button
        disabled={disabled}
        processing={disabled && action === 'reschedule'}
        type="submit"
        data-e2e="rescheduleButton"
        onClick={() => setAction('reschedule')}
      >
        {action === 'reschedule' && disabled && <Spinner variant="primary" inverted />}
        {intl('reschedule')}
      </Button>
    </div>
  );
};

export default RescheduleShootForm;
