/* eslint-disable quotes, prefer-object-spread */

const intl = require('@lemonone/intl/lib/de');
const components = require('@lemonone/components/lib/i18n/de');

const bookingui = require('./en');

module.exports = Object.assign({}, bookingui, intl, components, {
  'components.CheckoutFormActions.buttonBook': 'Buchen',
  'components.CheckoutFormActions.termsLink': 'https://www.lemonone.com/de/allgemeine-geschaeftsbedingungen',
  'components.CheckoutFormActions.privacyLink': 'https://www.lemonone.com/de/vatenschutzerklarung',
  'components.CheckoutFormActions.buttonPayBook': 'Bezahlen {price} & Buchen',
  'components.CheckoutFormActions.labelTC':
    'Mit Ihrer Bestellung erklären Sie sich mit unseren <terms-link>Dienstleistungsbedingungen</terms-link> einverstanden.\nBitte lesen Sie unsere <privacy-link>Datenschutzerklärung</privacy-link>.',

  'components.CheckoutFormConsent.label':
    'Hiermit bestätige ich, dass ich über die Berechtigung verfüge, die angegbenen Kontaktdaten an LemonOne GmbH weiterzugeben um die Erfüllung der vertraglich festgelegten Pflichten zu gewährleisten',

  'components.CheckoutFormHost.title': 'Gastgeber Details',
  'components.CheckoutFormHost.description': 'Wer ist die Kontaktperson vor Ort?',
  'components.CheckoutFormHost.Labels.name': 'Name',
  'components.CheckoutFormHost.Labels.nameError': 'Bitte geben Sie den Namen der Kontaktperson ein',
  'components.CheckoutFormHost.Labels.email': 'E-Mail',
  'components.CheckoutFormHost.Labels.emailError': 'Bitte geben Sie eine gültige E-Mail Addresse ein',
  'components.CheckoutFormHost.Labels.phone': 'Telefon',
  'components.CheckoutFormHost.Labels.phoneError': 'Bitte geben Sie eine gültige Telefonnummer ein',
  'components.CheckoutFormHost.Labels.externalIdError': 'Bitte geben Sie eine Referenz-ID ein',
  'components.CheckoutFormHost.Labels.externalIdTooLongError': 'Referenz-ID darf 72 Zeichen nicht überschreiten',

  'components.CheckoutFormLocation.title': 'Standort Details',
  'components.CheckoutFormLocation.Labels.locationName': 'Ortsname (z.B. Name des Geschäfts)',
  'components.CheckoutFormLocation.Labels.externalId': 'Ihre interne Referenz',

  'components.CheckoutFormNotes.attachmentButton': 'Klicken Sie hier um die hochzuladende Datei auszuwählen.',
  'components.CheckoutFormNotes.attachmentDescription': 'Oder laden Sie ein für das Shooting relevantes Dokument hoch.',
  'components.CheckoutFormNotes.title': 'Zusätzliche Informationen, die wir wissen müssen (Zugang zum Gebäude usw.)',
  'components.CheckoutFormNotes.label': 'Nachricht',
  'components.CheckoutFormNotes.description':
    'Teilen Sie uns hier zusätzliche Informationen mit, die wir bisher noch nicht erfragt haben.',

  'components.Confirmation.buttonBook': 'Erneut Buchen',

  'components.MyBookings.Header.title': 'Buchungen',
  'components.CreateBookingButton.tooltip': 'Jetzt Buchen',
  'components.NoBookings.EmptyButton': 'Jetzt Buchen',
  'components.NoBookings.EmptyText': 'Sie haben noch kein Fotoshooting gebucht.',

  'components.NavSteps.product': 'Produktauswahl',
  'components.NavSteps.booking': 'Datum und Uhrzeit',
  'components.NavSteps.confirmation': 'Buchungsbestätigung',
  'components.NavSteps.checkout': 'Termin',

  'components.OrderDetails.title': 'Ihre Fotoshooting Informationen',

  'components.OrderTable.id': 'ID',
  'components.OrderTable.referenceId': 'Referenz ID',
  'components.OrderTable.creator': 'Gebucht von',
  'components.OrderTable.createdAt': 'Gebucht am',
  'components.OrderTable.date': 'Datum',
  'components.OrderTable.address': 'Addresse',
  'components.OrderTable.status': 'Status',
  'components.OrderTable.product': 'Produkt',
  'components.OrderTable.downloadUrl': 'Fotos herunterladen',
  'components.OrderTable.seeAllInfo': 'Alle Informationen ansehen',
  'components.OrderTable.rescheduleUrl': 'Verschieben',
  'components.OrderTable.deliveredAt': 'Lieferdatum & Uhrzeit',

  'components.OrderTable.downloadCsv': 'CSV-Datei herunterladen',

  'components.OrderTable.status:scheduled': 'geplant',
  'components.OrderTable.status:cancelled': 'storniert',
  'components.OrderTable.status:fulfilled': 'erfüllt',
  'components.OrderTable.status:rescheduling': 'umplanen',
  'components.OrderTable.status:processing': 'wird bearbeitet',

  'components.PhotoShootEventDetails.addressLabel': 'Adresse',
  'components.PhotoShootEventDetails.dateLabel': 'Termin',
  'components.ShootDuration.totalDuration': '{duration} Minuten max.',

  'components.PhotoShootSpecCard.totalDuration': '{duration} Minuten max.',

  'components.PhotoShootSpecDetails.duration': 'Minuten max.',
  'components.PhotoShootSpecDetails.durationLabel': 'Max. Dauer',
  'components.PhotoShootSpecDetails.nameLabel': 'Produkt',

  'components.ShootRequest.confirmation':
    'Wir haben Ihre Daten erhalten und melden uns, sobald ein Fotograf in Ihrer Gegend verfügbar ist.',
  'components.ShootRequest.details':
    'Bitte geben Sie Ihre Kontaktinformationen an und wir werden uns schnellstmöglich bei Ihnen melden, sobald einer unserer Fotografen wieder verfügbar ist.',
  'components.ShootRequest.team': 'Team LemonOne',
  'components.ShootRequest.titleAfter': 'Vielen Dank für Ihre Anfrage!',
  'components.ShootRequest.titleBefore': 'Aktuell sind alle Fotografen in Ihrer Umgebung beschäftigt.',

  'components.ShootRequestForm.action': 'Fotoshooting Anfragen',
  'components.ShootRequestForm.message': 'Sagen Sie uns, was sie brauchen!',

  'components.ProductSelection.buttonSelect': 'Auswählen',
  'components.ProductSelection.editingService': 'Bearbeitung anfordern',

  'components.UserMenu.MenuItem.orders': 'Meine Buchungen',
  'components.UserMenu.MenuItem.organizationBookings': 'Alle Buchungen',

  'containers.Confirmation.confirmed': 'Ihr Fototermin wurde erfolgreich gebucht!',
  'containers.Confirmation.processing': 'Wir bearbeiten Ihre Buchung...',

  'components.CheckoutFormBillingAddress.title': 'Rechnungsdetails',
  'components.CheckoutFormBillingAddress.billingAddress': '{name}, {street}, {postalCode} {city}, {countryCode}',
  'components.CheckoutFormBillingAddress.notFound':
    'Sie sehen Ihre Rechnungsadresse hier nicht? Bitte wenden Sie sich an Ihren Kundenbetreuer.',
  'components.SelectDayTime.start': 'Anfangszeit',

  'errors.Address Error: invalid': 'Bitte geben Sie die vollständige Adresse inkl. Hausnummer ein.',
  'errors.Address Error: Not Found': 'Adresse Nicht gefunden',
  'errors.Address Error: COVID-19 Quarantine':
    'Um unsere Partner vor COVID-19 zu schützen, ist es derzeit leider nicht möglich in dem gewählten Gebiet Termine zu buchen. Bitte wenden Sie sich bei Fragen an: hello@lemonone.com / +4930120872870',
  'errors.Error: Order not found': 'Der Termin konnte nicht gefunden werden',
  'errors.GraphQL error: Order not found': 'Der Termin konnte nicht gefunden werden',
  'errors.GraphQL error: Order already cancelled': 'Dieser Termin wurde bereits storniert.',
  'errors.GraphQL error: Shoot has already been cancelled': 'Dieser Termin wurde bereits storniert.',
  'errors.Error: Late cancellation attempt': 'Die Stornierungsfrist ist überschritten.',
  'errors.Error: ': 'Fehlende Buchungsinformationen.',
  "errors.GraphQL error: Product doesn't exist!":
    'Damit wir Ihre Anfrage bearbeiten können, benötigen wir weitere Informationen. Bitte kontaktieren Sie uns, damit wir Ihr individuelles Produkt erstellen können.',
  'errors.Error: User email not verified.': 'Bitte verifizieren Sie Ihre E-Mail-Adresse.',
  'errors.GraphQL error: Host email is invalid: "value" must be a valid email':
    'Bitte geben Sie eine gültige E-Mail Addresse an',
  'errors.GraphQL error: Shoot lies in the past':
    'Das Fotoshooting, das Sie versuchen zu stornieren, liegt in der Vergangenheit.',
  'errors.Failed to authenticate':
    'Die Authentifizierung ist fehlgeschlagen. Bitte versuchen Sie es erneut oder kontaktieren Sie uns, wenn das Problem weiterhin besteht.',
  'errors.GraphQL error: Selected date and time not available.':
    'Der von Ihnen gewählte Termin ist nicht mehr verfügbar. Bitte wählen Sie eine andere Zeit aus.',
  'errors.GraphQL error: User email not verified': 'Bitte verifizieren Sie Ihre Email um die Buchung abzuschliessen.',
  'errors.Invalid authentication nonce.':
    'Die Authentifizierung ist fehlgeschlagen. Bitte versuchen Sie es erneut oder kontaktieren Sie uns, wenn das Problem weiterhin besteht.',
  'errors.Missing required fields': 'Bitte tragen Sie alle Pflichtfelder ein',
  'errors.Network Error':
    'Das Netzwerk reagiert nicht. Bitte versuchen Sie es später noch einmal oder kontaktieren Sie unseren Support.',
  'errors.OrderDetailsFacade.missing_required_props':
    'Es fehlen uns leider einige Daten um Ihnen akkurate Informationen bezüglich ihres Fotoshootings anzuzeigen. Bitte versuchen Sie es erneut und kontaktieren Sie uns, falls der Fehler weiterhin auftritt.',
  'errors.OrderDetailsFacade.not_found': 'Der Termin konnte nicht gefunden werden',
  'errors.PhotoShootEventDetails.missing_data':
    'Es fehlen uns leider einige Daten um Ihnen akkurate Informationen bezüglich Ihres Fotoshootings anzuzeigen. Bitte versuchen Sie es erneut und kontaktieren Sie uns, falls der Fehler weiterhin auftritt.',
  'errors.PhotoShootSpecDetails.missing_data':
    'Es fehlen uns leider einige Daten um Ihnen akkurate Informationen bezüglich ihres Fotoshootings anzuzeigen. Bitte versuchen Sie es erneut und kontaktieren Sie uns, falls der Fehler weiterhin auftritt.',
  'errors.Unexpected Error':
    'Ihre Anfrage konnte nicht bearbeitet werden. Bitte versuchen Sie es später noch einmal oder kontaktieren Sie unseren Support.',
  'errors.User did not authorize the request': 'Bitte autorisieren Sie die Anfrage, um die Anmeldung abzuschließen.',
  'errors.GraphQL error: Expected prices to be set for products of non-prepaid organization':
    'Die Preise der Produkte sind noch nicht festgelegt worden. Bitte kontaktieren Sie uns, um fortzufahren.',
  'errors.GraphQL error: Cancellation for this shoot is not possible anymore':
    'Eine Stornierung oder Verschiebung dieses Fotoshootings ist leider nicht mehr möglich. Bitte wenden Sie sich für Details an Ihren Account Manager.',

  'errors.GraphQL error: TimeSlotExpired: booking is not possible on the selected date & time':
    'Das ausgewählte Zeitfenster ist nicht mehr verfügbar, bitte erneut auswählen',
  'errors.GraphQL error: TimeSlotExpired: booking date must be in the future':
    'Das ausgewählte Zeitfenster ist nicht mehr verfügbar, bitte erneut auswählen',
  'errors.GraphQL error: TimeSlotExpired: booking is not possible less than 36 hours in advance':
    'Das ausgewählte Zeitfenster ist nicht mehr verfügbar, bitte erneut auswählen',
  'errors.GraphQL error: TimeSlotExpired: booking is not possible less than 72 hours in advance':
    'Das ausgewählte Zeitfenster ist nicht mehr verfügbar, bitte erneut auswählen',
  'errors.GraphQL error: TimeSlotExpired: booking is only possible between 8-20 hours':
    'Das ausgewählte Zeitfenster ist nicht mehr verfügbar, bitte erneut auswählen',
  'errors.GraphQL error: TimeSlotExpired: booking is not possible on Sunday':
    'Das ausgewählte Zeitfenster ist nicht mehr verfügbar, bitte erneut auswählen',
  'errors.GraphQL error: TimeSlotExpired: booking is only possible at HH:00, HH:30':
    'Das ausgewählte Zeitfenster ist nicht mehr verfügbar, bitte erneut auswählen',

  'module.authentication.copyright': '© {year} LemonOne GmbH',
  'module.authentication.cta': 'Registrieren / Einloggen',
  'module.authentication.headline': 'Fotos, die Ihre Kunden lieben werden.',
  'module.authentication.imprint': 'Impressum',
  'module.authentication.navLoginButton': 'Registrieren / Einloggen',
  'module.authentication.privacy': 'AGB',
  'module.authentication.subtitle': 'Professionelle Aufnahmen mit nur einem Klick',
  'module.authentication.DialogAlert.title': 'Sie müssen Ihre E-Mail bestätigen',
  'module.authentication.DialogAlert.description':
    'Bitte überprüfen Sie Ihre E-Mail-Adresse, indem Sie auf den Verifizierungslink in der E-Mail klicken.',
  'module.authentication.DialogAlert.buttonAccept': 'Ich habe meine E-Mail bestätigt.',
  'module.authentication.DialogAlert.buttonCancel': 'Ausloggen',

  'Company Name': 'Firma',
  Name: 'Vor- und Nachname',
  'Contact us': 'Kontaktieren Sie uns',
  Email: 'E-Mail',
  'Billing Address': 'Rechnungsadresse',
  City: 'Stadt',
  Zipcode: 'Postleitzahl',
  Phone: 'Telefon',
  'This field is required.': 'Dieses Feld ist erforderlich.',
  'You must accept the terms and conditions.': 'Sie müssen die Allgemeinen Geschäftsbedingungen akzeptieren.',
  'Your Booking': 'Ihre Buchung',
  'Book More': 'Erneut Buchen',
  'Sorry, there are no appointments for the selected date.':
    'Für diesen Tag stehen leider keine Termine zur Verfügung.',
  'Recommended time': 'Empfohlener Termin',
  'We are processing your order...': 'Wir bearbeiten Ihre Bestellung...',
  Select: 'Wählen',
  'Tell us where you need the photo shoot': 'Wo soll das Fotoshooting stattfinden:',
  'Pick a date that works for you': 'Bitte wählen Sie Ihren Wunschtermin aus:',
  'Enter your contact data so we can get in touch with you.':
    'Bitte geben Sie hier Ihre Kontakt- und Rechnungsinformationen an und buchen Sie Ihren Fototermin.',
  'All fields with * are mandatory.': '*Pflichtfelder',
  'Please fill in your contact details and propose three dates and times that work for you, and we will inform you as soon as we found a photographer.':
    'Bitte geben Sie Ihre Kontaktdaten an und schlagen Sie drei Termine vor, die für Sie in Frage kämen. Wir informieren Sie, sobald wir einen Fotografen gefunden haben',
  'Please use your company email.': 'Bitte nutzen Sie Ihre geschäftliche E-Mail.',
  'We are very sorry that you have decided to cancel your booking. Please be aware that it is only possible to cancel bookings without charge up to 48 hours before the photo shoot. Also note that some time slots may not be available for re-booking any more.':
    'Bitte beachten Sie, dass eine kostenfreie Stornierung höchstens 48 Stunden vor dem Fototermin möglich ist. Es kann außerdem sein, dass Einzelne freie Termine aus Ihrer ersten Buchung nicht mehr verfügbar sind.',
  'Cancel Photo Shoot': 'Stornieren',
  'Reasons for Cancellation': 'Grund der Stornierung',
  'Your photo shoot appointment has been cancelled': 'Ihr Fotoshooting wurde erfolgreich storniert:',
  'We can find a solution for you!': 'Wir helfen gerne.',
  'Pay & Book': 'Kostenpflichtig Buchen',
  'We can not complete your request. Please contact customer support':
    'Bei der Verarbeitung Ihrer Anfrage ist ein Fehler aufgetreten. Bitte kontaktieren Sie den Support.',
  'plus VAT': 'zzgl. gesetzlicher USt.',
  'Your contact details': 'Ihre Kontaktinformationen',
  Continue: 'Planen',
  'Billing details': 'Rechnungsdaten',
  'Look for available time slots in my location': 'Suchen Sie nach freien Terminen an meinem Standort.',
  'Clear the Searchfield': 'Löschen des Suchfeldes',

  'page.organization.bookings.apply': 'Anwenden',
  'page.organization.bookings.from': 'Fotoshootings von',
  'page.organization.bookings.title': 'Alle Buchungen',
  'page.organization.bookings.until': 'Fotoshootings bis',
  'page.organization.bookings.search': 'Suche',

  '@lemonone/bookingui/components/OrderDetails:title': 'Details zu Ihrem Fotoshooting:',
  '@lemonone/bookingui/components/RescheduleShoot:title': 'Verschiebung und Stornierung',
  '@lemonone/bookingui/components/RescheduleShoot:calendar':
    'Um den Termin für das Fotoshooting zu verschieben, wählen Sie bitte ein neues Datum und eine neue Uhrzeit:',
  '@lemonone/bookingui/components/RescheduleShoot:reason':
    'Bitte geben Sie den Grund für die Verschiebung/Stornierung an:',
  '@lemonone/bookingui/components/RescheduleShoot:consent':
    '<bold>Ich bin mir bewusst, dass Stornierungs- und Ver­schie­bungen anfallen können.</bold>\nFür weitere Informationen zu den Gebühren wenden Sie sich an Ihren Kundenbetreuer.',
  '@lemonone/bookingui/components/RescheduleShoot:reschedule': 'Terminverschiebung des Fotoshootings',
  '@lemonone/bookingui/components/RescheduleShoot:cancel': 'Fotoshooting abbrechen',
  '@lemonone/bookingui/components/RescheduleShoot:not_available':
    'Für das gewählte Datum gibt es leider keine Termine.',
  '@lemonone/bookingui/noProducts':
    'Zurzeit sind keine Produkte verfügbar, die Sie buchen können.\n\nBitte wenden Sie sich an Ihren <bold>Kundenbetreuer</bold>, um Hilfe zu erhalten.',
  '@lemonone/bookingui/uploadAttachments': 'Anhänge hinzufügen',
  '@lemonone/bookingui/users': 'Benutzer'
});
