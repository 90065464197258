import React from 'react';
// components
import Brand from 'components/Brand';
// material-ui
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { useAuth0 } from '@auth0/auth0-react';
import UserMenu from '../UserMenu';

const styles = {
  '@global': { body: { 'padding-top': '4rem' } },
  appBar: {
    background: '#000',
    color: '#fff'
  },
  expand: { marginLeft: 'auto' }
};

/**
 * Top Navigation bar.
 *
 * @func NavBar
 */
const NavBar = ({ classes, position }) => {
  const { user, isAuthenticated, logout } = useAuth0();
  if (!isAuthenticated) {
    return null;
  }
  return (
    <AppBar className={classes.appBar} position={position || 'fixed'} elevation={0}>
      <Toolbar>
        <Brand />
        <div className={classes.expand}>
          <UserMenu onLogout={() => logout({ returnTo: window.location.origin })} user={user} />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(NavBar);
