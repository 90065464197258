import React from 'react';
import { useHistory } from 'react-router-dom';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CameraRollIcon from '@material-ui/icons/CameraRoll';
import UserIcon from '@material-ui/icons/SupervisedUserCircle';
import { useSettings } from '@lemonone/components';
import { ACCESS_RESTRICTIONS, ROUTES } from '../../constants';
import { MenuItemOrders, MenuItemOrganizationBookings } from './i18n';

const UserMenuItems = ({ anchorEl, onClose, onLogout }) => {
  const history = useHistory();
  const settings = useSettings();
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      getContentAnchorEl={null}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={!!anchorEl}
      onClose={onClose}
    >
      <MenuItem onClick={() => history.push(ROUTES.BOOKINGS)}>
        <ListItemIcon>
          <CameraRollIcon />
        </ListItemIcon>
        <ListItemText>
          <MenuItemOrders />
        </ListItemText>
      </MenuItem>

      {settings?.claims?.roles.includes(ACCESS_RESTRICTIONS.ROLES.CLIENT_ADMIN) && (
        <MenuItem onClick={() => history.push(ROUTES.BOOKINGS_ALL)}>
          <ListItemIcon>
            <CameraRollIcon />
          </ListItemIcon>
          <ListItemText>
            <MenuItemOrganizationBookings />
          </ListItemText>
        </MenuItem>
      )}

      {settings?.claims?.roles.includes(ACCESS_RESTRICTIONS.ROLES.CLIENT_ADMIN) && (
        <MenuItem onClick={() => history.push(ROUTES.USERS)}>
          <ListItemIcon>
            <UserIcon />
          </ListItemIcon>
          <ListItemText>Users</ListItemText>
        </MenuItem>
      )}

      <MenuItem onClick={onLogout}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText>Logout</ListItemText>
      </MenuItem>
    </Menu>
  );
};

export default UserMenuItems;
