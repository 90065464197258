import React from 'react';
// components
import Text from 'components/Text';
import { Row } from '@lemonone/components';
// material-ui
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import { Label } from './i18n';

const styles = {
  alignTop: { alignItems: 'flex-start' },
  checker: { padding: '0 0px', marginRight: '10px', marginLeft: '10px', marginTop: '3px' }
};

const FormattedLabel = () => (
  <Text variant="body1">
    <Label />
  </Text>
);

const CheckoutFormConsent = ({ actions, values, classes }) => {
  return (
    <Row>
      <FormControlLabel
        control={
          <Checkbox
            name="consent"
            onChange={actions.onChange}
            value={values.consent || false}
            color="primary"
            inputProps={{ 'aria-label': 'Consent' }}
            required
            data-e2e="checkoutFormConsentCheckbox"
            variant="contained"
            className={classes.checker}
          />
        }
        className={classes.alignTop}
        label={<FormattedLabel />}
      />
    </Row>
  );
};

export default withStyles(styles)(CheckoutFormConsent);
