import { Row } from '@lemonone/components';
import { Button, Paper } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

import OrderDetails from '../OrderDetails';
import classes from './Confirmation.module.css';
import { ButtonBook } from './i18n';

const Confirmation = ({ order }) => {
  return (
    <Paper className={classes.root}>
      <Row>
        <OrderDetails
          orderInfo={{
            productUuid: order.product.uuid,
            productName: order.product.name,
            shootDateTime: order.shoot.start,
            shootAddress: order.shoot.location.address,
            shootTimeZone: order.shoot.location.timeZoneId,
            shootDurationMins: order.product.duration
          }}
        />
      </Row>
      <Row>
        <Link component={Button} type="button" color="primary" to="/" variant="contained">
          <ButtonBook />
        </Link>
      </Row>
    </Paper>
  );
};

export default Confirmation;
