import React from 'react';
import { FormattedMessage } from 'react-intl';
// Utils
import logger from 'utils/logger';
// components
import Grid from 'components/Grid';
import Form from 'components/Form';
// material-ui
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const handleSubmit = (address, product, mutation, onReceiveConfirmation, onError) => async formData => {
  try {
    const response = await mutation({
      variables: {
        shootRequest: {
          address,
          productId: product.uuid,
          message: formData.message
        }
      }
    });
    if (response.data && response.data.photoShootRequest && response.data.photoShootRequest.status) {
      onReceiveConfirmation(response.data.photoShootRequest);
    }
  } catch (err) {
    logger('error', err);
    onError(err);
  }
};

const ShootRequestForm = ({ address, mutation, product, onReceiveConfirmation, onError }) => {
  return (
    <Form onSubmit={handleSubmit(address, product, mutation, onReceiveConfirmation, onError)}>
      {(values, { onBlur, onChange }, { errors, touched }) => (
        <>
          <TextField
            error={touched.message && errors.message}
            fullWidth
            multiline
            id="message"
            name="message"
            rows="3"
            label={
              <FormattedMessage
                defaultMessage="Please tell us what you need!"
                id="components.ShootRequestForm.message"
              />
            }
            margin="normal"
            onChange={onChange}
            onBlur={onBlur}
            value={values.message || ''}
          />
          <Grid container justify="flex-end">
            <Button color="primary" variant="contained" type="submit">
              <FormattedMessage defaultMessage="Request photo shoot" id="components.ShootRequestForm.action" />
            </Button>
          </Grid>
        </>
      )}
    </Form>
  );
};

export default ShootRequestForm;
