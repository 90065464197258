import React from 'react';
// material-ui
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import OutlinedInputInput from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: 2,
    maxWidth: '800px',
    height: '50px',
    margin: 'auto'
  },
  left: { margin: 'auto 0 auto 1rem' },
  right: { '&:hover': { backgroundColor: 'transparent' } }
};

const SearchBar = ({ onClick, onReset, classes, arialabelsearch, arialabelclose, ...props }) => (
  <Paper className={classes.container} elevation={0} variant="outlined">
    <OutlinedInputInput inputProps={props} className={classes.left} disableUnderline fullWidth />
    {!props.value ? (
      <IconButton onClick={onClick} className={classes.right} aria-label={arialabelsearch}>
        <SearchIcon />
      </IconButton>
    ) : (
      <IconButton onClick={onReset} className={classes.right} aria-label={arialabelclose}>
        <CloseIcon />
      </IconButton>
    )}
  </Paper>
);

export default withStyles(styles)(SearchBar);
