import Drawer, { DrawerProps } from '@material-ui/core/Drawer';
import React from 'react';

import { OrderInfoSidebarContent, OrderInfoSidebarContentProps } from '../OrderInfoSidebarContent';

export type OrderInfoSidebarProps = Pick<DrawerProps, 'anchor' | 'open' | 'onClose'> & OrderInfoSidebarContentProps;

export const OrderInfoSidebar: React.FC<OrderInfoSidebarProps> = ({ order, ...drawerProps }) => {
  return (
    <Drawer {...drawerProps} anchor="right">
      {order && <OrderInfoSidebarContent order={order} />}
    </Drawer>
  );
};
