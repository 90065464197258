import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Title = () => (
  <FormattedMessage
    defaultMessage="Location details"
    description="Title for the location details form"
    id="components.CheckoutFormLocation.title"
  />
);
export const LocationNameLabel = () => (
  <FormattedMessage
    defaultMessage="Place Name"
    description="Precise name of the location where the shoot will take place"
    id="components.CheckoutFormLocation.Labels.locationName"
  />
);
export const LocationExternalIdLabel = () => (
  <FormattedMessage
    defaultMessage="Your internal reference"
    description="Identifier for the user to track back the booking within their systems"
    id="components.CheckoutFormLocation.Labels.externalId"
  />
);
