import gql from 'graphql-tag';
import React from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useLocation } from 'react-router';

import Loading from '../components/Loading';
import RescheduleShoot from '../components/RescheduleShoot';
import NotFound from './NotFound';

const getOrderQuery = gql`
  query($uuid: String!) {
    getOrder(uuid: $uuid) {
      orderUuid
      productUuid
      productName
      orderStatus
      shootDateTime
      shootAddress
      shootTimeZone
      shootDurationMins
    }
  }
`;

const rescheduleShootMutation = gql`
  mutation($orderId: String!, $cancellationNotes: String, $start: String!) {
    rescheduleShootForOrder(orderId: $orderId, cancellationNotes: $cancellationNotes, start: $start) {
      orderUuid
      productUuid
      productName
      orderStatus
      shootDateTime
      shootAddress
      shootTimeZone
      shootDurationMins
    }
  }
`;

const cancelShootMutation = gql`
  mutation cancelOrder($orderId: String!, $cancellationNotes: String) {
    cancelOrder(orderId: $orderId, cancellationNotes: $cancellationNotes) {
      orderUuid
      productUuid
      productName
      orderStatus
      shootDateTime
      shootAddress
      shootTimeZone
      shootDurationMins
    }
  }
`;

const Rescheduling = () => {
  const location = useLocation();
  const url = new URLSearchParams(location.search);
  const uuid = url.get('id');

  const [cancelShoot] = useMutation(cancelShootMutation);
  const [rescheduleShoot] = useMutation(rescheduleShootMutation);

  const getOrderResult = useQuery(getOrderQuery, { variables: { uuid } });
  const orderInfo = getOrderResult.data?.getOrder;

  if (getOrderResult.loading) return <Loading />;
  if (getOrderResult.error) return <>{getOrderResult.error.message}</>;
  if (!orderInfo) return <NotFound />;

  return <RescheduleShoot orderInfo={orderInfo} rescheduleShoot={rescheduleShoot} cancelShoot={cancelShoot} />;
};

export default Rescheduling;
