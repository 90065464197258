import React from 'react';
import logger from 'utils/logger';
import { FormattedMessage } from 'react-intl';
// components
import Text from 'components/Text';

/**
 *
 * @param {*} param0
 * @returns
 */
const ErrorComponent = ({ message, color, context }) => {
  logger('error', new Error(message), context);

  return (
    <Text align="center" color={color}>
      <FormattedMessage
        description="Show a contextual error message."
        defaultMessage="Oops! something unexpected happened. Please try again and contact us if the issue persists."
        id={`errors.${message}`}
      />
    </Text>
  );
};

export default ErrorComponent;
