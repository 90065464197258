import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Label = () => (
  <FormattedMessage
    defaultMessage="I do confirm, that I am authorized to pass the provided contact details to LemonOne GmbH for a reason of fulfilling contract obligations."
    description="Consent of customer to sent notifications on their behalf"
    id="components.CheckoutFormConsent.label"
  />
);
