import React from 'react';
import logo from 'assets/logotype-primary.svg';
// components
import { Link } from 'react-router-dom';
// material-ui
import { withStyles } from '@material-ui/core/styles';

const styles = {
  logo: {
    display: 'block',
    margin: '0.8rem 0',
    height: '40px'
  }
};

const Brand = ({ classes, href = '/' }) => (
  <Link to={href}>
    <img src={logo} className={classes.logo} alt="LemonOne" />
  </Link>
);

export default withStyles(styles)(Brand);
