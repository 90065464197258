import { loadScript } from 'utils';

const { REACT_APP_BOOKINGUI_STRIPE_KEY, REACT_APP_BOOKINGUI_LOGO_PNG } = process.env;

const initialiseStripe = async receiveTokenCallback => {
  if (!window.StripeCheckout) {
    await loadScript('https://checkout.stripe.com/checkout.js');
  }
  return window.StripeCheckout.configure({
    key: REACT_APP_BOOKINGUI_STRIPE_KEY,
    image: REACT_APP_BOOKINGUI_LOGO_PNG,
    locale: 'auto',
    token: receiveTokenCallback
  });
};

const handleSubmitCheckoutForm = ({
  createOrder,
  intl,
  onNotification,
  onPayment,
  onSubmit,
  data: { isPrepaid },
  user,
  history
}) => async formData => {
  if (isPrepaid) {
    await onSubmit(formData, createOrder, onNotification, intl, undefined, history);
  } else {
    const stripeInstance = await initialiseStripe(token =>
      onSubmit(formData, createOrder, onNotification, intl, token, history)
    );
    await onPayment(stripeInstance.open, user);
  }
};

export default handleSubmitCheckoutForm;
