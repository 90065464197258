import { queryString } from 'utils';
import { identify } from 'utils/analytics';

const AFFILIATE_USER_ID_KEY = 'leonAuid';
const CUSTOMER_ID_KEY = 'leonCid';
const DEFAULT_SHOOT_SPEC_KEY = 'leon.defaultShootSpec_TMP_1';
const DEFAULT_SHOOT_SPEC_QS = 'shootSpec';
const SESSION_KEY = 'leon.session';

/**
 * Cached data in different browser persistant sources:
 * - localStorage
 * - sessionStorage
 * - cookies
 * - IndexedDB
 *
 * @func cachedState
 * @return {Object}
 */
export const cachedState = () => {
  /**
   * Data from the URL query string
   * @type {Object}
   */
  const QS = queryString(window.location.search);

  /**
   * `customerId` coming from:
   *
   * - URL query string
   * - browser (long-lived) cache: `localStorage`
   * - environment variable injected on build time
   *
   * @type {String}
   */
  const customerId =
    QS.affiliateId || sessionStorage.getItem(CUSTOMER_ID_KEY) || process.env.REACT_APP_BOOKINGUI_CUSTOMER_ID;

  /**
   * `defaultShootSpec` coming from:
   *
   * - URL query string
   * - browser (long-lived) cache: `localStorage`
   *
   * @type {String}
   */
  const defaultShootSpec = QS[DEFAULT_SHOOT_SPEC_QS] || sessionStorage.getItem(DEFAULT_SHOOT_SPEC_KEY);

  /**
   * @typedef AdditionalData
   * @type {Object}
   * @property {String} affiliateUserId
   */
  const additionalData = {
    /**
     * [affiliateUserId description]
     * @type {String}
     */
    affiliateUserId: QS.affiliateUserId || sessionStorage.getItem(AFFILIATE_USER_ID_KEY)
  };

  // send user data to analytics
  identify({
    affiliateUserId: additionalData.affiliateUserId,
    customerId,
    defaultShootSpec
  });

  /**
   * restore browser (short-lived) cache
   * @type {Object}
   */
  const stateFromCache = sessionStorage.getItem(SESSION_KEY) ? JSON.parse(sessionStorage.getItem(SESSION_KEY)) : {};

  return { ...stateFromCache, additionalData, customerId, defaultShootSpec };
};

/**
 * listen to changes in the store and cache the state in the browser
 * @func cacheState
 * @param {Object} state application state
 */
export const cacheState = state => {
  const { additionalData, customerId, defaultShootSpec, ...session } = state;
  // short-lived cache: app state
  sessionStorage.setItem(SESSION_KEY, JSON.stringify(session));
  // long-lived cache: only customerId and defaultShootSpec
  if (customerId) sessionStorage.setItem(CUSTOMER_ID_KEY, customerId);
  if (defaultShootSpec) sessionStorage.setItem(DEFAULT_SHOOT_SPEC_KEY, defaultShootSpec);
  if (additionalData.affiliateUserId) sessionStorage.setItem(AFFILIATE_USER_ID_KEY, additionalData.affiliateUserId);
};
