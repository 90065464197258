import Box from 'components/Box';
import Text from 'components/Text';
import React from 'react';

import classes from './PageHeading.module.css';

/**
 *
 * @param {*} param0
 * @returns
 */
const PageHeading = ({ title, children }) => (
  <Box margin="0 0 24 0" unit="px">
    <div className={classes.root}>
      <div className={classes.item}>
        <Text date-e2e="pageHeadingTitle" variant="h5">
          {title}
        </Text>
      </div>
      {React.Children.map(children, child => (
        <div className={classes.item}>{child}</div>
      ))}
    </div>
  </Box>
);

export default PageHeading;
