import { useIntlScope, useSearchParam } from '@lemonone/components';
import { makeStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import orderBy from 'lodash/orderBy';
import React, { useEffect, useState } from 'react';
import track from 'utils/analytics';
import Checkbox from '@material-ui/core/Checkbox';

import OrderTableRow from './OrderTableRow';
import { SortableCell } from './SortableCell';

const useStyles = makeStyles(() => ({
  table: {
    '& .MuiTableCell-head': {
      padding: 6,
      fontWeight: 'bold',
      whiteSpace: 'nowrap'
    },
    '& .MuiTableCell-body': {
      padding: 6,
      borderBottom: 'none'
    }
  }
}));

const isNumber = /^\d+$/;

const OrderTable = ({ orders, onOrderPreview }) => {
  const styles = useStyles();
  const intl = useIntlScope('components.OrderTable.');
  const common = useIntlScope('@lemonone/intl/text:');
  const defaultSearchParams = { page: 0, perPage: 10 };
  const [searchParams, setSearchParams] = useSearchParam(defaultSearchParams);

  const [notesVisibility, setNotesVisibility] = useState(() => {
    return localStorage.getItem('notesVisibility') === 'true';
  });

  const page = Number.parseInt(searchParams.get('page'), 10);
  const perPage = Number.parseInt(searchParams.get('perPage'), 10);

  const sortBy = searchParams.get('sortBy') ?? 'shoot.start';
  const sortDir = searchParams.get('sortDir') ?? 'desc';

  const sorting = { key: sortBy, dir: sortDir };

  const handleSorting = ({ key, dir }) => {
    setSearchParams({
      sortBy: key,
      sortDir: dir
    });

    track('tableSorted');
  };

  const handleChangePage = (_, newPage) => {
    setSearchParams({
      page: newPage
    });

    track('tablePaginated');
  };

  const handleChangeRowsPerPage = event => {
    setSearchParams({
      page: 0,
      perPage: event.target.value
    });

    track('tablePaginated');
  };

  useEffect(() => {
    if (!(isNumber.test(page) && isNumber.test(perPage))) {
      setSearchParams(defaultSearchParams);
    }
  }, [page, perPage]);

  useEffect(() => {
    if (Math.round(orders.length / perPage) < page) {
      // reset to 0 page when filtering
      setSearchParams({ page: 0 });
    }
  }, [page, perPage, orders.length]);

  const view = orderBy(orders, sortBy, sortDir).slice(page * perPage, page * perPage + perPage);

  return (
    <Table aria-label="caption table" data-e2e="orderTable" padding="none" className={styles.table}>
      <TableHead>
        <TableRow>
          <SortableCell sorting={sorting} sortingKey="additionalData.externalId" onSorting={handleSorting}>
            {intl('referenceId')}
          </SortableCell>
          <SortableCell sorting={sorting} sortingKey="shoot.location.address" onSorting={handleSorting}>
            {intl('address')}
          </SortableCell>
          <SortableCell sorting={sorting} sortingKey="uuid" onSorting={handleSorting}>
            {intl('id')}
          </SortableCell>
          <SortableCell sorting={sorting} sortingKey="user.email" onSorting={handleSorting}>
            {intl('creator')}
          </SortableCell>
          <SortableCell sorting={sorting} sortingKey="createdAt" onSorting={handleSorting}>
            {intl('createdAt')}
          </SortableCell>
          <SortableCell sorting={sorting} sortingKey="shoot.start" onSorting={handleSorting}>
            {intl('date')}
          </SortableCell>
          <SortableCell sorting={sorting} sortingKey="shoot.deliveredAt" onSorting={handleSorting}>
            {intl('deliveredAt')}
          </SortableCell>
          <SortableCell sorting={sorting} sortingKey="product.name" onSorting={handleSorting}>
            {intl('product')}
          </SortableCell>
          <SortableCell sorting={sorting} sortingKey="shoot.price" onSorting={handleSorting}>
            {common('price')}
          </SortableCell>
          <SortableCell sorting={sorting} sortingKey="shoot.status" onSorting={handleSorting}>
            {intl('status')}
          </SortableCell>
          <SortableCell
            sorting={sorting}
            sortingKey="additionalData.notes"
            onSorting={handleSorting}
            checkbox={
              <Checkbox
                color="primary"
                checked={notesVisibility}
                onChange={() => {
                  localStorage.setItem('notesVisibility', (!notesVisibility).toString());
                  setNotesVisibility(!notesVisibility);
                }}
              />
            }
          >
            {common('notes')}
          </SortableCell>
          <TableCell>&nbsp;</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {view.map((order, index) => (
          <OrderTableRow
            key={order.uuid}
            order={order}
            striped={index % 2 === 0}
            isNotesVisible={notesVisibility === true}
            onOrderPreview={onOrderPreview}
          />
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            count={orders.length}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={perPage}
            rowsPerPageOptions={[...new Set([perPage, 25, 50, 100])]}
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
};

export default OrderTable;
