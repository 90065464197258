import MyBookings from 'components/MyBookings';
import React from 'react';
import { useIntl } from 'react-intl';

import { useGetMyBookingsLazyQuery } from '../__generated__';

const MyBookingsContainer = () => {
  const intl = useIntl();
  const [load, { loading, error, data }] = useGetMyBookingsLazyQuery({ fetchPolicy: 'network-only' });
  return (
    <MyBookings
      title={intl.formatMessage({ id: 'components.MyBookings.Header.title' })}
      load={({ startDate, endDate }) => load({ variables: { startDate, endDate } })}
      loading={loading}
      error={error}
      data={data?.bookings ?? []}
    />
  );
};

export default MyBookingsContainer;
