import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { format as dateFormat } from 'date-fns';
// material-ui
import { createMuiTheme, List } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Typography, Row } from '@lemonone/components';

import { useGetCapacityDaysQuery, useGetCapacityTimeslotsLazyQuery } from '__generated__';

// components
import ShootDuration from 'components/ShootDuration';
import { useIntl } from 'react-intl';
import Calendar from './Calendar';
import styles from './SelectDateTime.module.css';
import TimeSlot from './TimeSlot';
import Loading from '../Loading';

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: 'black',
        color: 'white',
        marginBottom: '30px'
      },
      iconButton: {
        backgroundColor: 'none'
      }
    },
    MuiSvgIcon: {
      root: {
        fill: '#999999'
      }
    },

    MuiListItemText: {
      fill: 'red'
    },

    MuiPickersDay: {
      daySelected: {
        color: 'white!important',
        backgroundColor: 'black',
        '&:hover': {
          color: 'white',
          backgroundColor: 'black'
        }
      }
    }
  }
});

const SelectDateTime = ({
  title,
  address,
  productUuid,
  duration,
  onAppointmentSelect,
  renderZeroCapacitiesFallback
}) => {
  const intl = useIntl();

  const [capacityDays, setCapacityDays] = useState(null);
  const [currentCapacityTimeslot, setCurrentCapacityTimeslot] = useState(null);
  const [currentDay, setCurrentDay] = useState(null);

  const onCapacityTimeslotChange = timeslot => {
    setCurrentCapacityTimeslot(timeslot);
    onAppointmentSelect(timeslot);
  };

  const [getCapacityTimeslots, getCapacityTimeslotsResult] = useGetCapacityTimeslotsLazyQuery({
    fetchPolicy: 'network-only'
  });

  const loadCapacityTimeslots = day =>
    getCapacityTimeslots({
      variables: {
        address,
        productUuid,
        date: day
      }
    });

  // TODO ugly: we originally get days as strings, but Calendar calls this with a Date instance.
  // That conversion to Date might get a previous day in UTC for cases of backwards time shift
  const onDayChange = day => {
    const dayString = day instanceof Date ? dateFormat(day, 'yyyy-MM-dd') : day;

    setCurrentDay(dayString);
    loadCapacityTimeslots(dayString);
  };

  const getCapacityDaysResult = useGetCapacityDaysQuery({
    variables: {
      address,
      productUuid
    },
    onCompleted(data) {
      const days = {};

      for (const capacityDay of data.capacityDays) {
        days[capacityDay.date] = capacityDay;
      }

      setCapacityDays(days);

      onDayChange(data.capacityDays[0].date);
    }
  });

  if (capacityDays === null || getCapacityDaysResult.loading) {
    return <Loading />;
  }

  if (!Object.keys(capacityDays).length) {
    if (renderZeroCapacitiesFallback) {
      return renderZeroCapacitiesFallback();
    }

    return (
      <Typography>
        {intl.formatMessage({
          id: 'Sorry, there are no appointments available.'
        })}
      </Typography>
    );
  }

  return (
    <>
      <Row>
        <Typography data-e2e="dateSelectHeadline">
          {title ?? intl.formatMessage({ id: 'Pick a date that works for you' })}
        </Typography>
      </Row>

      <Row>
        <Row>
          <div className={styles.Scheduling}>
            <div className={styles.Left}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <ThemeProvider theme={materialTheme}>
                  <Calendar days={capacityDays} onChange={onDayChange} value={currentDay} />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </div>

            <div className={styles.Right}>
              <div className={styles.Slots}>
                <div className={styles.SlotsInner}>
                  <List data-e2e="dateSelectTimeList">
                    <div className={styles.StartTime}>
                      <div className={styles.StartTimeInner}>
                        {intl.formatMessage({
                          id: 'components.SelectDayTime.start'
                        })}
                      </div>
                    </div>

                    <div className={styles.Appointment}>
                      {getCapacityTimeslotsResult.loading && <Loading />}
                      {!getCapacityTimeslotsResult.loading &&
                        getCapacityTimeslotsResult.data?.capacityTimeslots?.map(timeslot => (
                          <TimeSlot
                            key={timeslot.start}
                            onClick={onCapacityTimeslotChange}
                            timeslot={timeslot}
                            selected={timeslot === currentCapacityTimeslot}
                          />
                        ))}
                      {!getCapacityTimeslotsResult.loading &&
                        !getCapacityTimeslotsResult.data?.capacityTimeslots?.length && (
                          <Typography>
                            {intl.formatMessage({
                              id: 'Sorry, there are no appointments for the selected date.'
                            })}
                          </Typography>
                        )}
                    </div>
                  </List>

                  {!getCapacityTimeslotsResult.loading && (
                    <div className={styles.GuaranteedWrapper}>
                      <span className={styles.Guaranteed}>
                        *
                        {intl.formatMessage({
                          id: 'Recommended time'
                        })}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Row>
        <Row>
          <div className={styles.ShootDuration}>
            <ShootDuration duration={duration} />
          </div>
        </Row>
      </Row>
    </>
  );
};

export default SelectDateTime;
