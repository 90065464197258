import { Alert, Icon, Row, Typography } from '@lemonone/components';
import React from 'react';
import { useIntl } from 'react-intl';

import OrderDetails from '../OrderDetails';

const ShootRescheduled = ({ orderInfo }) => {
  const intl = useIntl();
  return (
    <>
      <Row>
        <Alert variant="warning">
          <Icon icon="attention" variant="warning" />
          <Typography color="warning" as="p">
            {intl.formatMessage({
              id: 'Your photo shoot appointment has been cancelled'
            })}
          </Typography>
        </Alert>
      </Row>
      <Row>
        <OrderDetails orderInfo={orderInfo} />
      </Row>
    </>
  );
};

export default ShootRescheduled;
