import React from 'react';
import { FormattedMessage } from 'react-intl';
// components
import Text from 'components/Text';
// material-ui
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import { withStyles } from '@material-ui/core';
import { usePhotoShootRequestMutation } from '../../__generated__';
import ShootRequestForm from './ShootRequestForm';

const styles = () => ({
  container: {
    maxWidth: '680px',
    margin: '3rem auto'
  },
  expand: {
    marginLeft: 'auto'
  }
});

const ShootRequest = ({ classes, address, onNotification, product, shootRequest, shootRequestReceived }) => {
  const [mutation] = usePhotoShootRequestMutation();
  if (shootRequest) {
    return (
      <Card className={classes.container}>
        <CardHeader
          title={<FormattedMessage defaultMessage="Thanks for your request!" id="components.ShootRequest.titleAfter" />}
          TypographyProps={{ align: 'center' }}
        />
        <CardContent>
          <Text paragraph>
            <FormattedMessage
              defaultMessage="We have received your details and will be in touch as soon as we have a photographer for your photoshoot."
              id="components.ShootRequest.confirmation"
            />
          </Text>
          <Text color="primary">
            <FormattedMessage defaultMessage="Team LemonOne" id="components.ShootRequest.team" />
          </Text>
        </CardContent>
      </Card>
    );
  }
  return (
    <Card className={classes.container}>
      <CardHeader
        title={
          <FormattedMessage
            defaultMessage="Unfortunately, we do not have any available photographers in your area at the moment."
            id="components.ShootRequest.titleBefore"
          />
        }
        titleTypographyProps={{ align: 'center' }}
      />
      <CardContent>
        <Text>
          <FormattedMessage
            defaultMessage="Please fill in your contact details and propose three dates and times that work for you, and we will inform you as soon as we found a photographer."
            id="components.ShootRequest.details"
          />
        </Text>
        <ShootRequestForm
          address={address}
          product={product}
          onReceiveConfirmation={shootRequestReceived}
          onError={onNotification}
          mutation={mutation}
        />
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(ShootRequest);
